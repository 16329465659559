import { toHeaderCase, toPascalCase, toSentenceCase } from "js-convert-case";
import { DropdownOption } from "../../models/Dashboard";

interface Props {
  values: Array<DropdownOption>;
}

export const TruncatedBadges = ({ values }: Props) => {
  const NUMBER_OF_BADGES_TO_SHOW = 4;

  return (
    <div className="flex items-center space-x-2">
      <div className="flex flex-shrink-0 items-center">
        {values
          .slice(0, NUMBER_OF_BADGES_TO_SHOW)
          .map((value: DropdownOption) => (
            <div
              key={value.id}
              className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-500 dark:text-gray-400 ring-1 ring-inset ring-gray-400/20 mr-2"
            >
              {toHeaderCase(value.value.replace(/^Can\s+/i, ""))}
            </div>
          ))}
        {values.length > NUMBER_OF_BADGES_TO_SHOW && (
          <>+ {values.length - NUMBER_OF_BADGES_TO_SHOW} more</>
        )}
      </div>
    </div>
  );
};
