import { useDispatch, useSelector } from "react-redux";
import { Nav } from "../nav/Nav";
import { dashboardActions } from "../../../store/dashboard";
import { Bars3CenterLeftIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { EMAIL_KEY, EVENT_KEY } from "../../../pages/protected-route";
import { UserCircleIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { AccountMenuContent } from "../nav/account-menu/AccountMenuContent";
import { CreateEventModal } from "../create-event/CreateEventModal";
import { useUserPermissions } from "../../../utils/auth";
import { PermissionDenied } from "../../PermissionDenied";
import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { State } from "../../../store/store";

interface Props {
  children: any;
  pageTitle: string;
  rightControls?: any;
  requiresSuperuser?: boolean;
  requiredPermissions: Array<string>;
}

Chart.register(PointElement, LinearScale, LineElement, CategoryScale, Tooltip);

export const DashboardLayout = ({
  children,
  pageTitle,
  rightControls,
  requiresSuperuser,
  requiredPermissions,
}: Props) => {
  const event = JSON.parse(localStorage.getItem(EVENT_KEY) || "{}");
  const dispatch = useDispatch();
  const [userPermissions, loading] = useUserPermissions();
  const organisationId = useSelector(
    (state: State) => state.dashboard.organisationId
  );

  const setSidebarOpen = (sidebarOpen: boolean) => {
    dispatch(dashboardActions.SetSideBarOpen({ sidebarOpen }));
  };

  const currentPermissions = organisationId
    ? userPermissions[organisationId]?.permissions || []
    : [];
  const isOrganisationAdmin = organisationId
    ? userPermissions[organisationId]?.is_organisation_admin || false
    : false;

  return (
    <>
      {event && !event.isActive && (
        <div className="absolute z-50 w-full">
          <div className="bg-indigo-500 w-full h-1"></div>
          <div className="flex w-full items-center justify-center">
            <p className="bg-indigo-500 flex px-2 rounded-b-md text-white text-sm">
              This event is still awaiting approval and is in draft mode
            </p>
          </div>
        </div>
      )}
      <Nav />
      <CreateEventModal />
      <div className="min-h-full dark:bg-dark-primary bg-light-primary">
        {/* Main column */}
        <div className="flex flex-col lg:pl-64">
          {/* Search header */}
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:hidden dark:bg-dark-secondary dark:border-white/5">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden dark:border-white/5"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
              <div className="flex flex-1"></div>
              <div className="flex items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex max-w-xs items-center rounded-full text-sm focus:outline-none dark:text-white text-gray-900">
                      <UserCircleIcon className="h-5 w-5 mr-2" />
                      {localStorage.getItem(EMAIL_KEY)}
                      <ChevronDownIcon className="ml-2 w-4 h-4" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-dark-secondary border border-transparent dark:border-white/5">
                      <AccountMenuContent />
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="ml-3 mt-3 mr-3 rounded-tr-md lg:rounded-tr-none lg:mr-0 rounded-tl-md border dark:border-white/5 h-screen overflow-y-scroll shadow-lg dark:bg-dark-secondary pb-20">
            {/* Page title & actions */}
            <div className="py-6 border-b border-gray-200 dark:border-white/5 px-4 py-4 flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
              <div className="min-w-0 flex-1 items-center flex">
                <h1 className="text-lg font-medium leading-6 text-gray-900 dark:text-white sm:truncate">
                  {pageTitle}
                </h1>
              </div>

              {rightControls && (
                <div className="flex sm:ml-4 sm:mt-0">{rightControls}</div>
              )}
            </div>

            <div className="">
              {loading ? (
                <div className="w-full h-screen flex items-center justify-center -mt-32">
                  <svg
                    className="animate-spin mr-2 h-12 w-12 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <>
                  {/* if organisation admin, always show, don't bother checking permissions */}
                  {isOrganisationAdmin ? (
                    children
                  ) : (
                    <>
                      {/* super user shit first */}
                      {requiresSuperuser ? (
                        <>
                          {requiresSuperuser && isOrganisationAdmin ? (
                            children
                          ) : (
                            <PermissionDenied />
                          )}
                        </>
                      ) : (
                        <>
                          {/* non super user shit, just checking perms */}
                          {requiredPermissions.every((permission) =>
                            currentPermissions.includes(permission)
                          ) ? (
                            children
                          ) : (
                            <PermissionDenied />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
