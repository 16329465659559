import {
  OrderResponse,
  PurchasedAddon,
  PurchasedTicket,
} from "../../../models/Order";

interface Props {
  order: OrderResponse;
}

export const ViewOrder = ({ order }: Props) => {
  return (
    <>
      <dl className="space-y-6 sm:border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
        {order.purchasedTickets && (
          <>
            <div className="flex justify-between">
              <dt className="text-left">Purchased Tickets</dt>
              <dt>
                {order.purchasedTickets.map((ticket: PurchasedTicket) => (
                  <dt className="mb-1">
                    x{ticket.quantity} {ticket.ticket_Name}
                  </dt>
                ))}
              </dt>
            </div>
          </>
        )}
        {order.purchasedAddons && order.purchasedAddons.length > 0 && (
          <>
            <div className="flex justify-between text-right">
              <dt className="text-left">Purchased Add-ons</dt>
              <dt>
                {order.purchasedAddons.map((addon: PurchasedAddon) => (
                  <dt className="mb-1">
                    x{addon.quantity} {addon.addon_Name}
                  </dt>
                ))}
              </dt>
            </div>
          </>
        )}
      </dl>
    </>
  );
};
