import axios, { AxiosResponse } from "axios";
import { DashboardLayout } from "../../../components/dashboard/layouts/DashboardLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../../store/dashboard";
import { DashboardTicket } from "../../../models/Dashboard";
import { State } from "../../../store/store";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  useFormikContext,
  FormikValues,
} from "formik";
import * as Yup from "yup";
import { Input } from "../../../crud/form/Input";
import { SkeletonLoading } from "../../../components/SkeletonLoading";
import { Skeleton } from "../../../components/Skeleton";
import { Checkbox } from "../../../crud/form/Checkbox";
import { createManualOrder } from "../../../queries";
import { CreateOrderRequest } from "../../../models/Order";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ButtonSpinner } from "../../../components/ButtonSpinner";
import { Dropdown } from "../../../crud/form/Dropdown";

interface CalculateFaceValueProps {
  index: number;
  ticket: DashboardTicket;
}

const ORDER_TYPE_OPTIONS = [
  { id: "PAYMENT", value: "Payment" },
  { id: "NO_PAYMENT_REQUIRED", value: "No payment required" },
  { id: "PAID_WITH_CASH", value: "Paid with cash" },
];

export const CreateOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allTickets: Array<DashboardTicket> = useSelector(
    (state: State) => state.dashboard.allTickets
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(dashboardActions.GetAllTickets());
  }, []);

  const initialValues = {
    tickets: allTickets.map((ticket) => ({
      uuid: ticket.uuid,
      quantity: "",
      faceValue: "",
      firstName: "",
      lastName: "",
      email: "",
      sendConfirmationEmail: false,
    })),
  };

  const CalculateFaceValue: React.FC<CalculateFaceValueProps> = ({
    index,
    ticket,
  }) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();

    useEffect(() => {
      const quantity = values.tickets[index].quantity;
      const faceValue = values.tickets[index].faceValue;

      if (quantity) {
        setFieldValue(
          `tickets[${index}].faceValue`,
          quantity * parseFloat(ticket.price)
        );
      } else {
        setFieldValue(`tickets[${index}].faceValue`, "");
      }
    }, [values.tickets[index].quantity]);

    return null;
  };

  const validationSchema = Yup.object().shape({
    tickets: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number()
          .typeError("Quantity must be a number")
          .nullable(),
        faceValue: Yup.number()
          .typeError("Face Value must be a number")
          .nullable()
          .when("quantity", (quantity: any) => {
            if (quantity && quantity > 0) {
              return Yup.number().required("Required");
            }
            return Yup.number().nullable();
          }),
      })
    ),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    sendConfirmationEmail: Yup.boolean(),
    orderType: Yup.mixed().oneOfByValue(ORDER_TYPE_OPTIONS),
  });

  const handleSubmit = async (values: any) => {
    setIsLoading(true);

    // Filter out tickets that don't have a quantity and face value
    const filteredTickets = values.tickets.filter((ticket: any) => {
      return ticket.quantity && ticket.faceValue;
    });

    // Create a new values object with the filtered tickets
    const filteredValues = {
      ...values,
      tickets: filteredTickets,
    };

    // Check if there are tickets
    if (filteredValues.tickets.length === 0) {
      toast.error("You need to add at least one ticket to create an order", {
        icon: "❌",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      setIsLoading(false);
      return;
    }

    await createManualOrder(filteredValues as CreateOrderRequest);

    toast(
      `Successfully Created order for ${values.firstName} ${values.lastName}${
        values.sendConfirmationEmail
          ? " and sent confirmation email with tickets."
          : "."
      }`,
      {
        icon: "✅",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      }
    );

    navigate("/dashboard/orders");

    setIsLoading(false);
  };

  return (
    <DashboardLayout
      requiredPermissions={["add_order"]}
      pageTitle="Create Order"
    >
      <div className="px-8 py-6">
        <div className="mb-5">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Tickets
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Choose what tickets you want to add to this order. You can override
            the face value of them
          </p>
        </div>

        {allTickets.length > 0 ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnMount={false}
            validateOnChange={false}
          >
            {({ values, handleSubmit }) => (
              <Form>
                <div>
                  <div className="inline-block min-w-full border-b border-gray-200 dark:border-white/5 align-middle">
                    <table className="min-w-full overflow-x-scroll">
                      <thead>
                        <tr className="border-gray-200 dark:border-white/5">
                          <th
                            key="Ticket"
                            className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                            scope="col"
                          >
                            Ticket
                          </th>
                          <th
                            key="Sold"
                            className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                            scope="col"
                          >
                            Sold
                          </th>
                          <th
                            key="Price"
                            className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                            scope="col"
                          >
                            Price
                          </th>
                          <th
                            key="Quantity"
                            className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                            scope="col"
                          >
                            Quantity
                          </th>
                          <th
                            key="Face Value"
                            className="pl-16 border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                            scope="col"
                          >
                            Face Value
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-100 bg-white dark:bg-transparent dark:divide-white/5 overflow-x-scroll">
                        {allTickets.map((ticket, index) => (
                          <tr key={ticket.uuid} className="overflow-x-scroll">
                            <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
                              <div className="flex items-center space-x-2">
                                <div className="flex flex-shrink-0 -space-x-1">
                                  {ticket.name}
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
                              <div className="flex items-center space-x-2">
                                <div className="flex flex-shrink-0 -space-x-1">
                                  {ticket.quantitySold}/
                                  {ticket.quantityAvailable}
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
                              <div className="flex items-center space-x-2">
                                <div className="flex flex-shrink-0 -space-x-1">
                                  &euro;{ticket.price}
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
                              <div className="flex items-center space-x-2">
                                <div className="flex flex-shrink-0 -space-x-1 w-16">
                                  <Input
                                    type="number"
                                    name={`tickets[${index}].quantity`}
                                    required={false}
                                  />
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
                              <div className="flex items-center justify-end space-x-2">
                                <div className=" flex-shrink-0 -space-x-1">
                                  <Input
                                    type="number"
                                    name={`tickets[${index}].faceValue`}
                                    required={false}
                                    isMoney={true}
                                  />
                                </div>
                              </div>
                            </td>

                            <CalculateFaceValue index={index} ticket={ticket} />
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex justify-end mt-6 pr-7">
                    <div className="text-right text-gray-900 dark:text-white">
                      <span className="font-semibold">Order Total: </span>
                      <span className="font-light ml-1">
                        &euro;
                        {values.tickets
                          .reduce((total, ticket) => {
                            return total + (parseFloat(ticket.faceValue) || 0);
                          }, 0)
                          .toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-6 border-t border-gray-200 dark:border-white/5 pt-6">
                  <div className="mb-5">
                    <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                      Customer information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                      Information about the customer who is buying the tickets.
                      Choose whether or not you want to send this order, with
                      their tickets to the customer via email
                    </p>
                  </div>

                  <div className="inline-block min-w-full align-middle">
                    <div className="flex mb-5">
                      <div className="w-1/3">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mb-3"
                        >
                          Order type
                        </label>
                        <Dropdown
                          name={"orderType"}
                          options={ORDER_TYPE_OPTIONS}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-1/2 pr-4">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mb-3"
                        >
                          First Name
                        </label>
                        <Input type="text" name="firstName" required={false} />
                      </div>
                      <div className="w-1/2 pl-4">
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mb-3"
                        >
                          Last Name
                        </label>
                        <Input type="text" name="lastName" required={false} />
                      </div>
                    </div>
                    <div className="flex mt-4">
                      <div className="w-1/2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mb-3"
                        >
                          Email
                        </label>
                        <Input type="text" name="email" required={false} />
                      </div>
                      <div className="w-1/2"></div>
                    </div>

                    <div className="mt-6 mb-2">
                      <Checkbox name="sendConfirmationEmail" />
                      <label className="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                        Send a confirmation email to the attendees
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="flex items-center inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {isLoading ? (
                      <>
                        <ButtonSpinner />
                        Processing...
                      </>
                    ) : (
                      <>Create order</>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div className="inline-block min-w-full border-b border-gray-200 dark:border-white/5 align-middle">
            <table className="min-w-full overflow-x-scroll">
              <thead>
                <tr className="border-gray-200 dark:border-white/5">
                  <th
                    key="Ticket"
                    className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    Ticket
                  </th>
                  <th
                    key="Sold"
                    className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    Sold
                  </th>
                  <th
                    key="Price"
                    className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    Price
                  </th>
                  <th
                    key="Quantity"
                    className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    Quantity
                  </th>
                  <th
                    key="Face Value"
                    className="border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    Face Value
                  </th>
                </tr>
              </thead>

              <tbody className="bg-red-500 divide-y divide-gray-100 bg-white dark:bg-transparent dark:divide-white/5 overflow-x-scroll">
                {[...Array(10)].map((_, index) => (
                  <tr key={index} className="overflow-x-scroll">
                    <td className="px-6 py-5 text-sm font-medium text-gray-500 dark:text-gray-400">
                      <Skeleton />
                    </td>
                    <td className="px-6 py-5 text-sm font-medium text-gray-500 dark:text-gray-400">
                      <Skeleton />
                    </td>
                    <td className="px-6 py-5 text-sm font-medium text-gray-500 dark:text-gray-400">
                      <Skeleton />
                    </td>
                    <td className="px-6 py-5 text-sm font-medium text-gray-500 dark:text-gray-400">
                      <Skeleton />
                    </td>
                    <td className="px-6 py-5 text-sm font-medium text-gray-500 dark:text-gray-400">
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};
