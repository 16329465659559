import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCESS_TOKEN_KEY, INVITE_KEY } from "./protected-route";
import { BASE_URL } from "../dashboardQueries";
import axios, { AxiosError } from "axios";
import { useDarkMode } from "usehooks-ts";
import { PreDashboardLayout } from "../components/dashboard/layouts/PreDashboardLayout";
import { OrganisationUser } from "../models/Dashboard";
import { Formik } from "formik";
import yup from "../crud/yup-extended";
import { Input } from "../crud/form/Input";
import toast from "react-hot-toast";
import Lottie from "lottie-react";
import successAnimation from "../assets/successAnimation.json";
import { is } from "date-fns/locale";
import { ButtonSpinner } from "../components/ButtonSpinner";

export const Invite = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const invite = queryParams.get("ref");
  const [loading, setIsLoading] = useState(true);
  const [organisationUser, setOrganisationUser] =
    useState<OrganisationUser | null>();
  const darkMode = useDarkMode();
  const [acceptedInvitation, setAcceptedInvitation] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  useEffect(() => {
    const fetchInvite = async () => {
      if (!invite) {
        navigate("/sign-in");
      } else {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        try {
          // Call the backend
          const response = await axios.get(
            `${BASE_URL}/dashboard/invite/${invite}`,
            {
              headers: accessToken
                ? { Authorization: `Bearer ${accessToken}` }
                : {},
            }
          );
          setOrganisationUser(response.data as OrganisationUser);
        } catch (error: any) {
          if (error.response.status === 401 || error.response.status === 404) {
            navigate("/sign-in");
          }
        }
      }
    };

    fetchInvite();
  }, [invite, navigate]);

  const acceptInvite = async (values?: any) => {
    setIsAccepting(true);
    try {
      // Get the access token from local storage or any other storage mechanism
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

      // Call the backend with headers if access token exists
      const response = await axios.post(
        `${BASE_URL}/dashboard/invite/${invite}`,
        values ? values : {},
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {},
        }
      );

      if (response.status === 202) {
        setAcceptedInvitation(true);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "There was an error setting up your account and accepting the invite. Please try again or contact your account manager"
      );
    }
    setIsAccepting(false);
  };

  const declineInvite = async () => {
    setIsDeclining(true);
    try {
      // Get the access token from local storage or any other storage mechanism
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

      // Call the backend with headers if access token exists
      const response = await axios.post(
        `${BASE_URL}/dashboard/invite/${invite}/decline`,
        {},
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {},
        }
      );

      if (response.status === 204) {
        window.location.href = "https://ticketr.events";
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "There was an error declining the invite. Please try again or contact your account manager"
      );
    }
    setIsDeclining(false);
  };

  /* Invite page needs to support the following:

  1. Make a request to the server. If it's not found, 404, redirect to sign in
  2. If it is found, then the backend returns the user organisation stuff, along with whether or not the user is signed in or not
  3. If they are signed in, then we show the "Accept invite" or "decline invite"
  4. If they are not logged in we show the

  1. Someone who has no account and we want to set it up for them. The user will exist and we will want to collect their first, last name and password only if they have never logged in and aren't active. For this, the user won't be logged in
  2. Someone who is logged in and we want to invite them to an event. We will want to show them the event and allow them to accept the invite. For this, the user will be logged in
  3. Someone who is not logged in but we want to make them login.
  */

  return (
    <PreDashboardLayout>
      <div className="flex items-center justify-center h-screen">
        {/* <h2 className="max-w-3xl text-pretty font-medium tracking-tighter dark:text-white text-6xl text-white">
            Select an organisation
          </h2> */}
        {/* {organisations.map((org) => (
            <div key={org.id} onClick={() => selectOrganisation(org)}>
              {org.name}
            </div>
          ))} */}
        <div className="max-w-lg w-full h-screen flex-1 items-center flex border-x border-gray-900 border-dashed">
          <div className="w-full rounded-xl shadow-md dark:bg-dark-secondary ring-1 ring-black/5">
            <div className="flex items-start p-7">
              {darkMode.isDarkMode ? (
                <svg
                  id="Livello_2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 648.3 150.34"
                  className="ml-1"
                  height="25"
                >
                  <g id="Livello_1-2">
                    <rect
                      className="fill-white"
                      x="111.99"
                      y="38.89"
                      width="33.07"
                      height="109.44"
                    />
                    <path
                      className="fill-white"
                      d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                    />
                    <polygon
                      className="fill-white"
                      points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                    />
                    <path
                      className="fill-white"
                      d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                    />
                    <path
                      className="fill-white"
                      d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                    />
                    <path
                      className="fill-white"
                      d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                    />
                    <polygon
                      className="fill-white"
                      points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  id="Livello_2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 648.3 150.34"
                  className="ml-1"
                  height="22"
                >
                  <g id="Livello_1-2">
                    <rect
                      className="fill-indigo-500"
                      x="111.99"
                      y="38.89"
                      width="33.07"
                      height="109.44"
                    />
                    <path
                      className="fill-indigo-500"
                      d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                    />
                    <polygon
                      className="fill-indigo-500"
                      points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                    />
                    <path
                      className="fill-indigo-500"
                      d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                    />
                    <path
                      className="fill-indigo-500"
                      d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                    />
                    <path
                      className="fill-indigo-500"
                      d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                    />
                    <polygon
                      className="fill-indigo-500"
                      points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                    />
                  </g>
                </svg>
              )}
            </div>
            {acceptedInvitation ? (
              <div className="flex items-center justify-center">
                <div>
                  <div className="">
                    <Lottie
                      animationData={successAnimation}
                      loop={false}
                      className="h-24"
                    />
                  </div>

                  <div className="text-center px-10 mt-4 mb-8">
                    <h1 className="text-base/6 font-medium text-white text-lg">
                      Successfully accepted invitation to the{" "}
                      {organisationUser?.organisation.name} organisation.
                    </h1>

                    {organisationUser?.isLoggedIn ? (
                      <div>
                        <p className="text-sm/5 text-gray-400 mt-4">
                          You can now access the organisations dashboard
                        </p>

                        <div className="mt-8">
                          <button
                            type="submit"
                            onClick={() => navigate("/dashboard")}
                            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-md"
                          >
                            Continue to Dashboard
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="mt-1 text-sm/5 text-gray-400 mt-4">
                          You have now created an account. To access the
                          organisation's dashboard, please sign in by clicking
                          below
                        </p>

                        <div className="mt-8">
                          <button
                            type="submit"
                            onClick={() => navigate("/sign-in")}
                            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-md"
                          >
                            Continue to Sign in
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {organisationUser ? (
                  <form className="pb-7 px-7 -mt-3">
                    <div>
                      <div className="flex items-center justify-center">
                        {organisationUser?.organisation.logo ? (
                          <img
                            alt=""
                            src={organisationUser.organisation.logo}
                            className="size-12 rounded-full"
                          />
                        ) : (
                          <div className="dark:bg-dark-primary h-16 w-16 rounded-full flex items-center justify-center text-white text-lg">
                            {organisationUser?.organisation.name
                              .split(" ")
                              .map((word: string) => word[0])
                              .join("")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex mt-4 text-center w-full">
                      <div className="w-full">
                        <h1 className="text-base/6 font-medium text-white text-center">
                          {organisationUser?.organisation.name} is inviting you
                          to their organisation
                        </h1>
                      </div>
                    </div>

                    {organisationUser?.isLoggedIn ? (
                      <div>
                        <div className="mt-8">
                          <button
                            type="button"
                            onClick={() =>
                              acceptInvite({
                                email: organisationUser?.user.email,
                              })
                            }
                            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-md flex items-center justify-center"
                          >
                            {isAccepting ? (
                              <>
                                <ButtonSpinner /> Accepting...
                              </>
                            ) : (
                              "Accept invite"
                            )}
                          </button>
                        </div>
                        <div className="mt-4">
                          <button
                            type="button"
                            onClick={declineInvite}
                            className="w-full bg-gray-900 hover:bg-gray-800 text-white py-3 rounded-md flex items-center justify-center"
                          >
                            {isDeclining ? (
                              <>
                                <ButtonSpinner /> Declining...
                              </>
                            ) : (
                              "Decline invite"
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <div className="text-center">
                          <p className="mt-1 text-sm/5 text-gray-400">
                            To accept this invitation, you need to setup an
                            account with Ticketr
                          </p>
                        </div>

                        <Formik
                          initialValues={{
                            email: organisationUser?.user.email,
                          }}
                          validationSchema={yup.object({
                            email: yup
                              .string()
                              .email("Please enter a valid email")
                              .required("Required"),
                            password: yup.string().required("Required"),
                            confirmPassword: yup
                              .string()
                              .oneOf(
                                [yup.ref("password"), undefined],
                                "Passwords must match"
                              )
                              .required("Required"),
                          })}
                          onSubmit={async (values, actions) => {
                            setIsAccepting(true);
                            try {
                              const formValues = values as any;
                              const response = await axios.post(
                                `${BASE_URL}/dashboard/invite/${invite}`,
                                formValues
                              );
                              if (response.status === 202) {
                                setAcceptedInvitation(true);
                              }
                            } catch (error) {
                              console.error(error);
                              toast.error(
                                "There was an error setting up your account and accepting the invite. Please try again or contact your account manager"
                              );
                            }
                            setIsAccepting(false);
                          }}
                          enableReinitialize
                          validateOnBlur={false}
                          validateOnChange={false}
                          validateOnMount={false}
                        >
                          {({ errors, handleSubmit, values }) => (
                            <form
                              className="space-y-6"
                              onSubmit={() => handleSubmit()}
                            >
                              <div className="flex mt-8">
                                <div className="w-1/2 mr-2">
                                  <div className="-mb-2">
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-white -mb-4"
                                    >
                                      First Name
                                    </label>
                                    <div className="mt-6">
                                      <Input
                                        type="text"
                                        name="firstName"
                                        required={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="w-1/2 ml-2">
                                  <div className="-mb-2">
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-white -mb-4"
                                    >
                                      Last Name
                                    </label>
                                    <div className="mt-6">
                                      <Input
                                        type="text"
                                        name="lastName"
                                        required={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="-mb-2">
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white -mb-4"
                                >
                                  Email
                                </label>
                                <div className="mt-6">
                                  <Input
                                    type="text"
                                    name="email"
                                    disabled={true}
                                    value={organisationUser?.user.email}
                                    required={true}
                                  />
                                </div>
                                <p
                                  id="email-description"
                                  className="mt-2 text-xs text-gray-500"
                                >
                                  We already have this from your invitation and
                                  it can't be changed
                                </p>
                              </div>

                              <div className="">
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                >
                                  Password
                                </label>
                                <div className="mt-2">
                                  <Input
                                    type="password"
                                    name="password"
                                    required={true}
                                  />
                                </div>
                              </div>

                              <div className="">
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                >
                                  Confirm password
                                </label>
                                <div className="mt-2">
                                  <Input
                                    type="password"
                                    name="confirmPassword"
                                    required={true}
                                  />
                                </div>
                              </div>

                              <div>
                                <p className="mt-2 text-sm text-gray-500 text-xs">
                                  If you decline this we'll let the organisation
                                  know and an account won't be created for you.
                                  By accepting this invitation, you agree to the
                                  Ticketr Terms of Service and Privacy Policy.
                                </p>
                                <div className="mt-8">
                                  <button
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-md flex items-center justify-center"
                                  >
                                    {isAccepting ? (
                                      <>
                                        <ButtonSpinner /> Accepting...
                                      </>
                                    ) : (
                                      "Accept invite and create account"
                                    )}
                                  </button>
                                </div>
                                <div className="mt-4">
                                  <button
                                    type="button"
                                    onClick={declineInvite}
                                    className="w-full bg-gray-900 hover:bg-gray-800 text-white py-3 rounded-md flex items-center justify-center"
                                  >
                                    {isDeclining ? (
                                      <>
                                        <ButtonSpinner /> Declining...
                                      </>
                                    ) : (
                                      "Decline invite"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    )}
                  </form>
                ) : (
                  <div className="flex items-center justify-center h-64 -mt-10">
                    <svg
                      className="animate-spin mr-2 h-12 w-12 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </PreDashboardLayout>
  );
};
