import { Base } from "./Dashboard";

export enum TicketType {
  ONCE_OFF = "ONCE_OFF",
  PAYMENT_PLAN = "PAYMENT_PLAN",
}

export interface TicketQuestion {
  uuid: string;
  question: string;
}

export interface TicketAnswer {
  uuid: string;
  answer: string;
}

export interface Ticket {
  uuid: string;
  name: string;
  price: string;
  description?: string;
  ticketType: TicketType;
  numberOfMonths?: number;
  perMonthPrice?: string;
  isSoldOut: boolean;
  ticketOptionsRange: number;
  onSaleTo: string;
  fee: string;
  isTicketOnSale: boolean;
  addons?: Array<Addon>;
  questions?: Array<TicketQuestion>;
}

export interface Addon extends Base {
  name: string;
  description?: string;
  price: string;
  fee: string;
  onSaleFrom: Date;
  onSaleTo: Date;
  isSoldOut: boolean;
}
