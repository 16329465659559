import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fork, takeEvery, put, select } from "@redux-saga/core/effects";
import {
  AddonCartItem,
  CartItem,
  Event,
  PromoCode,
  PromoCodeType,
} from "../models/Cart";
import { Order, OrderBase, OrderResponse } from "../models/Order";
import {
  createAddonOrder,
  createOrder,
  createPaymentPlan,
  getOrganisation,
  getPromoCode,
} from "../queries";
import { AxiosError, AxiosResponse } from "axios";
import { Addon, Ticket } from "../models/Tickets";
import { calculateDiscount } from "../utils/cart";
import { CartStep, ManageOrderTab } from "../constants";
import { Organisation } from "../models/Dashboard";
import { history } from "..";

export interface CartState {
  step: CartStep;
  // Tickets in the cart
  cart: Array<CartItem>;
  // Addons in the cart
  addonCart: Array<AddonCartItem>;
  showCheckout: boolean;
  billingDetailsSubmitting: boolean;
  // Order to load into state for managing order
  order?: OrderResponse;
  addonOrder?: OrderResponse;
  promoCode?: string;
  promoCodeError?: string;
  appliedPromoCode?: PromoCode;
  hiddenTicket?: Ticket;
  totalDiscount: number;
  cartError?: string;
  paymentPlanUpdated: boolean;
  carbonFootprintContribution: number;
  numberOfAttendeesInCart: number;
  containsAddons: boolean;
  manageOrderModalIsOpen: boolean;
  manageOrderTab: ManageOrderTab;
  event: Event | null;
  organisation?: Organisation;
}

const initialState = {
  step: CartStep.TICKETS,
  cart: [],
  addonCart: [],
  showCheckout: false,
  billingDetailsSubmitting: false,
  totalDiscount: 0,
  paymentPlanUpdated: false,
  carbonFootprintContribution: 0,
  numberOfAttendeesInCart: 0,
  containsAddons: false,
  manageOrderModalIsOpen: false,
  manageOrderTab: ManageOrderTab.PURCHASE_ADDONS,
  event: null,
} as CartState;

export const cartSlice = createSlice({
  name: "cartSlice",
  initialState: initialState,
  reducers: {
    SetCart(
      state: CartState,
      action: PayloadAction<{ cart: Array<CartItem> }>
    ) {
      // Set the Cart
      state.cart = action.payload.cart.sort(function (a, b) {
        return a.price > b.price ? 1 : b.price > a.price ? -1 : 0;
      });
      if (state.cart.length > 0) {
        state.numberOfAttendeesInCart = state.cart
          .map((cartItem: CartItem) => cartItem.quantity)
          .reduce((prev, next) => prev + next);
      }
    },

    ClearCart(state: CartState, action: PayloadAction) {
      state.cart = [];
    },

    SetCartError(
      state: CartState,
      action: PayloadAction<{ cartError: string }>
    ) {
      state.cartError = action.payload.cartError;
    },

    ClearCartError(state: CartState, action: PayloadAction) {
      state.cartError = undefined;
    },

    SetShowCheckout(
      state: CartState,
      action: PayloadAction<{ showCheckout: boolean }>
    ) {
      state.showCheckout = action.payload.showCheckout;
    },

    SetBillingDetailsSubmitting(
      state: CartState,
      action: PayloadAction<{ billingDetailsSubmitting: boolean }>
    ) {
      state.billingDetailsSubmitting = action.payload.billingDetailsSubmitting;
    },

    CreateOrder(state: CartState, action: PayloadAction<{ order: Order }>) {},

    CreateAddonOrder(
      state: CartState,
      action: PayloadAction<{
        orderUuid: string;
        addonCart: Array<AddonCartItem>;
      }>
    ) {},

    ClearAddonOrder(state: CartState) {
      state.addonOrder = undefined;
    },

    CreatePaymentPlan(
      state: CartState,
      action: PayloadAction<{ order: Order }>
    ) {},

    SetOrder(
      state: CartState,
      action: PayloadAction<{ orderResponse: OrderResponse }>
    ) {
      state.order = action.payload.orderResponse;
    },

    ClearOrder(state: CartState) {
      state.order = undefined;
    },

    SetAddonOrder(
      state: CartState,
      action: PayloadAction<{ orderResponse: OrderResponse }>
    ) {
      state.addonOrder = action.payload.orderResponse;
    },

    GetPromoCode(
      state: CartState,
      action: PayloadAction<{ promoCode: string }>
    ) {},

    SetPromoCode(
      state: CartState,
      action: PayloadAction<{ promoCode: string }>
    ) {
      state.promoCode = action.payload.promoCode;
    },

    SetPromoCodeError(
      state: CartState,
      action: PayloadAction<{ promoCodeError: string }>
    ) {
      state.promoCodeError = action.payload.promoCodeError;
    },

    ClearPromoCodeError(state: CartState, action: PayloadAction) {
      state.promoCodeError = undefined;
    },

    SetAppliedPromoCode(
      state: CartState,
      action: PayloadAction<{ promoCode: PromoCode }>
    ) {
      state.appliedPromoCode = action.payload.promoCode;
    },

    RemovePromoCode(state: CartState, action: PayloadAction) {
      // remove it from the cart if its a reveal code
      if (state.appliedPromoCode?.codeType == PromoCodeType.REVEAL) {
        state.cart = state.cart.filter(
          (cartItem: CartItem) =>
            cartItem.ticketUuid !== state.appliedPromoCode?.ticket.uuid
        );
      }
      state.appliedPromoCode = undefined;
      state.promoCode = undefined;
      state.totalDiscount = 0;
    },

    ApplyDiscountsToCart(state: CartState, action: PayloadAction) {
      // Calcualte the Discount for the cart
      if (state.appliedPromoCode) {
        if (state.appliedPromoCode?.codeType === PromoCodeType.DISCOUNT) {
          // See if this ticket is in the cart
          const cartItem: Array<CartItem> = state.cart.filter(
            (cartItem: CartItem) =>
              cartItem.ticketUuid === state?.appliedPromoCode?.ticket.uuid
          );
          // Check if the element is in the cart
          if (cartItem.length > 0) {
            // Check if the promo code has a minimum cart value
            const total = state.cart.reduce(
              (sum, current) => sum + current.lineTotal,
              0
            );

            if (state.appliedPromoCode.minimumCartValue) {
              if (
                total >= parseFloat(state.appliedPromoCode.minimumCartValue)
              ) {
                // it is in the cart, calculate the discount
                state.totalDiscount = calculateDiscount(state.appliedPromoCode);
              }
            } else {
              // it is in the cart, calculate the discount
              state.totalDiscount =
                cartItem[0].quantity *
                calculateDiscount(state.appliedPromoCode);
            }
          } else {
            // it's not in the cart, reset the discount
            state.totalDiscount = 0;
          }
        }
      }
    },

    SetPaymentPlanUpdated(
      state: CartState,
      action: PayloadAction<{ paymentPlanUpdated: boolean }>
    ) {
      state.paymentPlanUpdated = action.payload.paymentPlanUpdated;
    },

    SetCarbonFootprintContribution(
      state: CartState,
      action: PayloadAction<{ carbonFootprintContribution: number }>
    ) {
      state.carbonFootprintContribution =
        action.payload.carbonFootprintContribution;
    },

    SetContainsAddons(
      state: CartState,
      action: PayloadAction<{ containsAddons: boolean }>
    ) {
      state.containsAddons = action.payload.containsAddons;
    },

    SetStep(state: CartState, action: PayloadAction<{ step: CartStep }>) {
      state.step = action.payload.step;
    },

    SetAddonCart(
      state: CartState,
      action: PayloadAction<{ addons: Array<AddonCartItem> }>
    ) {
      // Set the Cart
      state.addonCart = action.payload.addons;
    },

    SetManageOrderModalIsOpen(
      state: CartState,
      action: PayloadAction<{ manageOrderModalIsOpen: boolean }>
    ) {
      state.manageOrderModalIsOpen = action.payload.manageOrderModalIsOpen;
    },

    SetManageOrderTab(
      state: CartState,
      action: PayloadAction<{ manageOrderTab: ManageOrderTab }>
    ) {
      state.manageOrderTab = action.payload.manageOrderTab;
    },

    SetEvent(state: CartState, action: PayloadAction<{ event: Event }>) {
      state.event = action.payload.event;
    },

    GetOrganisation(state: CartState, action: PayloadAction) {},

    SetOrganisation(
      state: CartState,
      action: PayloadAction<{ organisation: Organisation }>
    ) {
      state.organisation = action.payload.organisation;
    },
  },
});

export const cartActions = {
  ...cartSlice.actions,
};

export const cartSelector = (state: any) => state[cartSlice.name] as CartState;

function* cartSideEffects() {
  // For regular orders
  yield takeEvery(cartActions.CreateOrder, function* (action) {
    const orderResponse: AxiosResponse<OrderResponse> = yield createOrder(
      action.payload.order
    );
    yield put(cartActions.SetOrder({ orderResponse: orderResponse.data }));
    yield put(
      cartActions.SetBillingDetailsSubmitting({
        billingDetailsSubmitting: false,
      })
    );
  });

  yield takeEvery(cartActions.CreateAddonOrder, function* (action) {
    const orderResponse: AxiosResponse<OrderResponse> = yield createAddonOrder(
      action.payload.orderUuid,
      action.payload.addonCart
    );
    yield put(cartActions.SetAddonOrder({ orderResponse: orderResponse.data }));
    yield put(
      cartActions.SetBillingDetailsSubmitting({
        billingDetailsSubmitting: false,
      })
    );
  });

  // For Payment Plans
  yield takeEvery(cartActions.CreatePaymentPlan, function* (action) {
    const orderResponse: AxiosResponse<OrderResponse> = yield createPaymentPlan(
      action.payload.order
    );
    yield put(cartActions.SetOrder({ orderResponse: orderResponse.data }));
    yield put(
      cartActions.SetBillingDetailsSubmitting({
        billingDetailsSubmitting: false,
      })
    );
  });

  /* The actions that we need to always re-apply discounts after the cart changes */
  // Everytime we set the cart, calculate the discounts for the cart and check for addons
  yield takeEvery(cartActions.SetCart, function* (action) {
    const cart: Array<CartItem> = yield select((state) => state.cart.cart);

    // Re-apply discounts
    yield put(cartActions.ApplyDiscountsToCart());

    // When the cart changes, check if there are any addons
    yield put(
      cartActions.SetContainsAddons({
        containsAddons: cart.some(
          (cartItem: CartItem) => cartItem.addons && cartItem.addons.length > 0
        ),
      })
    );
  });

  // Everytime we set the discounts, calculate the discounts for the cart
  yield takeEvery(cartActions.SetAppliedPromoCode, function* (action) {
    yield put(cartActions.ApplyDiscountsToCart());
  });

  // Everytime an item is removed from the cart, re-run the discounts
  yield takeEvery(cartActions.RemovePromoCode, function* (action) {
    yield put(cartActions.ApplyDiscountsToCart());
  });

  yield takeEvery(cartActions.GetPromoCode, function* (action) {
    try {
      const promoCodeResponse: AxiosResponse<PromoCode> = yield getPromoCode(
        action.payload.promoCode
      );
      const promoCode = promoCodeResponse.data;
      yield put(
        cartActions.SetAppliedPromoCode({
          promoCode: promoCode,
        })
      );
      yield put(cartActions.ClearPromoCodeError());
      // Check if it's a discount code
      if (promoCode.codeType === PromoCodeType.DISCOUNT) {
        // do the stuff
      }
    } catch (error: any) {
      // todo: put error about code
      if (error.response.status == 404) {
        yield put(
          cartActions.SetPromoCodeError({
            promoCodeError: "That is not a valid Promo Code",
          })
        );
      }
    }
    // If is hidden ticket add to hidden tickets, if discount add to discounts
    // show the promocode in the box
  });

  yield takeEvery(cartActions.GetOrganisation, function* (action) {
    try {
      const organisationResponse: AxiosResponse<Organisation> =
        yield getOrganisation();
      yield put(
        cartActions.SetOrganisation({ organisation: organisationResponse.data })
      );
    } catch {
      console.log(
        "organisation not found, or organisation view is disabled, redirecting to Ticketr"
      );
      history.push("https://ticketr.events");
    }
  });
}

export function* cartSaga() {
  yield fork(cartSideEffects);
}
