import { Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";

interface Props {
  name: string;
  required: boolean;
}

export const Textarea = ({ name, required }: Props) => {
  const { errors, setFieldValue } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    setError(errors[name] || "");
  }, [errors]);

  return (
    <>
      <textarea
        rows={4}
        name="comment"
        id="comment"
        className={`${
          error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300"
        } block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
        defaultValue={""}
        onChange={(event) => setFieldValue(name, event.target.value)}
      />
      {error && <span className="text-red-500 text-xs">{error}</span>}
    </>
  );
};
