import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import { Field, Form, FormikProps, withFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Error } from "../components/Error";
import { CartItem, PromoCodeType } from "../models/Cart";
import { cartActions } from "../store/cart";
import { State } from "../store/store";
import { calculateDiscount } from "../utils/cart";
import { store } from "..";

interface PromoCodeFormValues {
  code: string;
}

const PromoCodeInnerForm = (props: FormikProps<PromoCodeFormValues>) => {
  const {
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    values,
    resetForm,
    setFieldValue,
  } = props;
  const dispatch = useDispatch();
  // const billingDetailsSubmitting = useSelector(
  //   (state: State) => state.cart.billingDetailsSubmitting
  // );
  const promoCodeError = useSelector(
    (state: State) => state.cart.promoCodeError
  );
  const appliedPromoCode = useSelector(
    (state: State) => state.cart.appliedPromoCode
  );

  useEffect(() => {
    setFieldValue("code", appliedPromoCode?.name);
  }, []);

  return (
    <Form>
      <div className="mb-6 mt-4 mx-auto max-w-lg lg:max-w-none">
        <label
          htmlFor="promoCode"
          className="block text-sm font-medium text-gray-700"
        >
          Promo Code
        </label>
        <div className="mt-1 flex space-x-4">
          <div className="w-full relative">
            <Field
              type="text"
              id="code"
              name="code"
              disabled={appliedPromoCode}
              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200`}
            />
            {appliedPromoCode && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-600"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {!appliedPromoCode ? (
            <button
              type="submit"
              disabled={!values.code}
              onClick={() => {
                handleSubmit();
              }}
              className="rounded-md disabled:bg-gray-200 px-4 text-sm font-medium disabled:text-gray-600 disabled:hover:bg-gray-300 focus:outline-none focus:ring-2 disabled:cursor-not-allowed focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50 bg-blue-600 text-white hover:bg-blue-500"
            >
              Apply
            </button>
          ) : (
            <button
              type="submit"
              disabled={!values.code}
              onClick={() => {
                dispatch(cartActions.RemovePromoCode());
                resetForm({
                  values: {
                    code: "",
                  },
                });
              }}
              className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 focus:outline-none focus:ring-2 disabled:cursor-not-allowed focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              Remove
            </button>
          )}
        </div>
        {appliedPromoCode?.codeType == PromoCodeType.DISCOUNT && (
          <p className="text-xs mt-2 text-gray-700">
            {appliedPromoCode.name} applied. A &euro;
            {calculateDiscount(appliedPromoCode)} discount is available
            {appliedPromoCode.minimumCartValue && (
              <>
                {" "}
                on your cart when it is over &euro;
                {appliedPromoCode.minimumCartValue}
              </>
            )}
            .
          </p>
        )}
        {promoCodeError && <Error error={promoCodeError} />}
      </div>
    </Form>
  );
};

interface PromoCodeFormProps {}

// Wrap our form with the withFormik HoC
export const PromoCodeForm = withFormik<
  PromoCodeFormProps,
  PromoCodeFormValues
>({
  validationSchema: Yup.object({
    code: Yup.string().required("Required"),
  }),
  handleSubmit: (values) => {
    store.dispatch(
      cartActions.GetPromoCode({
        promoCode: values.code,
      })
    );
    // store.dispatch(cartActions.CreateOrder({ order: values }));
  },
})(PromoCodeInnerForm);
