import { isExpired } from "react-jwt";
import axios from "axios";
import { BASE_URL, refreshAccessToken } from "../dashboardQueries";
import { history } from "..";
import { useEffect, useState } from "react";
import { dashboardActions } from "../store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../store/store";
import { PreDashboardLayout } from "../components/dashboard/layouts/PreDashboardLayout";
import { useDarkMode } from "usehooks-ts";
import {
  ArrowRightCircleIcon,
  ArrowsRightLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { CreateEventFormik } from "../components/dashboard/create-event/CreateEventFormik";
import Lottie from "lottie-react";
import successAnimation from "../assets/successAnimation.json";

interface Props {
  children: any;
}

export const ACCESS_TOKEN_KEY = "auth:access";
export const REFRESH_TOKEN_KEY = "auth:refresh";
export const EMAIL_KEY = "auth:email";
export const USER_ID_KEY = "auth:userId";
export const EVENT_KEY = "event";
export const ORGANISATION_KEY = "organisation";
export const INVITE_KEY = "invite";

export const signOut = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(EMAIL_KEY);
  localStorage.removeItem(USER_ID_KEY);
  localStorage.removeItem(EVENT_KEY);
  localStorage.removeItem(ORGANISATION_KEY);
  history.push("/sign-in");
  window.location.reload();
};

export const ProtectedRoute = ({ children }: Props) => {
  const dispatch = useDispatch();
  const darkMode = useDarkMode();

  // State for loading
  const [loading, setLoading] = useState(true);

  // State for loading interceptors setup
  const interceptorsReady = useSelector(
    (state: State) => state.dashboard.interceptorsReady
  );
  const [eventsLoading, setEventsLoading] = useState(false); // New state for loading events

  const setInterceptorsReady = (ready: boolean) => {
    dispatch(
      dashboardActions.SetInterceptorsReady({ interceptorsReady: ready })
    );
  };

  // State to store results from the API
  const [organisations, setOrganisations] = useState<any[] | undefined>(
    undefined
  );
  const [events, setEvents] = useState<any[] | undefined>(undefined);

  // To track if the event was created successfully
  const createEventSuccess = useSelector(
    (state: State) => state.dashboard.createEventSuccess
  );

  // State to hold the ID of the event we may have created
  const [createdEventId, setCreatedEventId] = useState<string | null>(null);

  // state variables to re-trigger render when local storage changes
  const organisationId = useSelector(
    (state: State) => state.dashboard.organisationId
  );
  const eventId = useSelector((state: State) => state.dashboard.eventId);

  // to set call dispatch
  const setOrganisationId = (id: string | null) => {
    dispatch(dashboardActions.SetOrganisationId({ organisationId: id }));
  };

  const setEventId = (id: string | null) => {
    dispatch(dashboardActions.SetEventId({ eventId: id }));
  };

  // Function to set organisation
  const selectOrganisation = (organisation: any) => {
    localStorage.setItem(ORGANISATION_KEY, JSON.stringify(organisation));
    setOrganisationId(organisation.uuid);
  };

  // Function to set event
  const selectEvent = (event: any) => {
    localStorage.setItem(EVENT_KEY, JSON.stringify(event));
    setEventId(event.uuid);
  };

  // Function to clear an organisation
  const clearOrganisation = () => {
    localStorage.removeItem(ORGANISATION_KEY);
    setOrganisationId(null);
  };

  // 1. Get access token, refresh token and organisation from local storage
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const organisation = localStorage.getItem(ORGANISATION_KEY);
  const event = localStorage.getItem(EVENT_KEY);

  // State to track interceptors
  const [requestInterceptor, setRequestInterceptor] = useState<number | null>(
    null
  );
  const [responseInterceptor, setResponseInterceptor] = useState<number | null>(
    null
  );

  const loadingScreen = () => {
    return (
      <PreDashboardLayout>
        <div className="text-white items-center justify-center flex h-screen">
          <svg
            className="animate-spin h-10 w-10 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </PreDashboardLayout>
    );
  };

  // 1. (a) Set organisation ID from local storage on page load
  useEffect(() => {
    if (organisation) {
      setOrganisationId(JSON.parse(organisation).uuid);
    }
  }, []);

  // 1. (b) Set event ID from local storage on page load
  useEffect(() => {
    if (event) {
      setEventId(JSON.parse(event).uuid);
    }
  }, []);

  useEffect(() => {
    // 2. Check if the access token is there or not
    if (!accessToken) {
      signOut();
      return;
    }

    // 3. Check the access token is actually valid
    const isTokenExpired = isExpired(accessToken);
    if (isTokenExpired) {
      signOut();
    }
  }, [accessToken]);

  // 4. Check if the organisation is there or not
  useEffect(() => {
    const fetchOrganisations = async () => {
      setLoading(true);
      try {
        const organisationsResponse = await axios.get(
          `${BASE_URL}/dashboard/organisations`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (organisationsResponse.data.length === 1) {
          selectOrganisation(organisationsResponse.data[0]);
        }
        setOrganisations(organisationsResponse.data);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching organisations:", error);
      }
    };

    fetchOrganisations();
  }, [organisation]);

  // 5. When there is an organisation, get events
  useEffect(() => {
    const fetchEvents = async () => {
      setEventsLoading(true); // Start event loading
      setLoading(true);
      try {
        const eventsResponse = await axios.get(`${BASE_URL}/dashboard/events`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            OrganisationId: organisationId,
          },
        });

        // Set the events
        setEvents(eventsResponse.data);

        // If we have more than one event, we can set the first one as the current event
        if (eventsResponse.data.length > 0) {
          // todo: we would have additional logic here to determine what event is the current one
          // maybe a setting that says what is default in the future
          selectEvent(eventsResponse.data[0]);
        } else {
          setInterceptorsReady(false);
        }

        setEventsLoading(false); // Stop event loading after fetch
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setEventsLoading(false); // Stop loading even in case of error
      }
    };

    if (organisationId) {
      fetchEvents();
    }
  }, [organisationId]);

  // 6. (a) Setup Access Token interceptor when we have an access token
  useEffect(() => {
    if (accessToken) {
      if (requestInterceptor !== null) {
        axios.interceptors.request.eject(requestInterceptor);
      }
      if (responseInterceptor !== null) {
        axios.interceptors.response.eject(responseInterceptor);
      }

      const newRequestInterceptor = axios.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem(ACCESS_TOKEN_KEY);
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      const newResponseInterceptor = axios.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          // if the response is 401
          if (error.response && error.response.status === 401) {
            // if the 401 is from the refresh token endpoint, sign out
            if (error.response.request.responseURL.includes("/token/refresh")) {
              signOut();
              return Promise.reject(error);
            } else if (refreshToken) {
              // if we have a refresh token try refresh
              try {
                const response = await refreshAccessToken(refreshToken);
                const accessToken = response.data.access;

                // If we have an access token
                if (accessToken) {
                  // Save it to the local storage
                  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

                  // setInterceptorsReady(false);

                  // Remove the old request interceptor
                  axios.interceptors.request.eject(requestInterceptor);

                  // Clone the original request and update the Authorization header
                  const originalRequest = error.config;

                  // Set the new header. Although, since we set the local storage and ejected the header, I don't think this is neccessary, but a good step nonetheless
                  originalRequest.headers.Authorization = `Bearer ${accessToken}`;

                  // retry the request
                  return axios(originalRequest);
                }
              } catch (refreshError) {
                console.error("Failed to refresh token", refreshError);
                signOut();
              }
            }
          }
          // if the response is 403, it's probably because the user doesn't have access to the event (the permission handler is saying no)
          if (error.message && error.response.status == 403) {
            history.push("/dashboard/permission-denied");
          }
          return Promise.reject(error);
        }
      );

      // Store the new interceptor IDs to the state
      setRequestInterceptor(newRequestInterceptor);
      setResponseInterceptor(newResponseInterceptor);

      // Clean up the interceptors when the component is unmounted
      // return () => {
      //   axios.interceptors.request.eject(requestInterceptor);
      //   axios.interceptors.response.eject(responseInterceptor);
      // };
    }
  }, [accessToken]);

  // 6. (b) When we have an organisation, setup an interceptor for that
  useEffect(() => {
    if (organisationId) {
      const requestInterceptor = axios.interceptors.request.use(
        (config) => {
          config.headers.OrganisationId = organisationId;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      // Clean up the interceptors when the component is unmounted
      return () => {
        axios.interceptors.request.eject(requestInterceptor);
      };
    }
  }, [organisationId]);

  // 6. (c) Finally, when we have an event, setup the Axios interceptors and set interceptors ready
  useEffect(() => {
    if (organisationId && eventId) {
      const requestInterceptor = axios.interceptors.request.use(
        (config) => {
          config.headers.EventId = eventId;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      if (eventId && organisationId) {
        // Set interceptors as ready after they're set up
        setInterceptorsReady(true);
      }

      // Clean up the interceptors when the component is unmounted
      return () => {
        axios.interceptors.request.eject(requestInterceptor);
        // axios.interceptors.response.eject(responseInterceptor);
      };
    }
  }, [eventId]);

  // Prevent rendering the children or any UI until interceptors are set and events loading is done
  if (eventId && organisationId && !interceptorsReady) {
    return loadingScreen();
  }

  // If we finally have an event and interceptors are ready, render the children
  if (eventId && interceptorsReady) {
    return <>{children}</>;
  }

  // If the organisation exists and events are still being loaded, show a loading indicator
  if (organisationId && eventsLoading) {
    return loadingScreen();
  }

  // If the organisation exists but has no events
  if (events && events.length === 0 && organisationId && !eventsLoading) {
    return (
      <PreDashboardLayout>
        <div
          className={`flex justify-center text-white w-full px-10 ${
            createEventSuccess ? "h-screen" : ""
          }`}
        >
          <div className="max-w-6xl w-full lg:flex md:px-32 lg:px-0">
            <div
              className={`lg:w-2/5 border-l dark:border-gray-900 border-dashed border-gray-200 flex px-6 ${
                createEventSuccess ? "items-center -mt-10" : "lg:pt-24 pt-12"
              }`}
            >
              <div>
                {darkMode.isDarkMode ? (
                  <svg
                    id="Livello_2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 648.3 150.34"
                    className="ml-1"
                    height="40"
                  >
                    <g id="Livello_1-2">
                      <rect
                        className="fill-white"
                        x="111.99"
                        y="38.89"
                        width="33.07"
                        height="109.44"
                      />
                      <path
                        className="fill-white"
                        d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                      />
                      <polygon
                        className="fill-white"
                        points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                      />
                      <path
                        className="fill-white"
                        d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                      />
                      <path
                        className="fill-white"
                        d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                      />
                      <path
                        className="fill-white"
                        d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                      />
                      <polygon
                        className="fill-white"
                        points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    id="Livello_2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 648.3 150.34"
                    className="ml-1"
                    height="40"
                  >
                    <g id="Livello_1-2">
                      <rect
                        className="fill-indigo-500"
                        x="111.99"
                        y="38.89"
                        width="33.07"
                        height="109.44"
                      />
                      <path
                        className="fill-indigo-500"
                        d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                      />
                      <polygon
                        className="fill-indigo-500"
                        points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                      />
                      <path
                        className="fill-indigo-500"
                        d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                      />
                      <path
                        className="fill-indigo-500"
                        d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                      />
                      <path
                        className="fill-indigo-500"
                        d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                      />
                      <polygon
                        className="fill-indigo-500"
                        points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                      />
                    </g>
                  </svg>
                )}
                <p className="mt-6 text-sm/6 text-gray-400 px-2">
                  Welcome to Ticketr 👋! The organisation does not have any
                  events yet. Create the first below.{" "}
                  <span className="text-xs font-normal">
                    (Don't worry, you can change these details later)
                  </span>
                </p>
              </div>
            </div>
            <div
              className={`lg:w-3/5 border-x dark:border-gray-900 border-dashed border-gray-200 flex ${
                createEventSuccess ? "items-center" : ""
              }`}
            >
              <div className="w-full rounded-xl shadow-md dark:bg-dark-secondary bg-white ring-1 ring-black/5 my-16">
                <div className="">
                  {createEventSuccess ? (
                    <div className="flex py-6 pl-2 pr-12">
                      <div className="w-56 mx-4">
                        <Lottie animationData={successAnimation} loop={false} />
                      </div>
                      <div className="pt-3">
                        <h1 className="text-gray-900 dark:text-white text-3xl font-semibold">
                          Submitted Event for Creation
                        </h1>
                        <p className="pt-4 pb-2 text-gray-500 dark:text-gray-400 text-sm">
                          To make sure Ticketr events are high quality and are
                          verified as being run by the organisers, we review all
                          events before they go live. Your event is currently in
                          draft mode and you can still start adding tickets and
                          setting up your event.
                          <br />
                          <br />
                          We also like to make sure that we offer the best
                          price/fee to our organisers, so we will be in touch
                          before the event goes live. Please let your account
                          manager know you have submitted an event for review.
                        </p>

                        <button
                          onClick={() => {
                            // dispatch(dashboardActions.GetEvents());
                            dispatch(
                              dashboardActions.SetEventId({
                                eventId: createdEventId,
                              })
                            );
                          }}
                          type="button"
                          className="mt-4 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Okay, go to event
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="p-7 sm:p-11">
                      <h1 className="text-base/6 font-medium dark:text-white text-gray-900">
                        Create event
                      </h1>
                      <p className="mt-1 text-sm/5 text-gray-400">
                        To get started, create your first event below
                      </p>

                      <div className="-mx-6">
                        <CreateEventFormik
                          callback={(eventId: string) =>
                            setCreatedEventId(eventId)
                          }
                          outsideModal
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div
                  onClick={clearOrganisation}
                  className="m-1.5 cursor-pointer rounded-lg bg-gray-50 dark:bg-dark-primary dark:text-white text-gray-500 py-4 text-center text-sm/5 ring-1 ring-black/5 flex justify-center items-center"
                >
                  <ArrowsRightLeftIcon className="h-5 w-5 dark:text-white text-gray-500 mr-2" />
                  Want to change organisation? Click here
                </div>
              </div>
            </div>
          </div>
        </div>
      </PreDashboardLayout>
    );
  }

  // If no organisation is selected, allow the user to pick one
  if (!organisation && organisations) {
    return (
      <PreDashboardLayout>
        <div className="flex items-center justify-center h-screen">
          {/* <h2 className="max-w-3xl text-pretty font-medium tracking-tighter dark:text-white text-6xl text-white">
            Select an organisation
          </h2> */}
          {/* {organisations.map((org) => (
            <div key={org.id} onClick={() => selectOrganisation(org)}>
              {org.name}
            </div>
          ))} */}
          <div className="max-w-md w-full h-screen flex-1 items-center flex border-x dark:border-gray-900 border-dashed border-gray-200">
            <div className="w-full max-w-md rounded-xl shadow-md dark:bg-dark-secondary bg-white ring-1 ring-black/5">
              <div className="p-10">
                <div className="flex items-start">
                  {darkMode.isDarkMode ? (
                    <svg
                      id="Livello_2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 648.3 150.34"
                      className="ml-1"
                      height="25"
                    >
                      <g id="Livello_1-2">
                        <rect
                          className="fill-white"
                          x="111.99"
                          y="38.89"
                          width="33.07"
                          height="109.44"
                        />
                        <path
                          className="fill-white"
                          d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                        />
                        <polygon
                          className="fill-white"
                          points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                        />
                        <path
                          className="fill-white"
                          d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                        />
                        <path
                          className="fill-white"
                          d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                        />
                        <path
                          className="fill-white"
                          d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                        />
                        <polygon
                          className="fill-white"
                          points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      id="Livello_2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 648.3 150.34"
                      className="ml-1"
                      height="25"
                    >
                      <g id="Livello_1-2">
                        <rect
                          className="fill-indigo-500"
                          x="111.99"
                          y="38.89"
                          width="33.07"
                          height="109.44"
                        />
                        <path
                          className="fill-indigo-500"
                          d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                        />
                        <polygon
                          className="fill-indigo-500"
                          points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                        />
                        <path
                          className="fill-indigo-500"
                          d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                        />
                        <path
                          className="fill-indigo-500"
                          d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                        />
                        <path
                          className="fill-indigo-500"
                          d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                        />
                        <polygon
                          className="fill-indigo-500"
                          points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                        />
                      </g>
                    </svg>
                  )}
                </div>
                <h1 className="mt-8 text-base/6 font-medium dark:text-white text-gray-900">
                  Select an organisation
                </h1>
                <p className="mt-1 text-sm/5 text-gray-400">
                  To continue, you must select an organisation
                </p>

                {organisations.length == 0 && (
                  <div className="-mb-4">
                    <p className="mt-1 text-sm/5 text-gray-400 mt-6">
                      Your account is not linked to any organisations 🥺. Please
                      reach out to your account manager or{" "}
                      <a
                        href="mailto:hello@ticketr.events"
                        className="underline cursor-pointer"
                      >
                        email us
                      </a>
                      .
                    </p>

                    <p className="mt-5 text-sm/5 text-gray-400">
                      If they've resolved the issue{" "}
                      <a
                        onClick={() => window.location.reload()}
                        className="underline cursor-pointer"
                      >
                        click here
                      </a>{" "}
                      to refresh the page.
                    </p>
                  </div>
                )}

                <div>
                  <ul
                    role="list"
                    className="mx-auto mt-6 grid grid-cols-1 gap-4 -mx-2"
                  >
                    {organisations.map((organisation) => (
                      <li
                        key={organisation.uuid}
                        className="gap-4 flex cursor-pointer hover:dark:bg-dark-primary/50 hover:bg-gray-50 px-2 py-3 rounded-lg"
                        onClick={() => selectOrganisation(organisation)}
                      >
                        {organisation.logo ? (
                          <img
                            alt=""
                            src={organisation.logo}
                            className="size-12 rounded-full"
                          />
                        ) : (
                          <div className="dark:bg-dark-primary bg-gray-100 h-12 w-12 rounded-full flex items-center justify-center dark:text-white text-gray-900">
                            {organisation.name
                              .split(" ")
                              .map((word: string) => word[0])
                              .join("")}
                          </div>
                        )}
                        <div className="text-sm/6">
                          <h3 className="font-medium dark:text-white text-gray-900">
                            {organisation.name}
                          </h3>
                          <p className="dark:text-gray-300 text-gray-500">
                            {organisation.domain}
                          </p>
                        </div>

                        <div className="flex-1 flex items-center justify-end">
                          <ChevronRightIcon className="h-6 w-6 text-gray-400" />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="-mt-2 m-1.5 rounded-lg bg-gray-50 dark:bg-dark-primary dark:text-white text-gray-500 py-4 text-center text-sm/5 ring-1 ring-black/5">
                No organisations? Reach out to your account manager
              </div>
            </div>
          </div>
        </div>
      </PreDashboardLayout>
    );
  }

  return loadingScreen();
};
