import { Field } from "formik";

interface Props {
  name: string;
}
export const Checkbox = ({ name }: Props) => {
  return (
    <>
      <Field
        id={name}
        name={name}
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      />
    </>
  );
};
