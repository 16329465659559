import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { format, isValid, set, startOfDay } from "date-fns";
import { getIn, useField, useFormikContext } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import DatePicker from "./custom/Components/DatePicker";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  name: string;
  hasTime: boolean;
}

export const DateTimePicker = ({ name, hasTime }: Props) => {
  const { values, setFieldValue, errors } = useFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  const [field] = useField(name);

  // Anytime the value changes, set the form value
  // useEffect(() => {
  //   setFieldValue(name, value);
  // }, [value]);

  useEffect(() => {
    // if it already has a value
    if (!field.value) {
      setFieldValue(name, new Date());
    }
  }, []);

  const handleDateChange = (newDate: Date) => {
    const fieldValue = new Date(field.value);
    // Get the time components from the original date
    const hours = fieldValue.getHours();
    const minutes = fieldValue.getMinutes();
    const seconds = fieldValue.getSeconds();
    const milliseconds = fieldValue.getMilliseconds();

    // Set just the date part
    const updatedDate = set(startOfDay(fieldValue), {
      year: newDate.getFullYear(),
      month: newDate.getMonth(),
      date: newDate.getDate(),
    });

    // Set the time components back to the updated date
    updatedDate.setHours(hours);
    updatedDate.setMinutes(minutes);
    updatedDate.setSeconds(seconds);
    updatedDate.setMilliseconds(milliseconds);

    setFieldValue(name, updatedDate);
  };

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTimeValue = event.target.value;

    // Parse the new time input (assuming it's in HH:mm format)
    const [newHours, newMinutes] = newTimeValue.split(":").map(Number);

    // Set the newHours and newMinutes
    const updatedDate = set(new Date(field.value), {
      hours: newHours,
      minutes: newMinutes,
      seconds: 0,
    });

    setFieldValue(name, updatedDate);
  };

  return (
    <>
      {field.value && (
        <div className="flex">
          <DatePicker
            options={{
              autoHide: true,
              todayBtn: false,
              clearBtn: true,
              inputNameProp: name,
              inputIdProp: name,
              maxDate: new Date("2030-01-01"),
              minDate: new Date("1950-01-01"),
              theme: {
                background: "bg-white dark:bg-zinc-900",
                todayBtn: "",
                clearBtn: "",
                icons: "",
                text: "",
                disabledText: "",
                input: "",
                inputIcon: "",
                selected: "",
              },
              icons: {
                prev: () => <ArrowLeftIcon className="w-6 h-6" />,
                next: () => <ArrowRightIcon className="w-6 h-6" />,
              },
              datepickerClassNames: "top-12",
              defaultDate: field.value ? new Date(field.value) : new Date(),
              language: "en",
            }}
            value={new Date(field.value)}
            onChange={handleDateChange}
            show={isOpen}
            setShow={setIsOpen}
          />
          {hasTime && (
            <div className="ml-4">
              <input
                onChange={handleTimeChange}
                type="time"
                name={`${name}Time`}
                id={`${name}Time`}
                required={true}
                value={
                  isValid(new Date(field.value)) &&
                  !isNaN(new Date(field.value).getTime())
                    ? format(new Date(field.value), "HH:mm")
                    : ""
                }
                className="text-md py-2 items-center block dark:bg-dark-secondary dark:ring-white/5 dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 leading-[1.6rem]"
              />
            </div>
          )}
        </div>
      )}

      {getIn(errors, name) && (
        <div className="mt-2 text-red-600 flex items-cente">
          <ExclamationCircleIcon className="h-5 w-5 mr-1.5" />
          <p className="-mt-0.5">{getIn(errors, name)}</p>
        </div>
      )}
    </>
  );
};
