import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { AddonCartItem } from "../../../models/Cart";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  submitOrder: () => void;
}

export const AddonCart = ({ open, setOpen, submitOrder }: Props) => {
  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const addonOrder = useSelector((state: State) => state.cart.addonOrder);
  const [subTotal, setSubTotal] = useState(0);
  const [fees, setFees] = useState(0);

  // Totals
  useEffect(() => {
    setSubTotal(addonCart.reduce((sum, current) => sum + current.lineTotal, 0));
  }, [addonCart]);

  // Fees
  useEffect(() => {
    setFees(
      addonCart.reduce(
        (sum, current) => sum + current.quantity * parseFloat(current.fee),
        0
      )
    );
  }, [addonCart]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Add-ons
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {addonCart.map((cartItem: AddonCartItem) => (
                              <li key={cartItem.name} className="flex py-6">
                                <div className="flex flex-1 flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                      <h3>{cartItem.name}</h3>
                                      <p className="ml-4">
                                        &euro;{cartItem.lineTotal.toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex flex-1 items-end justify-between text-sm">
                                    <p className="text-gray-500">
                                      Quantity {cartItem.quantity}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-600 mb-2">
                        <p>Subtotal</p>
                        <p>&euro;{subTotal.toFixed(2)}</p>
                      </div>
                      <div className="flex justify-between text-base font-medium text-gray-600 mb-4 pb-4 border-b border-gray-200">
                        <p>Service Fees</p>
                        <p>&euro;{fees.toFixed(2)}</p>
                      </div>
                      <div className="flex justify-between text-base font-medium text-gray-900 my-1">
                        <p>Total</p>
                        <p>&euro;{(subTotal + fees).toFixed(2)}</p>
                      </div>
                      {!addonOrder && (
                        <>
                          <div className="mt-6">
                            <button
                              onClick={() => {
                                setOpen(false);
                                setTimeout(() => submitOrder(), 500);
                              }}
                              className="w-full flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                            >
                              Continue to payment
                            </button>
                          </div>
                          <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                            <p>
                              or{" "}
                              <button
                                type="button"
                                className="font-medium text-blue-600 hover:text-blue-500"
                                onClick={() => setOpen(false)}
                              >
                                Continue Browsing Add-ons
                                <span aria-hidden="true"> &rarr;</span>
                              </button>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
