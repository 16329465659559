import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export const ViewEventButton = () => {
  const event = JSON.parse(localStorage.getItem("event") || "{}");
  return (
    <a
      target="_blank"
      href={`/${event.slug}`}
      className="flex items-center dark:text-white text-sm"
    >
      View Event <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-2" />
    </a>
  );
};
