import { useState } from "react";
import { Addon } from "../../models/Tickets";
import { AddonQuantity } from "./AddonQuantity";

interface Props {
  addon: Addon;
}

export const AddonItem = ({ addon }: Props) => {
  // State to manage the visibility of the full description
  const [showFullDescription, setShowFullDescription] = useState(false);

  // Toggle function to show/hide the full description
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Function to render the description
  const renderDescription = () => {
    if (addon.description) {
      if (addon.description.length > 50 && !showFullDescription) {
        return (
          <>
            {`${addon.description.substring(0, 65)}... `}
            <button
              onClick={toggleDescription}
              className="text-blue-500 hover:text-blue-700"
            >
              more info
            </button>
          </>
        );
      }
      return addon.description;
    }
  };

  return (
    <li
      key={addon.uuid}
      className={`sm:py-4 mb-6 lg:mb-0 rounded-md list-none ${
        false ? "border-blue-600" : "border-gray-100"
      }`}
    >
      <div className="sm:flex items-center">
        <div>
          <h1 className="font-semibold">{addon.name}</h1>
          <p className="font- text-sm">
            &euro;{addon.price} + &euro;{addon.fee} service fee
          </p>
        </div>
        <div className="flex-1 flex sm:justify-end mt-3 sm:mt-0">
          {addon.isSoldOut ? (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 text-center">
              Sold Out
            </span>
          ) : (
            <AddonQuantity addon={addon} />
          )}
        </div>
      </div>
      {addon.description && (
        <p className="text-sm text-gray-500 mt-3">{renderDescription()}</p>
      )}
    </li>
  );
};
