import { useEffect } from "react";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cartActions } from "../store/cart";

export const Completionist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      cartActions.SetCartError({
        cartError:
          "Your cart has expired, please re-add your tickets to your cart.",
      })
    );
    dispatch(
      cartActions.SetShowCheckout({
        showCheckout: false,
      })
    );
    dispatch(cartActions.ClearCart());
  }, []);
  return <span className="text-gray-500">Cart Expired</span>;
};

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }: any) => {
  const zeroPad = ({ num, places }: any) => String(num).padStart(places, "0");

  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span className="text-gray-500 flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-5 h-5 mr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span className="mr-1">Holding tickets for</span> {minutes}:
        {zeroPad({ num: seconds, places: 2 })}
      </span>
    );
  }
};

interface Props {
  heldUntil: string;
}

export const HoldCountdown = ({ heldUntil }: Props) => {
  return (
    <>
      <Countdown date={new Date(heldUntil)} renderer={renderer} />
    </>
  );
};
