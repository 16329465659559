import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { DashboardLayout } from "../../../components/dashboard/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { useEffect } from "react";
import { dashboardActions } from "../../../store/dashboard";
import { SkeletonLoading } from "../../../components/SkeletonLoading";
import toast from "react-hot-toast";
import { ViewEventButton } from "../../../components/dashboard/nav/ViewEventButton";
import { ORGANISATION_KEY } from "../../protected-route";

export const OrganisationSettings = () => {
  const dispatch = useDispatch();

  const organisation = JSON.parse(
    localStorage.getItem(ORGANISATION_KEY) || "{}"
  );

  return (
    <DashboardLayout
      requiresSuperuser={true}
      requiredPermissions={["view_organisation"]}
      pageTitle="Organisation Settings"
      rightControls={<ViewEventButton />}
    >
      {organisation ? (
        <div className="mt-2">
          <dl className="divide-y divide-gray-200 dark:divide-white/5 px-10">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Name
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {organisation.name}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Organisation Domain
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                <div>
                  <div className="mt-2 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-200 dark:border-white/5  px-3 dark:text-gray-400 text-gray-900 sm:text-sm">
                      https://
                    </span>
                    <input
                      id="company-website"
                      name="company-website"
                      type="text"
                      value={organisation.domain}
                      disabled
                      placeholder="www.example.com"
                      className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-200 dark:ring-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-dark-secondary bg-gray-50 dark:text-gray-400 text-gray-900"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Organisation View
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {organisation.organisationViewEnabled ? (
                  <>
                    <div className="flex max-w-md items-center gap-3 whitespace-nowrap">
                      <span
                        data-slot="control"
                        className="relative block w-full before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow dark:before:hidden after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-indigo-500 has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none before:has-[[data-invalid]]:shadow-red-500/10"
                      >
                        <input
                          className="relative block w-full appearance-none rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)] text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-gray-400 border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20 bg-transparent dark:bg-white/5 focus:outline-none data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-500 data-[invalid]:data-[hover]:dark:border-red-500 data-[disabled]:border-zinc-950/20 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]"
                          aria-label="Invite link"
                          id="headlessui-input-:R1dnlfalta:"
                          data-headlessui-state=""
                          readOnly
                          value={`https://${organisation.domain}`}
                        />
                      </span>
                      <button
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:w-auto"
                        type="button"
                        data-headlessui-state=""
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://${organisation.domain}`
                          );
                          toast.success("Copied URL to Clipboard");
                        }}
                      >
                        Copy link
                        <span
                          className="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden"
                          aria-hidden="true"
                        ></span>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-4">
                      While each event has it's own specific URL, you can
                      request/enable a Organisation View URL which will allow
                      you to view all events under your organisation.
                    </div>
                    <div>
                      <p>
                        Please contact your account manage to enable the
                        Organisation View.
                      </p>
                      {/* <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:w-auto"
                        // onClick={() => handleSubmit()}
                      >
                        Request URL
                      </button> */}
                    </div>
                  </>
                )}
              </dd>
            </div>
          </dl>
        </div>
      ) : (
        <div className="p-10">
          <SkeletonLoading />
        </div>
      )}
    </DashboardLayout>
  );
};
