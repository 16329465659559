import { useSelector } from "react-redux";
import { BillingDetailsForm } from "../forms/BillingDetailsForm";
import { PaymentDetails } from "./PaymentDetails";
import { State } from "../store/store";
import { useEffect, useState } from "react";
import { MobileCart } from "./portal/MobileCart";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";

export const Checkout = () => {
  const cart = useSelector((state: State) => state.cart.cart);
  const carbonFootprintContribution = useSelector(
    (state: State) => state.cart.carbonFootprintContribution
  );
  const promoCode = useSelector((state: State) => state.cart.appliedPromoCode);
  const [mobileCartOpen, setMobileCartOpen] = useState(false);
  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const order = useSelector((state: State) => state.cart.order);
  const billingDetailsSubmitting = useSelector(
    (state: State) => state.cart.billingDetailsSubmitting
  );

  return (
    <>
      <div className="px-4 pt-16 pb-36 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
        <MobileCart
          open={mobileCartOpen}
          setOpen={setMobileCartOpen}
          cart={cart}
          addonCart={addonCart}
          readOnly={true}
        />
        <div className="mx-auto max-w-lg lg:max-w-none">
          {!order && (
            <div className="absolute right-0 mr-5 lg:hidden mt-0.5">
              <button
                onClick={() => setMobileCartOpen(true)}
                className="group -m-2 flex items-center p-2"
              >
                <ShoppingCartIcon className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                <span className="ml-1 text-sm text-gray-500">(View Cart)</span>
                <span className="sr-only">items in cart, view bag</span>
              </button>
            </div>
          )}
          {/* show this component until we get an order then we show the payment one */}
          <BillingDetailsForm
            cart={cart}
            addonCart={addonCart}
            promoCode={promoCode}
            carbonFootprintContribution={carbonFootprintContribution}
          />
          {!order && billingDetailsSubmitting ? (
            <div className="flex items-center justify-center w-full h-64">
              <svg
                className="animate-spin h-12 w-12 text-blue-200"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            <>{order && <PaymentDetails order={order} />}</>
          )}
        </div>
      </div>
    </>
  );
};
