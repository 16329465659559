import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { PromoCodeForm } from "../forms/PromoCodeForm";
import { PromoCodeType } from "../models/Cart";
import { Ticket as TicketModel } from "../models/Tickets";
import { getTickets } from "../queries";
import { State } from "../store/store";
import { Error } from "./Error";
import { HoldCountdown } from "./HoldCountdown";
import { Ticket } from "./Ticket";
import { getFeatureFlag } from "../utils/feature-flags";

export const Tickets = () => {
  const { isLoading, data } = useQuery<
    AxiosResponse<Array<TicketModel>>,
    Error
  >("tickets", getTickets);
  // const hiddenTicket = useSelector((state: State) => state.cart.hiddenTicket);
  const appliedPromoCode = useSelector(
    (state: State) => state.cart.appliedPromoCode
  );
  const cartError = useSelector((state: State) => state.cart.cartError);
  const event = useSelector((state: State) => state.cart.event);
  const portalNavEnabled = getFeatureFlag("PORTAL_NAV_ENABLED");

  return (
    <>
      <div
        className={`px-4 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16 ${
          event?.showEventDetails ? "-mt-6 lg:-mt-1 lg:pt-6" : ""
        } ${portalNavEnabled ? "lg:pt-16" : "pt-8"}`}
      >
        {cartError && (
          <div className="mb-4 -mt-4 mx-auto max-w-lg lg:max-w-none">
            <Error error={cartError} />
          </div>
        )}
        <PromoCodeForm />
        {/* If it's a REVEAL promo code */}
        {appliedPromoCode?.ticket &&
          appliedPromoCode.codeType === PromoCodeType.REVEAL && (
            <ul>
              <div className="border-b border-gray-200 mb-6">
                <p className="text-sm font-medium text-gray-700 mb-2">
                  Unlocked with Code
                </p>
                <Ticket ticket={appliedPromoCode.ticket} />
              </div>
            </ul>
          )}
        <div className="h-full">
          <div className="mx-auto max-w-lg lg:max-w-none">
            {data && data.data ? (
              <>
                <ul role="list">
                  {data.data.map((ticket: TicketModel) => (
                    <Ticket key={ticket.uuid} ticket={ticket} />
                  ))}
                </ul>
              </>
            ) : (
              <div className="flex items-center justify-center w-full h-screen -mt-24">
                {" "}
                <svg
                  className="animate-spin -ml-1 mr-3 h-8 w-8 text-blue-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
