// yup-extended.ts
import * as yup from "yup";

type AnyPresentValue = {};
type _<T> = T extends {}
  ? {
      [k in keyof T]: T[k];
    }
  : T;
type MakeKeysOptional<T> = T extends yup.AnyObject ? _<yup.MakePartial<T>> : T;

yup.addMethod<yup.StringSchema>(yup.string, "emptyAsUndefined", function () {
  return this.transform((value) => (value ? value : undefined));
});

yup.addMethod<yup.NumberSchema>(yup.number, "emptyAsUndefined", function () {
  return this.transform((value, originalValue) =>
    String(originalValue)?.trim() ? value : undefined
  );
});

// One by Value
yup.addMethod<yup.MixedSchema>(
  yup.mixed,
  "oneOfByValue",
  function (
    this: yup.MixedSchema<
      AnyPresentValue | undefined,
      yup.AnyObject,
      undefined,
      ""
    >,
    enums: ReadonlyArray<any>,
    message?: any
  ): yup.MixedSchema<
    AnyPresentValue | undefined,
    yup.AnyObject,
    undefined,
    ""
  > {
    // Access the existing _whitelist or create an empty array if it doesn't exist
    const whitelist = this._whitelist || enums;

    // Modify the whitelist as needed (e.g., add enum values)
    // For example, let's say you want to add values ['foo', 'bar']:

    enums.forEach((_enum: any) => {
      whitelist.add(_enum);
    });

    // Assign the modified whitelist back to the schema
    this._whitelist = whitelist;

    return this;
  }
);

// Many by value
yup.addMethod<yup.MixedSchema>(
  yup.mixed,
  "manyOfByValue",
  function (
    this: yup.MixedSchema<
      AnyPresentValue | undefined,
      yup.AnyObject,
      undefined,
      ""
    >,
    enums: ReadonlyArray<any>,
    min?: number,
    message?: string
  ): yup.MixedSchema<
    AnyPresentValue | undefined,
    yup.AnyObject,
    undefined,
    ""
  > {
    // Access the existing _whitelist or create an empty Set if it doesn't exist
    const whitelist = this._whitelist || new Set();

    // Add each enum value to the whitelist
    enums.forEach((_enum) => {
      whitelist.add(_enum);
    });

    // Assign the modified whitelist back to the schema
    this._whitelist = whitelist;
    this._isMany = true;

    return this.test({
      name: "manyOfByValue",
      message: message
        ? message
        : `Must have at least ${min} items from the allowed values`,
      test: function (value) {
        if (min) {
          if (!Array.isArray(value)) {
            return false;
          }
          const hasMinItems = value.length >= min;
          return hasMinItems;
        } else {
          return true;
        }
      },
    });
  }
);

// Money field to display currency before
yup.addMethod<yup.NumberSchema>(yup.number, "money", function () {
  return this.meta({ isMoney: true });
});

declare module "yup" {
  interface StringSchema<
    TType extends yup.Maybe<string> = string | undefined,
    TContext = yup.AnyObject,
    TDefault = undefined,
    TFlags extends yup.Flags = ""
  > extends yup.Schema<TType, TContext, TDefault, TFlags> {
    emptyAsUndefined(): StringSchema<TType, TContext>;
  }

  interface NumberSchema<
    TType extends yup.Maybe<number> = number | undefined,
    TContext = yup.AnyObject,
    TDefault = undefined,
    TFlags extends yup.Flags = ""
  > extends yup.Schema<TType, TContext, TDefault, TFlags> {
    emptyAsUndefined(): NumberSchema<TType, TContext>;
    money(): NumberSchema<TType, TContext>;
  }

  interface MixedSchema<
    TType extends yup.Maybe<AnyPresentValue> = AnyPresentValue | undefined,
    TContext = yup.AnyObject,
    TDefault = undefined,
    TFlags extends yup.Flags = ""
  > extends yup.Schema<TType, TContext, TDefault, TFlags> {
    _isMany?: boolean;
    oneOfByValue(
      enums: ReadonlyArray<any>,
      message?: any
    ): MixedSchema<TType, TContext>;

    manyOfByValue(
      enums: ReadonlyArray<any>,
      min?: number,
      message?: string
    ): MixedSchema<TType, TContext>;
  }
}

export default yup;
