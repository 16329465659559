import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { dashboardActions } from "../../../store/dashboard";
import { Fragment, useEffect, useRef } from "react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { CreateEventForm } from "./CreateEventForm";
import { Formik } from "formik";
import yup from "../../../crud/yup-extended";
import { Event } from "../../../models/Dashboard";
import successAnimation from "../../../assets/successAnimation.json";
import Lottie from "lottie-react";
import { ORGANISATION_KEY, signOut } from "../../../pages/protected-route";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { CreateEventFormik } from "./CreateEventFormik";

interface Props {
  isFirstEventCreate?: boolean;
}

export const CreateEventModal = ({ isFirstEventCreate }: Props) => {
  const dispatch = useDispatch();
  const createEventModalOpen = useSelector(
    (state: State) => state.dashboard.createEventModalOpen
  );
  const createEventSuccess = useSelector(
    (state: State) => state.dashboard.createEventSuccess
  );
  const selectedOrganisation = useSelector(
    (state: State) => state.dashboard.selectedOrganisation
  );
  const organisations = useSelector(
    (state: State) => state.dashboard.organisations
  );

  const setIsOpen = (isOpen: boolean) => {
    dispatch(
      dashboardActions.SetCreateEventModalOpen({ createEventModalOpen: isOpen })
    );
  };

  useEffect(() => {
    dispatch(dashboardActions.GetOrganisations());
  }, []);

  return (
    <Transition.Root show={createEventModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => (isFirstEventCreate ? null : setIsOpen(!false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-75 blur bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          {isFirstEventCreate && (
            <div>
              <div className="absolute right-0 mt-4 mr-4 flex">
                {organisations && organisations.length > 1 && (
                  <div className="mr-4 flex">
                    <button
                      className="text-white underline flex items-center justify-center"
                      onClick={() => {
                        localStorage.removeItem(ORGANISATION_KEY);
                      }}
                    >
                      <ArrowsRightLeftIcon className="h-5 w-5 mr-1.5" />
                      Switch organisation
                    </button>
                  </div>
                )}
                <div className="">
                  <button
                    className="text-white underline"
                    onClick={() => signOut()}
                  >
                    Sign out
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-center absolute w-full mt-10">
                <div>
                  <div className="flex items-center justify-center mb-3">
                    <svg
                      id="Livello_2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 648.3 150.34"
                      className="ml-1"
                      height="50"
                    >
                      <g id="Livello_1-2">
                        <rect
                          className="fill-white"
                          x="111.99"
                          y="38.89"
                          width="33.07"
                          height="109.44"
                        />
                        <path
                          className="fill-white"
                          d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                        />
                        <polygon
                          className="fill-white"
                          points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                        />
                        <path
                          className="fill-white"
                          d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                        />
                        <path
                          className="fill-white"
                          d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                        />
                        <path
                          className="fill-white"
                          d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                        />
                        <polygon
                          className="fill-white"
                          points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                        />
                      </g>
                    </svg>
                  </div>
                  <h1 className="text-white mt-5 font-medium max-w-2xl text-center">
                    Welcome to Ticketr 👋! The {selectedOrganisation?.name}{" "}
                    organisation does not have any events yet. Create the first
                    below.{" "}
                    <span className="text-xs font-normal">
                      (Don't worry, you can change these details later)
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          )}

          <div
            className={`flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ${
              isFirstEventCreate ? "mt-10" : ""
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg border dark:border-white/5 bg-light-primary dark:bg-dark-primary text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                {createEventSuccess ? (
                  <div className="flex py-8 pl-2 pr-12">
                    <div className="w-56 mx-4">
                      <Lottie animationData={successAnimation} loop={false} />
                    </div>
                    <div>
                      <h1 className="text-gray-900 dark:text-white text-3xl font-semibold">
                        Submitted Event for Creation
                      </h1>
                      <p className="pt-2 pb-2 text-gray-500 dark:text-gray-400 font-light">
                        To make sure Ticketr events are high quality and are
                        verified as being run by the organisers, we review all
                        events before they go live. Your event is currently in
                        draft mode and you can still start adding tickets and
                        setting up your event.
                        <br />
                        <br />
                        We also like to make sure that we offer the best
                        price/fee to our organisers, so we will be in touch
                        before the event goes live. Please let your account
                        manager know you have submitted an event for review.
                      </p>

                      <button
                        onClick={() =>
                          dispatch(
                            dashboardActions.SetCreateEventModalOpen({
                              createEventModalOpen: false,
                            })
                          )
                        }
                        type="button"
                        className="mt-4 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Okay, got it
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="bg-light-primary dark:bg-dark-secondary py-3 sm:flex sm:px-5 text-gray-900 dark:text-white font-medium">
                      Create Event
                    </div>
                    <CreateEventFormik />
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
