import { useDispatch, useSelector } from "react-redux";
import { Addon } from "../../../models/Tickets";
import { AddonItem } from "../../addons/AddonItem";
import { State } from "../../../store/store";
import { AddonCart } from "./AddonCart";
import { useState } from "react";
import { PaymentDetails } from "../../PaymentDetails";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { cartActions } from "../../../store/cart";
import { Loading } from "../Loading";
import { useSearchParams } from "react-router-dom";

interface Props {
  addons?: Array<Addon>;
}

export const PurchaseAddons = ({ addons }: Props) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const [addonCartOpen, setAddonCartOpen] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const order = useSelector((state: State) => state.cart.order);
  const billingDetailsSubmitting = useSelector(
    (state: State) => state.cart.billingDetailsSubmitting
  );
  const addonOrder = useSelector((state: State) => state.cart.addonOrder);
  const event = useSelector((state: State) => state.cart.event);

  const getTotalQuantityOfCart = () => {
    let total = 0;
    addonCart.forEach((addon) => {
      total += addon.quantity;
    });
    return total;
  };

  const submitOrder = () => {
    dispatch(
      cartActions.SetBillingDetailsSubmitting({
        billingDetailsSubmitting: true,
      })
    );
    // We want to submit the order but attach it to the existing order
    // Essentially we are creating an addon order
    if (order) {
      dispatch(
        cartActions.CreateAddonOrder({
          addonCart,
          orderUuid: order.uuid,
        })
      );
    } else {
      console.error(
        "Tried to an Addon order but can't because no order is loaded"
      );
    }
  };

  return (
    <div className="">
      <AddonCart
        open={addonCartOpen}
        setOpen={setAddonCartOpen}
        submitOrder={submitOrder}
      />
      <section className="lg:px-0 sm:border-t border-gray-200">
        {addons && addons.length > 0 ? (
          <>
            {!showPaymentForm && addonCart.length > 0 && (
              <div className="mt-6 -mb-2 md:hidden">
                <button
                  onClick={() => setAddonCartOpen(true)}
                  type="submit"
                  className="w-full rounded-md border border-transparent bg-blue-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  <div className="flex items-center justify-center">
                    <>
                      <ShoppingCartIcon className="h-5 w-5 text-white mr-1.5" />{" "}
                      View Cart/Pay
                    </>
                  </div>
                </button>
              </div>
            )}
            <div className="flex items-end">
              <div className="md:mt-3 mt-6 md:mb-0 mb-1">
                <h2
                  id="summary-heading"
                  className="text-lg font-medium text-gray-900 md:mb-0"
                >
                  Add-ons
                </h2>
                <p className="text-sm">Enhance your festival experience</p>
              </div>
              <div className="flex flex-1 justify-end md:mb-3 mb-4">
                {addonCart.length > 0 && (
                  <button
                    onClick={() => setAddonCartOpen(true)}
                    className="group -m-2 flex items-center p-2"
                  >
                    <svg
                      className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                      ></path>
                    </svg>
                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                      {getTotalQuantityOfCart()}
                    </span>
                    <span className="sr-only">items in cart, view bag</span>
                  </button>
                )}
              </div>
            </div>

            {billingDetailsSubmitting ? (
              <div className="pt-32 lg:pt-40 flex w-full h-screen justify-center">
                <Loading />
              </div>
            ) : (
              <>
                {addonOrder && event ? (
                  <PaymentDetails
                    order={addonOrder}
                    redirectUrl={`${process.env.REACT_APP_BASE_URL}/${
                      event.slug
                    }/order?token=${searchParams.get("token")}`}
                  />
                ) : (
                  <>
                    <div className="mx-auto lg:max-w-none">
                      <div className="mt-4">
                        {addons.map((addon: Addon) => (
                          <AddonItem addon={addon} />
                        ))}
                      </div>
                    </div>
                    {addonCart.length > 0 && (
                      <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between hidden lg">
                        <div className="flex justify-between w-full">
                          <div className="order-last">
                            <button
                              type="submit"
                              onClick={() => {
                                submitOrder();
                              }}
                              className="w-full flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50 order-1 sm:w-auto"
                            >
                              {billingDetailsSubmitting ? (
                                <>
                                  <span>Processing</span>
                                  <svg
                                    className="animate-spin h-5 w-5 text-blue-200 ml-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </>
                              ) : (
                                <>Continue to payment</>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>There are no purchasble add-ons available for this order</>
        )}
      </section>
    </div>
  );
};
