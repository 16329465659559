import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CRUDResource } from "./models";
import { dashboardActions } from "../store/dashboard";
import { fork, takeEvery, put } from "@redux-saga/core/effects";
import axios, { AxiosResponse } from "axios";
import { error } from "console";
import { PaginatedResponse } from "../models/Dashboard";
import { convertToKebabCase, singularize } from "./utils";
import { ReactNode } from "react";
import { CRUD_RESOURCES } from "../globals";
import toast from "react-hot-toast";
import { toHeaderCase } from "js-convert-case";

/*
 1. Clean things up and add types
 2. Figure out how we can pass the model type
*/

type SideEffectGenerator = (
  action: PayloadAction<{ page: number }>
) => Generator<any, void, any>;

export const createCRUDReducer = <T extends object, R extends ReactNode>(
  resource: CRUDResource<T, R>
) => {
  const initialState: { [name: string]: PaginatedResponse<any> | boolean } = {
    CreateSlideOverOpen: false,
    IsUpdating: false,
  };

  const slice = createSlice({
    name: resource.name,
    initialState,
    reducers: {
      SetCreateSlideOverOpen: (
        state,
        action: PayloadAction<{ createSlideOverOpen: boolean }>
      ) => {
        state["CreateSlideOverOpen"] = action.payload.createSlideOverOpen;
      },

      // Set an error to show on create
      SetCreateError: (state, action: PayloadAction<{ error: any }>) => {
        state["CreateError"] = action.payload.error;
      },

      // Gets a single instance using an ID
      Get: (state, action: PayloadAction<{ id: string }>) => {},

      // Sets the single instance
      Set: (state, action: PayloadAction<{ instance: any }>) => {
        state["Single"] = action.payload.instance;
      },

      // Gets all instances
      GetAll: (
        state,
        action: PayloadAction<{ page?: number; filters?: string }>
      ) => {
        state["IsLoading"] = true;
      },

      // Searches instances
      SearchAll: (
        state,
        action: PayloadAction<{
          query?: string;
          page?: number;
          filters?: string;
        }>
      ) => {},

      // Sets all instances
      SetAll: (
        state,
        action: PayloadAction<{ paginatedResponse: PaginatedResponse<any> }>
      ) => {
        state["All"] = action.payload.paginatedResponse;
      },

      // Deletes a single instance
      Delete: (state, action: PayloadAction<{ id: string }>) => {},

      // Create Instance
      Create: (
        state,
        action: PayloadAction<{ resource: any; resetForm: () => void }>
      ) => {},

      Update: (
        state,
        action: PayloadAction<{ id: Number; resource: any }>
      ) => {},

      SetIsUpdating(state, action: PayloadAction<{ isUpdating: boolean }>) {
        state["IsUpdating"] = action.payload.isUpdating;
      },

      SetIsLoading(state, action: PayloadAction<{ isLoading: boolean }>) {
        state["IsLoading"] = action.payload.isLoading;
      },
    },
  });

  return slice.reducer;
};

// Create an object that holds all the reducers for CRUD resources
export const createReducers = <T extends object, R extends ReactNode>(
  resources: CRUDResource<T, R>[]
) => {
  const reducers: { [key: string]: any } = {};
  resources.forEach((resource) => {
    reducers[resource.name] = createCRUDReducer(resource);
  });
  return reducers;
};

export function* createCRUDSideEffects() {
  axios.defaults.withCredentials = true;

  const BASE_URL = import.meta.env.VITE_API_BASE_URL;

  for (const resource of CRUD_RESOURCES) {
    const resourceName = resource.name.replaceAll(" ", "");

    yield takeEvery(
      `${resourceName}/Get`,
      function* (action: PayloadAction<{ id: string }>) {
        try {
          const response: AxiosResponse<any> = yield axios.get(
            `${BASE_URL}/dashboard/${convertToKebabCase(resourceName)}/${
              action.payload.id
            }`
          );
          yield put({
            type: `${resourceName}/Set`,
            payload: { instance: response.data },
          });
        } catch {}
      }
    );

    yield takeEvery(`${resourceName}/Set`, function* (action) {});

    yield takeEvery(
      `${resourceName}/GetAll`,
      function* (action: PayloadAction<{ page?: number; filters?: string }>) {
        try {
          const response: AxiosResponse<PaginatedResponse<any>> =
            yield axios.get(
              `${BASE_URL}/dashboard/${convertToKebabCase(resourceName)}${
                action.payload.page ? "?page=" + action.payload.page : ""
              }${action.payload.filters ? "&" + action.payload.filters : ""}`
            );
          yield put({
            type: `${resourceName}/SetAll`,
            payload: { paginatedResponse: response.data },
          });
          yield put({
            type: `${resourceName}/SetIsLoading`,
            payload: { isLoading: false },
          });
        } catch {
          // todo: handle error
        }
      }
    );

    yield takeEvery(
      `${resourceName}/SearchAll`,
      function* (
        action: PayloadAction<{
          query?: string;
          page?: number;
          filters?: string;
        }>
      ) {
        try {
          const response: AxiosResponse<PaginatedResponse<any>> =
            yield axios.get(
              `${BASE_URL}/dashboard/${convertToKebabCase(
                resourceName
              )}?search=${action.payload.query}${
                action.payload.page ? "&page=" + action.payload.page : ""
              }${action.payload.filters ? "&" + action.payload.filters : ""}`
            );
          yield put({
            type: `${resourceName}/SetAll`,
            payload: { paginatedResponse: response.data },
          });
        } catch {}
      }
    );

    yield takeEvery(`${resourceName}/SetAll`, function* (action) {});

    yield takeEvery(
      `${resourceName}/Delete`,
      function* (action: PayloadAction<{ id?: string }>) {
        try {
          yield axios.delete(
            `${BASE_URL}/dashboard/${convertToKebabCase(resourceName)}/${
              action.payload.id
            }`
          );
          yield put({
            type: `${resourceName}/GetAll`,
            payload: { page: 1 },
          });
          toast(
            `Successfully Deleted ${toHeaderCase(singularize(resource.name))}`,
            {
              icon: "♻️",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            }
          );
        } catch {}
      }
    );

    yield takeEvery(
      `${resourceName}/Create`,
      function* (
        action: PayloadAction<{ resource: any; resetForm: () => void }>
      ) {
        try {
          yield put({
            type: `${resourceName}/SetCreateError`,
            payload: { error: "" },
          });
          yield axios.post(
            `${BASE_URL}/dashboard/${convertToKebabCase(resourceName)}`,
            action.payload.resource
          );
          // Once we've succesfully created and the modal is closed, reset the form
          setTimeout(() => {
            action.payload.resetForm();
          }, 1500);
          yield put({
            type: `${resourceName}/GetAll`,
            payload: { page: 1 },
          });
          yield put({
            type: `${resourceName}/SetCreateSlideOverOpen`,
            payload: { createSlideOverOpen: false },
          });
          toast(
            `Successfully Created ${toHeaderCase(singularize(resource.name))}`,
            {
              icon: "✅",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            }
          );
          yield put({
            type: `${resourceName}/Set`,
            payload: { instance: null },
          });
        } catch (error: any) {
          if (error.response.data) {
            yield put({
              type: `${resourceName}/SetCreateError`,
              payload: { error: error.response.data.error },
            });
          }
        }
      }
    );

    yield takeEvery(
      `${resourceName}/Update`,
      function* (action: PayloadAction<{ id: number; resource: any }>) {
        try {
          yield axios.put(
            `${BASE_URL}/dashboard/${convertToKebabCase(resourceName)}/${
              action.payload.id
            }`,
            action.payload.resource
          );
          yield put({
            type: `${resourceName}/GetAll`,
            payload: { page: 1 },
          });
          yield put({
            type: `${resourceName}/SetCreateSlideOverOpen`,
            payload: { createSlideOverOpen: false },
          });
          toast(
            `Successfully Updated ${toHeaderCase(singularize(resource.name))}`,
            {
              icon: "✅",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            }
          );
        } catch {}
      }
    );
  }
}

export function* crudSaga() {
  yield fork(createCRUDSideEffects);
}
