import {
  ExclamationTriangleIcon,
  PlusIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { State } from "../store/store";
import { dashboardActions } from "../store/dashboard";
import { DashboardLayout } from "../components/dashboard/layouts/DashboardLayout";
import { SkeletonLoading } from "../components/SkeletonLoading";
import { Table } from "../components/Table";
import {
  Attendee,
  DashboardTicket,
  PaginatedResponse,
  PaginationBase,
} from "../models/Dashboard";
import { CRUDResource } from "./models";
import { deepEqual, singularize, useSelectorByStringPath } from "./utils";
import { Link, useLocation } from "react-router-dom";
import {
  LinkIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { toHeaderCase, toSentenceCase } from "js-convert-case";
import { DateTimePicker } from "./form/DateTimePicker";
import { Dropdown } from "./form/Dropdown";
import { Field, Formik } from "formik";
import { Checkbox } from "./form/Checkbox";
import { Input } from "./form/Input";
import { Dialog, Transition } from "@headlessui/react";
import { fi } from "date-fns/locale";

interface Props<T, R> {
  isUpdating: boolean;
  resource: CRUDResource<T, R>;
  createSlideOverOpen: boolean;
  setCreateSlideOverOpen: (createSlideOverOpen: boolean) => void;
}

export const Create = <T extends object, R extends ReactNode>({
  isUpdating,
  resource,
  createSlideOverOpen,
  setCreateSlideOverOpen,
}: Props<T, R>) => {
  const resourceName = resource.name.replaceAll(" ", "");
  const dispatch = useDispatch();
  const location = useLocation();
  const contentLoading = useSelector(
    (state: State) => state.dashboard.contentLoading
  );
  const [validationSchema, setValidationSchema] = useState<any>();
  const createError = useSelectorByStringPath(`${resourceName}.CreateError`);

  useEffect(() => {
    // Load the schema async as we need to let the client get any results it needs from the server
    resource.validationSchema().then((result: any) => {
      setValidationSchema(result);
    });
  }, [location.pathname]);

  const singleResource = useSelectorByStringPath(
    `${resourceName}.Single`
  ) as any;

  const handleSlideoverClose = () => {
    setCreateSlideOverOpen(false);

    if (isUpdating) {
      setTimeout(() => {
        // Set the single Resource to null
        // Set is Editing to false
        dispatch({
          type: `${resourceName}/SetIsEditing`,
          payload: { isEditing: false },
        });
        dispatch({
          type: `${resourceName}/Set`,
          payload: { instance: undefined },
        });
      }, 1000);
    }
  };

  const renderCustomField = (field: any) => {
    const Component = field.reactComponentMetadata.type;
    const props = field.reactComponentMetadata.props || {};
    return (
      <Component
        {...props}
        // value={formik.values[fieldName]}
        // onChange={(e) => formik.setFieldValue(fieldName, e.target.value)}
        // onBlur={formik.handleBlur(fieldName)}
      />
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={isUpdating && singleResource ? singleResource : {}}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          if (isUpdating && singleResource) {
            dispatch({
              type: `${resourceName}/Update`,
              payload: { id: singleResource.id, resource: values },
            });
          } else {
            dispatch({
              type: `${resourceName}/Create`,
              payload: { resource: values, resetForm },
            });
          }
        }}
      >
        {({ errors, handleSubmit, values, setFieldValue, initialValues }) => (
          <Transition.Root show={createSlideOverOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => handleSlideoverClose()}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl border-l dark:border-white/5">
                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl dark:bg-dark-primary border-l border-transparent dark:border-white/5">
                          <div className="flex-1">
                            {/* Header */}
                            <div className="bg-gray-50 px-4 py-6 sm:px-6 dark:bg-dark-secondary">
                              <div className="flex items-start justify-between space-x-3">
                                <div className="space-y-1">
                                  <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                    {isUpdating ? "Update" : "Create"}{" "}
                                    {toHeaderCase(singularize(resource.name))}
                                  </Dialog.Title>
                                  {/* <p className="text-sm text-gray-500 dark:text-gray-400">
                                    Get started by filling in the information
                                    below to create your new project.
                                  </p> */}
                                </div>
                                <div className="flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="relative text-gray-400 hover:text-gray-500"
                                    onClick={() => handleSlideoverClose()}
                                  >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* Create Component */}
                            <div className="h-auto">
                              {createError && (
                                <>
                                  {/* if there was an error from the API */}
                                  <div className="p-6 -mb-2">
                                    <div className="rounded-md bg-red-400/10 p-4 ring-red-400/20">
                                      <div className="flex">
                                        <div className="flex-shrink-0">
                                          <ExclamationTriangleIcon
                                            aria-hidden="true"
                                            className="h-5 w-5 text-red-500"
                                          />
                                        </div>
                                        <div className="ml-3">
                                          <h3 className="text-sm font-medium text-red-500">
                                            Error creating{" "}
                                            {singularize(resource.name)}
                                          </h3>
                                          <div className="mt-2 text-sm text-red-500">
                                            <p>{createError}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* Project name */}
                              {validationSchema ? (
                                <>
                                  <form className="flex-1 space-y-6 py-6 sm:space-y-0 sm:divide-y divide-gray-200 dark:divide-white/5 sm:py-0">
                                    {validationSchema?.fields && (
                                      <>
                                        {Object.keys(
                                          validationSchema.fields
                                        ).map((key) => {
                                          const field =
                                            validationSchema.fields[key];

                                          let displayField = true;
                                          const fieldMetadata =
                                            field.describe().meta;

                                          // Check if the field should be shown based on metadata
                                          if (
                                            fieldMetadata &&
                                            "displayIf" in fieldMetadata
                                          ) {
                                            // If it's an array of displayIfs
                                            if (
                                              Array.isArray(
                                                fieldMetadata["displayIf"]
                                              )
                                            ) {
                                              // For each one in the array, check if what we say the value must be equal,
                                              // is equal to the actual value in the form
                                              // do it for all
                                              fieldMetadata[
                                                "displayIf"
                                              ].forEach((displayIf) => {
                                                if (
                                                  !deepEqual(
                                                    values[displayIf["field"]],
                                                    displayIf["value"]
                                                  )
                                                ) {
                                                  displayField = false;
                                                }
                                              });
                                            } else {
                                              // if it's not an array, it's just a single object, just check the one
                                              if (
                                                !deepEqual(
                                                  values[
                                                    fieldMetadata["displayIf"][
                                                      "field"
                                                    ]
                                                  ],
                                                  fieldMetadata["displayIf"][
                                                    "value"
                                                  ]
                                                )
                                              ) {
                                                displayField = false;
                                              }
                                            }
                                          }

                                          // Check if it's a custom field and check props to hide if update
                                          if (
                                            field.reactComponentMetadata &&
                                            field.reactComponentMetadata.props
                                              .hideOnUpdate &&
                                            isUpdating
                                          ) {
                                            if (isUpdating) {
                                              displayField = false;
                                            }
                                          }

                                          if (displayField) {
                                            return (
                                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                <div>
                                                  <label
                                                    htmlFor="project-name"
                                                    className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5 dark:text-white"
                                                  >
                                                    {toHeaderCase(key)}
                                                  </label>
                                                </div>
                                                <div className="sm:col-span-2">
                                                  {/* If it has displayIf metadata */}
                                                  {/* If it's a string */}
                                                  {field.type === "string" && (
                                                    <Input
                                                      type="text"
                                                      name={key}
                                                      required={
                                                        field.exclusiveTests
                                                          .required
                                                      }
                                                    />
                                                  )}
                                                  {/* If it's a datetime */}
                                                  {field.type === "date" && (
                                                    <div className="relative">
                                                      <DateTimePicker
                                                        name={key}
                                                        hasTime={true}
                                                      />
                                                    </div>
                                                  )}
                                                  {/* If it's a single select */}
                                                  {field.type === "mixed" && (
                                                    <>
                                                      <>
                                                        {field.reactComponentMetadata ? (
                                                          renderCustomField(
                                                            field
                                                          )
                                                        ) : (
                                                          <>
                                                            {Array.from(
                                                              field._whitelist
                                                            ).length > 0 ? (
                                                              <Dropdown
                                                                multiple={
                                                                  // new way
                                                                  field.exclusiveTests &&
                                                                  typeof field.exclusiveTests ===
                                                                    "object" &&
                                                                  Object.prototype.hasOwnProperty.call(
                                                                    field.exclusiveTests,
                                                                    "manyOfByValue"
                                                                  )
                                                                  // old way before I put a min tet in the yup schema
                                                                  // field._isMany
                                                                }
                                                                name={key}
                                                                options={Array.from(
                                                                  field._whitelist
                                                                )}
                                                                isUpdating={
                                                                  !!singleResource
                                                                }
                                                              />
                                                            ) : (
                                                              <span className="text-white">
                                                                No options
                                                                available yet
                                                              </span>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    </>
                                                  )}
                                                  {/* If it's a boolean field, yes or no, i.e. checkbox*/}
                                                  {field.type === "boolean" && (
                                                    <Checkbox name={key} />
                                                  )}
                                                  {/* If it's a number */}
                                                  {field.type === "number" && (
                                                    <>
                                                      <Input
                                                        type="number"
                                                        name={key}
                                                        required={
                                                          field.exclusiveTests
                                                            .required
                                                        }
                                                        isMoney={
                                                          fieldMetadata
                                                            ? fieldMetadata.isMoney
                                                            : false
                                                        }
                                                        trailingAddon={
                                                          field.spec.meta
                                                            ? field.spec.meta
                                                                .trailingAddon
                                                            : null
                                                        }
                                                      />
                                                    </>
                                                  )}

                                                  {field.spec.meta &&
                                                    field.spec.meta
                                                      .helpText && (
                                                      <p className="mt-2 text-sm leading-6 text-gray-400">
                                                        {
                                                          field.spec.meta
                                                            .helpText
                                                        }
                                                      </p>
                                                    )}
                                                </div>
                                              </div>
                                            );
                                          } else {
                                            <></>;
                                          }
                                        })}
                                      </>
                                    )}
                                  </form>
                                </>
                              ) : (
                                <div className="flex items-center w-full justify-center mt-64">
                                  <svg
                                    className="animate-spin -ml-1 mr-3 h-10 w-10 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6 dark:border-white/5 dark:bg-dark-secondary">
                            <div className="flex justify-end space-x-3">
                              <button
                                type="button"
                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={() => setCreateSlideOverOpen(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => handleSubmit()}
                              >
                                {isUpdating
                                  ? "Update"
                                  : resource.createButtonTitle
                                  ? resource.createButtonTitle
                                  : "Create"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </Formik>
    </>
  );
};
