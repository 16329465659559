import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { cartActions } from "../../store/cart";
import { State } from "../../store/store";

export const Nav = () => {
  const dispatch = useDispatch();
  const event = useSelector((state: State) => state.cart.event);

  return (
    <div className="mt-4 w-full absolute top-0 left-0 z-10 flex justify-center pt-6 lg:pt-0">
      {event && (
        <nav className="pointer-events-auto md:block">
          <ul className="flex rounded-lg bg-black text-white px-3 text-sm font-medium shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur">
            <div className="flex items-center rounded-full ml-0.5">
              <img className="h-6 rounded-full" src="../icon.jpeg" />
            </div>
            <li>
              <Link
                className="relative block px-3 py-2 transition hover:text-blue-600"
                to={`/${event.slug}`}
              >
                Tickets
              </Link>
            </li>
            {/* <li>
            <Link
              className="relative block px-3 py-2 transition hover:text-blue-600"
              to="/merchandise"
            >
              Merchandise
            </Link>
          </li> */}
            <li>
              <button
                onClick={() => {
                  dispatch(
                    cartActions.SetManageOrderModalIsOpen({
                      manageOrderModalIsOpen: true,
                    })
                  );
                }}
                className="relative block px-3 py-2 transition hover:text-blue-600"
              >
                Manage My Order
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};
