import { useEffect, useState } from "react";
import { ACCESS_TOKEN_KEY, signOut } from "../pages/protected-route";
import { decodeToken } from "react-jwt";

interface OrganisationPermissions {
  [key: string]: {
    organisation_name: string;
    permissions: string[];
    is_organisation_admin: boolean;
  };
}

interface DecodedToken {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  email: string;
  organisation_permissions: OrganisationPermissions;
  is_superuser: boolean;
}

export const useUserPermissions = (): [OrganisationPermissions, boolean] => {
  const [permissions, setPermissions] = useState<OrganisationPermissions>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPermissions = () => {
      // Get the access token
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

      // If the token isn't there, logout
      if (!accessToken) {
        signOut();
        setLoading(false);
        return;
      }

      // Decode the token
      const decodedToken = decodeToken<DecodedToken>(accessToken);

      // If the token isn't decoded properly, logout
      if (!decodedToken) {
        signOut();
        setLoading(false);
        return;
      }

      // Get organisation_permissions from the token
      const { organisation_permissions, is_superuser } = decodedToken;

      if (organisation_permissions === undefined) {
        signOut();
      }

      // Add "superuser" to each organisation's permissions if is_superuser is true
      if (is_superuser) {
        for (const orgId in organisation_permissions) {
          organisation_permissions[orgId].permissions.push("superuser");
        }
      }

      setPermissions(organisation_permissions);
      setLoading(false);
    };

    fetchPermissions();
  }, []);

  return [permissions, loading];
};
