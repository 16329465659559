import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { ChangeEvent, Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { cartActions } from "../../../store/cart";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { createOrderEmailLink } from "../../../queries";
import Lottie from "lottie-react";
import successAnimation from "../../../assets/successAnimation.json";

export const ManageOrderModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((state: State) => state.cart.manageOrderModalIsOpen);
  const [linkSent, setLinkSent] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const setOpen = (open: boolean) => {
    dispatch(
      cartActions.SetManageOrderModalIsOpen({
        manageOrderModalIsOpen: open,
      })
    );
  };

  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {linkSent ? (
                  <div>
                    <div className="flex justify-center">
                      <div className="w-20 h-20">
                        <Lottie animationData={successAnimation} loop={false} />
                      </div>
                    </div>
                    <div className="mt-3 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Order Link Sent
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          If an order with that number exists, we will send you
                          a link to manage your order. If you didn't receive the
                          link, please check your junk.
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="mt-5 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                      onClick={() => {
                        setOpen(false);
                        setTimeout(() => setLinkSent(false), 650);
                      }}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <Formik
                    initialValues={{
                      orderNumber: "",
                    }}
                    validationSchema={Yup.object({
                      orderNumber: Yup.string().required("Required"),
                    })}
                    onSubmit={(values, actions) => {
                      setIsProcessing(true);
                      createOrderEmailLink(values.orderNumber).finally(() => {
                        setLinkSent(true);
                        setIsProcessing(false);
                      });
                    }}
                    enableReinitialize
                  >
                    {({
                      errors,
                      handleSubmit,
                      values,
                      setFieldValue,
                      initialValues,
                    }) => (
                      <form>
                        <div>
                          <div className="text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              Manage My Order
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                When you bought your ticket, you should have
                                received an email with it and a link to manage
                                your order. If you don't have it, enter your
                                Order Number below and we will send you a new
                                link.
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 sm:text-sm">
                                    #
                                  </span>
                                </div>
                                <Field
                                  onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      "orderNumber",
                                      e.target.value.replace("#", "")
                                    );
                                  }}
                                  type="text"
                                  name="orderNumber"
                                  id="orderNumber"
                                  className={`block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
                                    errors.orderNumber
                                      ? "ring-red-500 focus:ring-red-600"
                                      : "ring-gray-300 focus:ring-blue-600"
                                  }`}
                                  placeholder="Order Number"
                                />
                              </div>
                              {errors.orderNumber && (
                                <p
                                  className="mt-3 text-sm text-red-500 flex"
                                  id="lastNameError"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 mr-1"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                                    />
                                  </svg>
                                  {errors.orderNumber}
                                </p>
                              )}
                            </div>
                            <div className="w-full mt-3">
                              <div className="mx-auto w-full rounded-2xl bg-white">
                                <Disclosure>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
                                        <span>
                                          I can't find my Order Number
                                        </span>
                                        <ChevronUpIcon
                                          className={`${
                                            open ? "rotate-180 transform" : ""
                                          } h-5 w-5 text-gray-500`}
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                        Your order number can be found on the
                                        top right of your ticket.
                                        <img
                                          className="mx-auto mt-2"
                                          src="order-number.png"
                                        />
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                            onClick={() => handleSubmit()}
                          >
                            {isProcessing ? (
                              <>
                                Processing
                                <svg
                                  className="animate-spin h-5 w-5 text-blue-200 ml-3"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </>
                            ) : (
                              <>Send me a link</>
                            )}
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
