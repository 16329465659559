import { useDarkMode } from "usehooks-ts";

interface Props {
  children: any;
}

export const PreDashboardLayout = ({ children }: Props) => {
  const darkMode = useDarkMode();
  return (
    <div className="min-h-full dark:bg-dark-primary bg-light-primary">
      {children}
    </div>
  );
};
