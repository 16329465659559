import {
  Field,
  FieldArray,
  FormikErrors,
  useField,
  useFormikContext,
} from "formik";
import {
  TicketAnswer,
  TicketQuestion as TicketQuestionModel,
} from "../models/Tickets";
import { Order, OrderResponse } from "../models/Order";
import { BillingFormValues } from "../forms/BillingDetailsForm";
import { TicketQuestion } from "./TicketQuestion";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

interface Props {
  order?: OrderResponse;
  questions: Array<TicketQuestionModel>;
}

export const TicketQuestions = ({ order, questions }: Props) => {
  const { errors, touched, setFieldValue, setFieldError } =
    useFormikContext<BillingFormValues>(); // Specify your Formik context type

  const isFormikErrorsArray = (
    errors: any
  ): errors is FormikErrors<TicketAnswer>[] => {
    return Array.isArray(errors);
  };

  return (
    <div className="mt-5">
      <FieldArray name="questions">
        {({ insert, remove, push }) => (
          <div>
            {questions.length > 0 &&
              questions.map((question, index) => (
                <div className="row" key={index}>
                  <Field
                    name={`questions.${index}.uuid`}
                    type="hidden"
                    value={question.uuid}
                  />
                  <div className="mt-2">
                    <label
                      htmlFor={question.uuid}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {question.question}
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <Field
                        type="text"
                        disabled={order}
                        name={`questions.${index}.answer`}
                        id={question.uuid}
                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 ${
                          errors.questions &&
                          isFormikErrorsArray(errors.questions)
                            ? "text-red-900 ring-1 ring-inset ring-red-300"
                            : ""
                        }`}
                      />
                      {errors.questions &&
                        isFormikErrorsArray(errors.questions) && (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                    </div>

                    {errors.questions &&
                      isFormikErrorsArray(errors.questions) && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id={`${question.uuid}Error`}
                        >
                          {errors.questions[index]?.answer}
                        </p>
                      )}
                  </div>
                </div>
              ))}
          </div>
        )}
      </FieldArray>
    </div>
  );
};
