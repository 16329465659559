import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart";
import { AddonCartItem } from "../../models/Cart";
import { Addon } from "../../models/Tickets";
import { State } from "../../store/store";

interface Props {
  addon: Addon;
}

export const AddonQuantity = ({ addon }: Props) => {
  const dispatch = useDispatch();
  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const [quantitySelected, setQuantitySelected] = useState(0);

  useEffect(() => {
    if (addonCart) {
      const addonCartItem = addonCart.filter(
        (addonCartItem: AddonCartItem) => addonCartItem.uuid === addon.uuid
      );
      if (addonCartItem.length > 0) {
        setQuantitySelected(addonCartItem[0].quantity);
      } else {
        setQuantitySelected(0);
      }
    }
  }, [addonCart]);

  const getLineTotal = (quantity: number) => {
    // if (ticket.ticketType === TicketType.PAYMENT_PLAN && ticket.perMonthPrice) {
    //   return parseFloat(ticket.perMonthPrice) * quantity;
    // }
    return parseFloat(addon.price) * quantity;
  };

  const addToCart = (quantity: number) => {
    dispatch(cartActions.ClearCartError());
    const newAddon: AddonCartItem = {
      ...addon,
      quantity: quantity,
      lineTotal: getLineTotal(1),
    };
    // If we have no addons in the cart, just set it
    if (addonCart.length === 0 && quantity > 0) {
      dispatch(
        cartActions.SetAddonCart({
          addons: [newAddon],
        })
      );
    } else {
      // Loop through the cart looking for an entry for that addon
      const matchingItemIndex = addonCart.findIndex(
        (addonCartItem: AddonCartItem) => addonCartItem.uuid == addon.uuid
      );

      // If it's already in the cart
      if (matchingItemIndex > -1) {
        const allOtherItems = addonCart.filter(
          (addonCartItem: AddonCartItem) => addonCartItem.uuid != addon.uuid
        );
        // Check if the current quantity minus the desired will result in negative, if remove it
        if (addonCart[matchingItemIndex].quantity + quantity < 1) {
          dispatch(
            cartActions.SetAddonCart({
              addons: allOtherItems,
            })
          );
        } else {
          // If the result isn't negative, then add the quantity and calculate a new lineTotal
          const newQuantity = addonCart[matchingItemIndex].quantity + quantity;
          dispatch(
            cartActions.SetAddonCart({
              addons: [
                ...allOtherItems,
                {
                  ...newAddon,
                  quantity: addonCart[matchingItemIndex].quantity + quantity,
                  lineTotal: getLineTotal(newQuantity),
                },
              ],
            })
          );
        }
      } else {
        // Not in the cart, add it
        if (quantity > 0) {
          dispatch(
            cartActions.SetAddonCart({
              addons: [...addonCart, newAddon],
            })
          );
        }
      }
    }
  };

  return (
    <div className="flex mt-">
      <div>
        <button
          type="button"
          disabled={quantitySelected == 0}
          onClick={() => {
            addToCart(-1);
          }}
          className={`rounded py-1 px-2 text-sm font-semiboldshadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2${
            quantitySelected > 0
              ? "focus-visible:outline-blue-600 bg-blue-600 hover:bg-blue-500"
              : "focus-visible:outline-gray-600 bg-gray-200 hover:bg-gray-100"
          } `}
        >
          <MinusIcon
            className={`h-6 w-6 ${
              quantitySelected > 0 ? "text-white" : "text-gray-500"
            }`}
          />
        </button>
      </div>
      <div className="mx-4 flex items-center">
        <h3 className="text-lg">{quantitySelected}</h3>
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            if (quantitySelected < 10) {
              addToCart(1);
            }
          }}
          // disabled={quantitySelected == ticket.ticketOptionsRange}
          className={`rounded py-1 px-2 text-sm font-semiboldshadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2${
            quantitySelected < 10
              ? "focus-visible:outline-blue-600 bg-blue-600 hover:bg-blue-500"
              : "focus-visible:outline-gray-600 bg-gray-200 hover:bg-gray-100"
          } `}
        >
          <PlusIcon
            className={`h-6 w-6  ${
              quantitySelected < 10 ? "text-white" : "text-gray-500"
            }`}
          />
        </button>
      </div>
    </div>
  );
};
