import { Formik } from "formik";
import { Link, useLocation } from "react-router-dom";
import yup from "../crud/yup-extended";
import { Input } from "../crud/form/Input";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../store/dashboard";
import { SignInRequest } from "../models/Dashboard";
import { useEffect } from "react";
import { State } from "../store/store";
import { useDarkMode } from "usehooks-ts";
import { getFeatureFlag } from "../utils/feature-flags";

export const SignIn = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isSigningIn = useSelector(
    (state: State) => state.dashboard.isSigningIn
  );
  const darkMode = useDarkMode();
  const showSignInVideo = getFeatureFlag("SHOW_SIGN_IN_VIDEO");
  const queryParams = new URLSearchParams(location.search);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full flex-1 dark:bg-dark-primary justify-center">
        <div className="flex flex-1 flex-col justify-center lg:px-14 xl:px-20 max-w-lg lg:max-w-2xl">
          <div className="w-full">
            <div className="w-full h-screen flex-1 items-center flex border-x dark:border-gray-900 border-dashed border-gray-200">
              <div className="w-full rounded-xl shadow-md dark:bg-dark-secondary ring-1 ring-black/5">
                <form className="p-7 sm:p-11" action="#" method="POST">
                  <div className="flex items-start">
                    {darkMode.isDarkMode ? (
                      <svg
                        id="Livello_2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 648.3 150.34"
                        className="ml-1"
                        height="25"
                      >
                        <g id="Livello_1-2">
                          <rect
                            className="fill-white"
                            x="111.99"
                            y="38.89"
                            width="33.07"
                            height="109.44"
                          />
                          <path
                            className="fill-white"
                            d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                          />
                          <polygon
                            className="fill-white"
                            points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                          />
                          <path
                            className="fill-white"
                            d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                          />
                          <path
                            className="fill-white"
                            d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                          />
                          <path
                            className="fill-white"
                            d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                          />
                          <polygon
                            className="fill-white"
                            points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                          />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        id="Livello_2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 648.3 150.34"
                        className="ml-1"
                        height="25"
                      >
                        <g id="Livello_1-2">
                          <rect
                            className="fill-indigo-500"
                            x="111.99"
                            y="38.89"
                            width="33.07"
                            height="109.44"
                          />
                          <path
                            className="fill-indigo-500"
                            d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                          />
                          <polygon
                            className="fill-indigo-500"
                            points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                          />
                          <path
                            className="fill-indigo-500"
                            d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                          />
                          <path
                            className="fill-indigo-500"
                            d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                          />
                          <path
                            className="fill-indigo-500"
                            d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                          />
                          <polygon
                            className="fill-indigo-500"
                            points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                          />
                        </g>
                      </svg>
                    )}
                  </div>

                  <div className="mb-5">
                    <h1 className="mt-8 text-base/6 font-medium dark:text-white text-gray-900">
                      Welcome back!
                    </h1>
                    <p className="mt-1 text-sm/5 text-gray-400">
                      Sign in to your account to continue.
                    </p>
                  </div>

                  <Formik
                    initialValues={{}}
                    validationSchema={yup.object({
                      email: yup
                        .string()
                        .email("Please enter a valid email")
                        .required("Required"),
                      password: yup.string().required("Required"),
                    })}
                    onSubmit={(values, actions) => {
                      const formValues = values as any;
                      dispatch(
                        dashboardActions.SignIn({
                          signInRequest: {
                            username: formValues.email,
                            password: formValues.password,
                          },
                        })
                      );
                    }}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    validateOnMount={false}
                  >
                    {({ errors, handleSubmit }) => (
                      <form
                        className="space-y-6"
                        onSubmit={() => handleSubmit()}
                      >
                        <div className="-mb-2">
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900 dark:text-white -mb-4"
                          >
                            Email
                          </label>
                          <div className="mt-6">
                            <Input type="text" name="email" required={true} />
                          </div>
                        </div>

                        <div className="">
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                          >
                            Password
                          </label>
                          <div className="mt-2">
                            <Input
                              type="password"
                              name="password"
                              required={true}
                            />
                          </div>
                        </div>

                        {/* <div className="flex items-center justify-between">
                        <div className="text-sm leading-6">
                          <a
                            href="#"
                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                          >
                            Forgot password?
                          </a>
                        </div>
                      </div> */}

                        <div>
                          <button
                            type="button"
                            onClick={() => handleSubmit()}
                            disabled={isSigningIn}
                            className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            {isSigningIn ? (
                              <>
                                Signing in
                                <div className="ml-3">
                                  <svg
                                    className="animate-spin -ml-1 mr-4 h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </div>
                              </>
                            ) : (
                              <>Sign in</>
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <div className="h-full w-full">
            {showSignInVideo && (
              <video
                src="long-video.mp4"
                autoPlay={true}
                muted={true}
                loop={true}
                playsInline={true}
                className="absolute right-0 bottom-0 bg-cover min-w-full min-h-full object-cover"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
