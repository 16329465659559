import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../store/cart";
import { PortalLayout } from "../components/portal/layouts/PortalLayout";
import { State } from "../store/store";
import { Loading } from "../components/portal/Loading";
import { Event } from "../models/Cart";
import { format } from "date-fns";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

const people = [
  {
    name: "Lindsay Walton",
    role: "Front-end Developer",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    xUrl: "#",
    linkedinUrl: "#",
  },
  // More people...
];

export const Organisation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organisation = useSelector((state: State) => state.cart.organisation);
  useEffect(() => {
    dispatch(cartActions.GetOrganisation());
  }, []);
  return (
    <PortalLayout>
      {organisation ? (
        <div className="h-screen overflow-y-scroll pb-20">
          <div className="bg-white py-8 lg:py-24 sm:py-30 lg:max-w-5xl lg:mx-auto">
            <div className="mx-auto px-6 lg:px-8">
              <div className="mx-auto lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {organisation.name}
                </h2>
                {organisation.description && (
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    {organisation.description}
                  </p>
                )}
              </div>
              <div className="mx-auto lg:mx-0">
                <h2 className="font-bold tracking-tight text-gray-900 text-2xl mt-6 lg:mt-20">
                  Events
                </h2>
              </div>
              {organisation.events && (
                <ul role="list" className="divide-y divide-gray-100">
                  {organisation.events.map((event: Event) => (
                    <li
                      key={event.uuid}
                      className="flex justify-between gap-x-6 py-5 items-center"
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <div className="mr-2 text-center mt-1.5">
                          <div
                            className="text-xs text-orange-500"
                            style={{ fontSize: 18 }}
                          >
                            {format(
                              new Date(event.startDate),
                              "MMM"
                            ).toUpperCase()}
                          </div>
                          <div
                            className="font-semibold -mt-1"
                            style={{ fontSize: 30 }}
                          >
                            {format(
                              new Date(event.startDate),
                              "dd"
                            ).toUpperCase()}
                          </div>
                        </div>
                        <div className="min-w-0 items-center max-w-1/2">
                          <div>
                            <p className="text-md lg:text-lg font-semibold leading-6 text-gray-900 break-all">
                              {event.name}
                            </p>
                            <p className="mt-1 truncate text-xs leading-snug text-gray-500">
                              {format(
                                new Date(event.startDate),
                                "EEEE 'at' h:mm"
                              )}
                            </p>
                            <p className="mt-1 text-sm leading-4 text-gray-500">
                              {event.location}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                        <button
                          onClick={() => {
                            window.location.href = `https://${organisation.domain}/${event.slug}`;
                          }}
                          type="submit"
                          className="lg:mt-6 w-full rounded-full sm:rounded-md border border-transparent bg-blue-600 py-2 px-2 sm:px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        >
                          <div className="flex items-center justify-center">
                            <span className="hidden sm:block">
                              Find Tickets
                            </span>
                            <ChevronRightIcon className="sm:ml-1.5 h-6 w-6 text-white" />
                          </div>
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
                // <ul
                //   role="list"
                //   className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 mt-8"
                // >
                //   {organisation.events.map((event: Event) => (
                //     <li key={event.name}>
                //       <img
                //         className="aspect-[3/2] w-full rounded-2xl object-cover"
                //         src={event.logo}
                //         alt=""
                //       />
                //       <div className="px-2">
                //         <div className="flex mt-4">
                //           <div className="mr-2 text-center mt-2">
                //             <div
                //               className="text-xs text-orange-500"
                //               style={{ fontSize: 18 }}
                //             >
                //               {format(
                //                 new Date(event.startDate),
                //                 "MMM"
                //               ).toUpperCase()}
                //             </div>
                //             <div
                //               className="font-semibold -mt-1"
                //               style={{ fontSize: 30 }}
                //             >
                //               {format(
                //                 new Date(event.startDate),
                //                 "dd"
                //               ).toUpperCase()}
                //             </div>
                //           </div>
                //           <div className="ml-2">
                //             <p className="text-base leading-7 text-gray-600 text-sm">
                //               {format(
                //                 new Date(event.startDate),
                //                 "EEEE 'at' h:mm"
                //               )}
                //             </p>
                //             <h3 className="-mt-0.5 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                //               {event.name}
                //             </h3>
                //             <p className="text-base leading-7 text-gray-600 text-sm leading-snug">
                //               {event.location}
                //             </p>
                //           </div>
                //         </div>
                //         <button
                //           onClick={() => navigate(event.slug)}
                //           type="submit"
                //           className="mt-5 w-full rounded-md border border-transparent bg-blue-600 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                //         >
                //           <div className="flex items-center justify-center">
                //             Find Tickets
                //             <ChevronRightIcon className="ml-1.5 h-6 w-6 text-white" />
                //           </div>
                //         </button>
                //       </div>
                //     </li>
                //   ))}
                // </ul>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <Loading />
        </div>
      )}
    </PortalLayout>
  );
};
