import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { TicketQuantity } from "./TicketQuantity";
import { Ticket as TicketModel, TicketType } from "../models/Tickets";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../App";
import { CartContextType, CartItem, PromoCodeType } from "../models/Cart";
import { useSelector } from "react-redux";
import { State } from "../store/store";
import { calculateDiscount } from "../utils/cart";
import { format, parse, parseISO } from "date-fns";

interface Props {
  ticket: TicketModel;
}

export const Ticket = ({ ticket }: Props) => {
  const cart = useSelector((state: State) => state.cart.cart);
  const [isInCart, setIsInCart] = useState(false);
  const appliedPromoCode = useSelector(
    (state: State) => state.cart.appliedPromoCode
  );
  const [showFullDescription, setShowFullDescription] = useState(false);
  // Toggle function to show/hide the full description
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    if (cart) {
      const cartItem = cart.findIndex(
        (cartItem: CartItem) => ticket.uuid === cartItem.ticketUuid
      );
      setIsInCart(cartItem > -1);
    }
  }, [cart]);

  // Function to render the description
  const renderDescription = () => {
    if (ticket.description) {
      if (ticket.description.length > 50) {
        return (
          <div className="text-sm w-2/3">
            {showFullDescription
              ? `${ticket.description} `
              : `${ticket.description.substring(0, 65)}... `}
            <button
              onClick={toggleDescription}
              className="text-blue-500 hover:text-blue-700"
            >
              {showFullDescription ? "less info" : "more info"}
            </button>
          </div>
        );
      }
      return <div className="text-sm w-2/3">{ticket.description}</div>;
    }
  };

  return (
    <li
      key={ticket.uuid}
      className={`p-4 sm:p-6 border-2 mb-6 rounded-md ${
        isInCart ? "border-blue-600" : "border-gray-100"
      }`}
    >
      <div className="flex items-center sm:items-start">
        <div className="flex-1 text-sm">
          <div className="font-medium text-gray-900 flex justify-between">
            <h5 className="text-lg">{ticket.name}</h5>
            <p className="mt-0">
              {isInCart && (
                <>
                  <CheckCircleIcon className="text-blue-600 bg-white h-7 w-7" />
                </>
              )}
            </p>
          </div>
          {ticket.onSaleTo && (
            <p className="text-gray-500 sm:block">
              Sale ends on&nbsp;
              {format(parseISO(ticket.onSaleTo), "dd LLL yyyy")}
            </p>
          )}
        </div>
      </div>
      <div className="mt-0 xs:flex mt-2 xs:mt-0">
        <div className="flex items-center flex-1">
          <p className="flex items-baseline gap-x-1">
            <span className="text-lg font-semibold tracking-tight text-gray-900">
              {appliedPromoCode?.codeType === PromoCodeType.DISCOUNT &&
              appliedPromoCode.ticket.uuid === ticket.uuid ? (
                <>
                  <s>&euro;{ticket.price}</s>
                  <>
                    &nbsp; &euro;
                    {(
                      parseFloat(ticket.price) -
                      calculateDiscount(appliedPromoCode)
                    ).toFixed(2)}
                  </>
                  <span className="ml-2 text-sm font-semibold leading-6 text-gray-600">
                    + &euro;{ticket.fee} fee
                  </span>
                </>
              ) : (
                <>
                  {ticket.ticketType == TicketType.PAYMENT_PLAN ? (
                    <div className="">
                      {ticket.numberOfMonths} monthly payments of &euro;
                      {ticket.perMonthPrice}
                      <div className="text-sm font-semibold leading-6 text-gray-600">
                        + &euro;{ticket.fee} fee
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div>&euro;{ticket.price}</div>
                      {ticket.fee != "0.00" && (
                        <div className="ml-2 text-sm font-semibold leading-6 text-gray-600">
                          + &euro;{ticket.fee} fee
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </span>
          </p>
        </div>

        <div className="text-sm font-medium sm:border-none mt-2 xs:mt-0">
          {ticket.isSoldOut ? (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 text-center">
              Sold Out
            </span>
          ) : (
            <>
              {ticket.isTicketOnSale ? (
                <TicketQuantity ticket={ticket} />
              ) : (
                <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 text-center">
                  Not on sale
                </span>
              )}
            </>
          )}
        </div>
      </div>

      {renderDescription()}
    </li>
  );
};
