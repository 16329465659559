import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "../../utils/styles";
import { DropdownOption } from "../../models/Dashboard";
import { getIn, useField, useFormikContext } from "formik";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface Props {
  name: string;
  options: Array<DropdownOption>;
  multiple?: boolean;
  isUpdating?: boolean;
}

export const Dropdown = ({ name, options, multiple, isUpdating }: Props) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const [field] = useField(name);
  const [selected, setSelected] = useState<DropdownOption | DropdownOption[]>(
    multiple ? [] : options[0]
  );
  const [haveSetValues, setHaveSetValues] = useState(false);
  const [error, setError] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!selected) {
      setHaveSetValues(false);
    }
  }, [options]);

  useEffect(() => {
    setError(getIn(errors, name) || "");
  }, [errors]);

  useEffect(() => {
    if (isUpdating) {
      if (!haveSetValues) {
        if (multiple && field.value && field.value.length > 0) {
          const foundOptions = options.filter((option) => {
            if (Array.isArray(field.value) && field.value.length > 0) {
              if (typeof field.value[0] === "number") {
                return field.value.includes(option.id);
              } else if (
                typeof field.value[0] === "object" &&
                "id" in field.value[0]
              ) {
                return field.value.some((item) => item.id === option.id);
              }
            }
            return false;
          });
          if (foundOptions) {
            setSelected(foundOptions);
            setHaveSetValues(true);
          }
        } else {
          const option = options.find((opt) => opt.id === field.value);
          if (option) {
            setSelected(option);
            setHaveSetValues(true);
          }
        }
      }
    }
  }, [field.value, options]);

  useEffect(() => {
    setFieldValue(name, selected);
  }, [selected, name, setFieldValue, field.value]);

  const handleSelectionChange = (option: DropdownOption) => {
    if (multiple) {
      setSelected((currentSelected) => {
        const arraySelected = currentSelected as DropdownOption[];
        const selectedIndex = arraySelected.findIndex(
          (opt) => opt.id === option.id
        );
        if (selectedIndex > -1) {
          return arraySelected.filter((opt) => opt.id !== option.id);
        } else {
          return [...arraySelected, option];
        }
      });
    } else {
      setSelected(option);
    }
  };

  const getDisplayValue = () => {
    if (multiple) {
      const name = (selected as DropdownOption[])
        .map((opt) => opt.value)
        .join(", ");
      return name ? name : "Nothing selected";
    } else {
      return (selected as DropdownOption).value;
    }
  };

  const filteredOptions = options.filter((option) =>
    option.value.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="z-50">
      <Listbox
        value={
          multiple
            ? (selected as DropdownOption[]).map((opt) => opt.id)
            : (selected as DropdownOption).id
        }
        onChange={(ids: any) => {
          if (multiple) {
            const selectedOptions = options.filter((opt) =>
              ids.includes(opt.id)
            );
            setSelected(selectedOptions);
          } else {
            const option = options.find((opt) => opt.id === ids);
            if (option) {
              handleSelectionChange(option);
            }
          }
        }}
        multiple={multiple}
      >
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button
                className={`w-full relative cursor-default rounded-md bg-white dark:bg-dark-secondary dark:text-gray-400 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                  error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300 "
                }`}
              >
                <span className="block truncate">{getDisplayValue()}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute z-50 mt-1 w-full rounded-md bg-white dark:bg-dark-secondary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <Listbox.Options className="max-h-60 overflow-auto py-1">
                    <div className="px-2 pt-1">
                      <div className="relative rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 mr-2">
                          <MagnifyingGlassIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                          />
                        </div>
                        <input
                          id="search"
                          name="search"
                          type="search"
                          placeholder="Search..."
                          className="bg-red-500 pl-[2.2rem] dark:ring-white/5 ring-gray-300 block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-6"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onClick={(e) => e.stopPropagation()} // Prevent closing the Listbox
                        />
                      </div>
                    </div>
                    {filteredOptions.length > 0 ? (
                      filteredOptions.map((option: DropdownOption) => (
                        <Listbox.Option
                          key={option.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-indigo-600 text-white "
                                : "text-gray-900 dark:text-gray-400",
                              "relative cursor-pointer select-none py-2 pl-3 pr-9 "
                            )
                          }
                          value={option.id}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {option.value}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))
                    ) : (
                      <div className="px-4 py-2 text-gray-500">
                        No options found
                      </div>
                    )}
                  </Listbox.Options>
                </div>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error && (
        <div className="mt-1">
          <span className="text-red-500 text-xs">{error}</span>
        </div>
      )}
    </div>
  );
};
