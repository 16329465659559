import { useEffect, useState } from "react";

export const PermissionDenied = () => {
  const PERMISSION_DENIED_MESSAGES = [
    "Sorry, not on the guest list!",
    "Looks like your backstage pass got lost!",
    "Oops, wrong wristband for this party!",
    "You didn't make the cut, try again!",
    "VIPs only beyond this point!",
    "No ticket, no entry—try the box office!",
    "Your name’s not on the list, but you can always refresh!",
    "Access denied! Looks like you missed the presale!",
    "Event sold out… and so is this page!",
    "No ticket? No entry. This is an exclusive gig!",
  ];

  const [randomMessage, setRandomMessage] = useState("");

  useEffect(() => {
    const message =
      PERMISSION_DENIED_MESSAGES[
        Math.floor(Math.random() * PERMISSION_DENIED_MESSAGES.length)
      ];
    setRandomMessage(message);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <div className="text-center -mt-52">
        <h1 className="text-5xl">🖐</h1>
        <h3 className="mt-4 font-bold text-gray-900 text-4xl dark:text-white">
          {randomMessage}
        </h3>
        <p className="mt-4 text-gray-400 mx-[20rem]">
          You don't have access to view this page, sorry about that. If you
          believe this is an error, reach out to the person who invited you.
        </p>
      </div>
    </div>
  );
};
