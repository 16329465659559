import { Fragment } from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../utils/styles";
import { Event } from "../../models/Cart";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "../../store/store";
import { CartStep } from "../../constants";

interface Props {
  event: Event;
}
export const EventDetails = ({ event }: Props) => {
  const navigate = useNavigate();
  const step = useSelector((state: State) => state.cart.step);
  const cart = useSelector((state: State) => state.cart.cart);
  return (
    <div
      className={`relative z-0 w-full -mb-10 lg:-mb-6 lg:pt-0 ${
        step === CartStep.CHECKOUT ? "-mt-2 lg:mt-4" : "mt-4"
      } ${cart.length === 0 ? "pt-4" : ""}`}
    >
      <div className="md:mx-0 mx-4">
        <div className="shadow-inner bg-gray-50 max-w-lg lg:max-w-full mx-auto lg:mx-0 w-full px-4 py-4 pt-0.5 rounded-lg mt-2">
          {event.organisation && (
            <nav className="flex mt-4 mb-3" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-4">
                <li>
                  <div className="flex">
                    <a
                      onClick={() => navigate("/")}
                      className="text-sm font-medium text-gray-600 hover:text-gray-800 cursor-pointer"
                    >
                      {event.organisation.name}
                    </a>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-500"
                      aria-hidden="true"
                    />
                    <a className="ml-4 text-sm font-medium text-gray-600 hover:text-gray-800 cursor-pointer">
                      {event.name}
                    </a>
                  </div>
                </li>
              </ol>
            </nav>
          )}
          <h1 className="font-semibold text-xl text-gray-700 -mt-1 mb-1">
            {event.name}
          </h1>
          <p className="text-sm text-gray-600">
            {format(new Date(event.startDate), "EE, dd MMM YYY 'at' HH:mm")}
          </p>
          <p className="text-sm text-gray-600 line-clamp-1">{event.location}</p>
        </div>
      </div>
    </div>
    // <div className="relative bg-black z-50 lg:w-1/2 items-center flex xl:justify-end lg:justify-start xl:pr-[15rem] justify-center bg-red-500 sm:pl-8 pb-6 text-white mt-4 pt-4 lg:pt-0 lg:mt-0">
    //   <div className="bg-green-500">
    //     {event.organisation && (
    //       <nav className="flex mt-4 mb-3" aria-label="Breadcrumb">
    //         <ol role="list" className="flex items-center space-x-4">
    //           <li>
    //             <div className="flex">
    //               <a
    //                 href="#"
    //                 className="text-sm font-medium text-gray-300 hover:text-white"
    //               >
    //                 {event.organisation.name}
    //               </a>
    //             </div>
    //           </li>
    //           <li>
    //             <div className="flex items-center">
    //               <ChevronRightIcon
    //                 className="h-5 w-5 flex-shrink-0 text-gray-500"
    //                 aria-hidden="true"
    //               />
    //               <a
    //                 href="#"
    //                 className="ml-4 text-sm font-medium text-gray-300 hover:text-white"
    //               >
    //                 {event.name}
    //               </a>
    //             </div>
    //           </li>
    //         </ol>
    //       </nav>
    //     )}
    //     <h1 className="font-semibold text-xl">{event.name}</h1>
    //     <p className="text-sm text-gray-400">
    //       {format(new Date(event.startDate), "EE, dd MMM YYY 'at' hh:mm")}
    //     </p>
    //     <p className="text-sm text-gray-400 line-clamp-1">{event.location}</p>
    //   </div>
    // </div>
  );
};
