import { Field, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { cartActions } from "../store/cart";
import { State } from "../store/store";

export const OffsetCarbonFootprint = () => {
  const dispatch = useDispatch();
  const carbonFootprintContribution = useSelector(
    (state: State) => state.cart.carbonFootprintContribution
  );
  const numberOfAttendeesInCart = useSelector(
    (state: State) => state.cart.numberOfAttendeesInCart
  );
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  return (
    <div className="overflow-y-scroll overflow-x-hidden relative isolate lg:overflow-hidden bg-green-400 px-4 lg:px-6 py-4 lg:py-8 lg:pb-0 shadow-sm rounded-md lg:flex lg:gap-x-20 lg:pt-0 lg:mt-7">
      <div className="mx-auto lg:mx-0 lg:flex-auto lg:py-6 lg:text-left relative">
        {carbonFootprintContribution ? (
          <div className="flex -mx-1">
            <div className="text-white mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8"
              >
                <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
              </svg>
            </div>
            <div className="pr-3">
              <h1 className="text-lg text-white font-semibold">
                Thank you for your &euro;
                {carbonFootprintContribution.toFixed(2)} Contribution
              </h1>
              <p className="text-sm text-green-100">
                Your contribution has helped make the environment a better place
              </p>
              <button
                onClick={() => {
                  dispatch(
                    cartActions.SetCarbonFootprintContribution({
                      carbonFootprintContribution: 0,
                    })
                  );
                }}
                type="button"
                className="mt-4 rounded-md bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Remove
              </button>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="grid grid-flow-col justify-stretch">
              <div className="">
                <h2 className="text-lg lg:text-2xl font-bold tracking-tight text-white sm:text-3xl">
                  Offset your Carbon Footprint?
                </h2>
                <p className="text-sm text-green-100 mt-2">
                  The average carbon footprint of a Fuinneamh Festival goer is{" "}
                  <strong>40kg</strong>. Let one of our environmental partners
                  compensate your carbon emissions for you.
                  <span
                    className="text-white items-center ml-1.5 relative hover:underline hover:cursor-pointer"
                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                  >
                    <span className="mr-0.5">
                      (<>{showMoreInfo ? "Less" : "More"}</> info)
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 -mr-4 absolute right-0 top-0 mt-0.5 text-white"
                    >
                      {showMoreInfo ? (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      ) : (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      )}
                    </svg>
                  </span>
                </p>
              </div>
              <div className="">
                <div className="lg:flex lg:flex-row-reverse hidden flex-row-reverse">
                  <img src="leaf.png" className="w-10 lg:w-16" />
                </div>
              </div>
            </div>
            {showMoreInfo && (
              <div className="text-sm text-green-100 mt-2">
                This statistic is based off:
                <ul className="list-disc ml-4">
                  <li>
                    Return trip from Dublin = 34kg (170.5g per km @ 200km)
                  </li>
                  <li>12 cans = 1.5kg (680g per 6 pack x 2)</li>
                  <li>
                    A meal per day = 3.5kg (based off estimate calculation of a
                    fast food meal)
                  </li>
                </ul>
                <p className="mt-2">Meal calculation based on:</p>
                <ul className="list-disc ml-4">
                  <li>
                    Beefburger 3.36kg (
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://assets.ctfassets.net/hhv516v5f7sj/4exF7Ex74UoYku640WSF3t/cc213b148ee80fa2d8062e430012ec56/Impossible_foods_comparative_LCA.pdf"
                    >
                      Quantis Report
                    </a>
                    )
                  </li>

                  <li>
                    Carton Box 0.0079kg (
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.procarton.com/wp-content/uploads/2021/06/ProCarton_Carbon-Footprint-Report_Proof-9.pdf"
                    >
                      RISE Report
                    </a>
                    )
                  </li>

                  <li>
                    Fries 0.15g (
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.plateupfortheplanet.org"
                    >
                      Plate Up For the Planet
                    </a>
                    )
                  </li>

                  <li>
                    Carton Box 0.0079kg (
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.procarton.com/wp-content/uploads/2021/06/ProCarton_Carbon-Footprint-Report_Proof-9.pdf"
                    >
                      RISE Report
                    </a>
                    )
                  </li>

                  <li>
                    Coca Cola 0.018kg (
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.coca-colacompany.com/news/improving-our-water-efficiency"
                    >
                      Coca Cola
                    </a>
                    )
                  </li>

                  <li>
                    Cup 0.0079kg (
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.statista.com/statistics/575829/coca-colas-carbon-dioxide-emissions-worldwide"
                    >
                      CO2 Emissions Statistics
                    </a>
                    )
                  </li>
                </ul>
              </div>
            )}
            <Formik
              initialValues={{
                contribution: 5.0 * numberOfAttendeesInCart,
              }}
              validationSchema={Yup.object({
                contribution: Yup.number()
                  .required("Required")
                  .min(1, "Minimum contribution is €1")
                  .max(10000, "Maximum contribution is €10,000"),
              })}
              onSubmit={(values, actions) => {
                dispatch(
                  cartActions.SetCarbonFootprintContribution({
                    carbonFootprintContribution: values.contribution,
                  })
                );
              }}
              enableReinitialize
            >
              {({
                errors,
                handleSubmit,
                values,
                setFieldValue,
                initialValues,
              }) => (
                <form>
                  <div className="mt-4 flex items-end gap-x-6 justify-start">
                    <div className="w-1/2 lg:w-1/3">
                      <label
                        htmlFor="price"
                        className="block text-sm font-medium leading-6 text-white"
                      >
                        I want to contribute
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-green-100 sm:text-sm">
                            &euro;
                          </span>
                        </div>
                        <Field
                          type="number"
                          name="contribution"
                          id="contribution"
                          className="bg-green-500 border-0 block w-full rounded-md py-1.5 pl-7 pr-12 text-green-100 placeholder:text-green-100 focus:ring-2 focus:ring-inset focus:ring-green-200 sm:text-sm sm:leading-6"
                          aria-describedby="price-currency"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span
                            className="text-green-100 sm:text-sm"
                            id="price-currency"
                          >
                            EUR
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="rounded-md bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                      Add to my Cart
                    </button>
                  </div>
                  {errors.contribution && (
                    <p
                      className="mt-3 text-sm text-green-100 flex"
                      id="lastNameError"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                      {errors.contribution}
                    </p>
                  )}
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};
