import { useSelector } from "react-redux";

export const useSelectorByStringPath = (path: string) => {
  return useSelector((state: any) => {
    const keys = path.split(".");
    let result = state;
    for (const key of keys) {
      if (result && result[key] !== undefined) {
        result = result[key];
      } else {
        return undefined;
      }
    }
    return result;
  });
};

export const singularize = (word: string): string => {
  // Check if the word ends with 's' and remove it if it does
  if (word.endsWith("s")) {
    return word.slice(0, -1);
  }
  // If it doesn't end with 's', return the original word
  return word;
};

export const convertToKebabCase = (resourceName: string): string => {
  // Replace spaces with "-" and split the string into words based on uppercase letters or existing "-"
  const words = resourceName.replace(/\s+/g, "-").split(/(?=[A-Z])|-/);
  // Convert each word to lowercase and join them with "-"
  const kebabCaseString = words.map((word) => word.toLowerCase()).join("-");
  return kebabCaseString;
};

export const convertToSentenceCase = (inputString: string): string => {
  // Use a regular expression to add a space before each uppercase letter
  const stringWithSpaces = inputString.replace(/([A-Z])/g, " $1");
  return stringWithSpaces;
};

// To see if two objects are equal by value, not by reference
export const deepEqual = (objA: any, objB: any): boolean => {
  // Check if both objects are of the same type
  if (typeof objA !== typeof objB) {
    return false;
  }

  // Check if both objects are null or not objects
  if (
    objA === null ||
    typeof objA !== "object" ||
    objB === null ||
    typeof objB !== "object"
  ) {
    return objA === objB;
  }

  // Get the keys of both objects
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  // Check if the number of keys is the same
  if (keysA.length !== keysB.length) {
    return false;
  }

  // Check if the values of corresponding keys are deep equal
  for (const key of keysA) {
    if (!deepEqual(objA[key], objB[key])) {
      return false;
    }
  }

  return true;
};
