import { CartItem, PromoCode, PromoCodeType } from "../models/Cart";
import { TicketType } from "../models/Tickets";

export const addTicketToCart = (
  cart: Array<CartItem>,
  ticketUuid: string,
  quantity: number
) => {};

export const calculateDiscount = (promoCode: PromoCode): number => {
  // Takes a promocode and gets the amount/percentage off
  if (promoCode.codeType === PromoCodeType.DISCOUNT) {
    if (promoCode.amount) {
      return parseFloat(promoCode.amount);
    } else if (promoCode.percentage) {
      return parseFloat(
        (
          (parseFloat(promoCode.ticket.price) / 100) *
          parseFloat(promoCode.percentage)
        ).toFixed(2)
      );
    }
  }
  return 0;
};

export const planExistsInCart = (cart: Array<CartItem>): boolean => {
  const planItems = cart.filter(
    (cartItem: CartItem) => cartItem.ticketType == TicketType.PAYMENT_PLAN
  );
  return planItems.length > 0;
};

export const getPlanInCart = (cart: Array<CartItem>): CartItem => {
  const planItems = cart.filter(
    (cartItem: CartItem) => cartItem.ticketType == TicketType.PAYMENT_PLAN
  );
  return planItems[0];
};

export const formatNumberToMoney = (value: number): string => {
  if (!value) {
    return "€0.00";
  }
  return `€${value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};
