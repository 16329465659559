import { Formik } from "formik";
import { Input } from "../../../crud/form/Input";
import { Textarea } from "../../../crud/form/Textarea";
import { DateTimePicker } from "../../../crud/form/DateTimePicker";

export const CreateEventForm = () => {
  return (
    <form>
      <div className="mb-6">
        <div className="text-gray-900 dark:text-white">Name</div>
        <p className="pt-0.5 pb-2 text-gray-500 dark:text-gray-400 text-sm font-light">
          Give your Event a name. This will be displayed on tickets etc.
        </p>
        <Input type="text" name="name" required={true} />
      </div>
      <div className="mb-6">
        <div className="text-gray-900 dark:text-white">Location</div>
        <p className="pt-0.5 pb-2 text-gray-500 dark:text-gray-400 text-sm font-light">
          Where is your event happening? A venue, a festival ground, park etc.?
        </p>
        <Input type="text" name="location" required={true} />
      </div>
      <div className="mb-2">
        <div className="text-gray-900 dark:text-white">
          Start &amp; End Dates
        </div>
        <p className="pt-0.5 pb-2 text-gray-500 dark:text-gray-400 text-sm font-light">
          When is your event starting and when is it ending?
        </p>
        <dl className="divide-y divide-white/10">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
              Starts at
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              <DateTimePicker name="startDate" hasTime={true} />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
              Ends at
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              <DateTimePicker name="endDate" hasTime={true} />
            </dd>
          </div>
        </dl>
      </div>
      <div className="mb-6">
        <div className="text-gray-900 dark:text-white">Description</div>
        <p className="pt-1 pb-2.5 text-gray-500 dark:text-gray-400 text-sm font-light">
          Give your event a description so customers know whats happening.
        </p>
        <Textarea name="description" required={true} />
      </div>
    </form>
  );
};
