import { Popover, Transition } from "@headlessui/react";
import {
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartContext } from "../App";
import { CartItem } from "../models/Cart";
import { TicketType } from "../models/Tickets";
import { cartActions } from "../store/cart";
import { State } from "../store/store";
import { CartItems } from "./CartItems";
import { Discounts } from "./Discounts";
import { OffsetCarbonFootprint } from "./OffsetCarbonFootprint";
import { CartStep } from "../constants";
import { MobileCart } from "./portal/MobileCart";
import { CartTotals } from "./portal/CartTotals";
import { getFeatureFlag } from "../utils/feature-flags";

export const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: State) => state.cart.cart);
  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const step = useSelector((state: State) => state.cart.step);
  const order = useSelector((state: State) => state.cart.order);
  const containsAddons = useSelector(
    (state: State) => state.cart.containsAddons
  );
  const showCheckout = useSelector((state: State) => state.cart.showCheckout);
  const totalDiscount = useSelector((state: State) => state.cart.totalDiscount);
  const carbonFootprintContribution = useSelector(
    (state: State) => state.cart.carbonFootprintContribution
  );
  const [subTotal, setSubTotal] = useState(0);
  const [fees, setFees] = useState(0);
  const [paymentPlanItem, setPaymentPlanItem] = useState<CartItem | undefined>(
    undefined
  );
  const [mobileCartOpen, setMobileCartOpen] = useState(false);
  const cartStep = useSelector((state: State) => state.cart.step);
  const offsetCarbonFootprintEnabled = getFeatureFlag(
    "OFFSET_CARBON_FOOTPRINT_ENABLED"
  );
  const portalNavEnabled = getFeatureFlag("PORTAL_NAV_ENABLED");

  useEffect(() => {
    const paymentPlanItems = cart.filter(
      (cartItem) => cartItem.ticketType === TicketType.PAYMENT_PLAN
    );
    if (paymentPlanItems && paymentPlanItems.length > 0) {
      setPaymentPlanItem(paymentPlanItems[0]);
    } else {
      setPaymentPlanItem(undefined);
    }
  }, [cart]);

  return (
    <>
      {cart.length > 0 ? (
        <>
          <h1 className="sr-only">Order information</h1>
          <section
            aria-labelledby="summary-heading"
            className="lg:bg-gray-50 sm:-mt-10 px-4 lg:pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
          >
            <div className="mx-auto max-w-lg lg:max-w-none">
              <div className="hidden lg:block mt-10">
                <CartItems cart={cart} addonCart={addonCart} />
                <CartTotals />
                {offsetCarbonFootprintEnabled && (
                  <Transition
                    as={Fragment}
                    show={!!!order}
                    enter="transform transition duration-[400ms]"
                    enterFrom="opacity-0 rotate-[-120deg] scale-50"
                    enterTo="opacity-100 rotate-0 scale-100"
                    leave="transform duration-200 transition ease-in-out"
                    leaveFrom="opacity-100 rotate-0 scale-100 "
                    leaveTo="opacity-0 scale-95 "
                  >
                    <div>
                      <OffsetCarbonFootprint />
                    </div>
                  </Transition>
                )}
                {step === CartStep.TICKETS && (
                  <div className="py-6 mt-2">
                    <button
                      onClick={() => {
                        // If there are addons, go to the addons step
                        dispatch(
                          cartActions.SetStep({
                            step: containsAddons
                              ? CartStep.ADDONS
                              : CartStep.CHECKOUT,
                          })
                        );
                      }}
                      type="submit"
                      className="w-full rounded-md border border-transparent bg-blue-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                      <div className="flex items-center justify-center">
                        {containsAddons ? (
                          <>Next: Add-ons</>
                        ) : (
                          <>Continue to Payment</>
                        )}{" "}
                        <ChevronRightIcon className="ml-1.5 h-6 w-6 text-white" />
                      </div>
                    </button>
                  </div>
                )}
              </div>
              <div className={`sm:mt-20 -mb-5 lg:hidden mb-1 mt-10`}>
                {cartStep !== CartStep.CHECKOUT && (
                  <button
                    onClick={() => {
                      if (containsAddons && cartStep != CartStep.ADDONS) {
                        dispatch(
                          cartActions.SetStep({
                            step: containsAddons
                              ? CartStep.ADDONS
                              : CartStep.CHECKOUT,
                          })
                        );
                      } else {
                        setMobileCartOpen(true);
                      }
                    }}
                    type="submit"
                    className="w-full rounded-md border border-transparent bg-blue-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  >
                    <div className="flex items-center justify-center">
                      {containsAddons && cartStep != CartStep.ADDONS ? (
                        <>
                          Next: Add-ons{" "}
                          <ChevronRightIcon className="ml-.5 mt-0.5 h-6 w-6 text-white" />
                        </>
                      ) : (
                        <>
                          <ShoppingCartIcon className="h-5 w-5 text-white mr-1.5" />{" "}
                          View Cart/Pay
                        </>
                      )}{" "}
                    </div>
                  </button>
                )}
              </div>
              <MobileCart
                open={mobileCartOpen}
                setOpen={setMobileCartOpen}
                cart={cart}
                addonCart={addonCart}
              />
            </div>
          </section>
        </>
      ) : (
        <div className="lg:flex hidden items-center justify-center h-screen w-full">
          <ShoppingCartIcon className="h-16 w-16 text-gray-400" />
        </div>
      )}
    </>
  );
};
