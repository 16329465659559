import { Menu } from "@headlessui/react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { EMAIL_KEY, signOut } from "../../../../pages/protected-route";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { AccountMenuContent } from "./AccountMenuContent";

export const AccountMenu = () => {
  return (
    <div className="ml-3">
      <Menu>
        <Menu.Items className="dark:bg-dark-secondary bg-light-secondary !outline-none border dark:border-white/5 rounded-md shadow-lg">
          <AccountMenuContent />
        </Menu.Items>
        <Menu.Button className="!outline-none">
          <div
            // onClick={() => signOut()}
            className="flex items-center gap-x-4 px-3 py-3 text-sm font-semibold leading-6 text-gray-600 dark:text-white dark:hover:text-gray-400"
          >
            <div className="w-full max-w-md rounded-xl shadow-md dark:bg-dark-secondary bg-white ring-1 ring-black/5 p-1.5 -mr-1">
              <UserCircleIcon className="h-8 w-8 dark:text-white" />
            </div>
            <div className="-mt-0.5 max-w-[10rem]">
              <p aria-hidden="true" className="truncate max-w-xs">
                {localStorage.getItem(EMAIL_KEY)}
              </p>
              <div className="flex items-center text-xs font-light">
                <p aria-hidden="true">View Settings</p>
              </div>
            </div>
          </div>
        </Menu.Button>
      </Menu>
    </div>
  );
};
