import { Dialog, Transition } from "@headlessui/react";
import { FunnelIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ChangeEvent, Fragment, ReactNode, useEffect, useState } from "react";
import { AvailableFilter, CRUDResource } from "./models";
import { Loading } from "../components/portal/Loading";
import { Formik } from "formik";
import yup from "../crud/yup-extended";
import { Dropdown } from "./form/Dropdown";
import { toCamelCase, toSentenceCase } from "js-convert-case";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { addSearchParam, constructQueryString } from "../utils/url";
import { useLocation, useSearchParams } from "react-router-dom";

interface Props<T, R> {
  resource: CRUDResource<T, R>;
  isSearching: boolean;
  handleSearchChange: (
    event: ChangeEvent<HTMLInputElement> | string,
    isClear?: boolean
  ) => void;
  debouncedValue: string;
  rawFilters: any;
  setRawFilters: any;
  setSelectedFilters: (filters: string) => void;
}

export const Search = <T extends object, R extends ReactNode>({
  resource,
  isSearching,
  handleSearchChange,
  debouncedValue,
  rawFilters,
  setRawFilters,
  setSelectedFilters,
}: Props<T, R>) => {
  const location = useLocation();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState<Array<any>>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (resource.getFilters) {
      resource.getFilters().then((data) => setFilters(data));
    }
  }, []);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const clearFilters = () => {
    // remove the filters from the URL if they are there
    const params = new URLSearchParams(location.search);
    const search = params.get("search");

    // Clear all parameters
    // we actually don't want to add page back because if you were filtering and were on page 2, then we remove the filters
    // you'd be on page 2 of some page, which doesn't really make sense
    params.forEach((_, key) => {
      params.delete(key);
    });

    if (search) {
      params.set("search", search);
    }

    // Update the URL
    setSearchParams(params);
    setSelectedFilters("");
    setRawFilters({});
  };

  const clearSearch = () => {
    handleSearchChange("", true);
  };

  return (
    <div className="flex border-b dark:border-white/5 pb-0.5" id="search">
      {filters && (
        <Transition.Root show={filterModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setFilterModalOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-75 blur bg-opacity-75 transition-opacity backdrop-blur-sm" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform rounded-lg border dark:border-white/5 bg-light-primary dark:bg-dark-primary text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                    <div className="bg-light-primary dark:bg-dark-secondary py-3 sm:flex sm:px-5 text-gray-900 dark:text-white font-medium">
                      Apply filters
                    </div>
                    {filters.length > 0 ? (
                      <div className="p-10">
                        <Formik
                          initialValues={{}}
                          validationSchema={yup.object({})}
                          onSubmit={(values: any) => {
                            setRawFilters(values);
                            setSelectedFilters(constructQueryString(values));

                            // Add the new filters to the URL as query params
                            for (const key in values) {
                              if (values.hasOwnProperty(key)) {
                                const value = values[key];
                                if (
                                  typeof value === "object" &&
                                  value !== null &&
                                  value.hasOwnProperty("id")
                                ) {
                                  addSearchParam(
                                    searchParams,
                                    setSearchParams,
                                    `${key}Id`,
                                    value.id
                                  );
                                } else {
                                  addSearchParam(
                                    searchParams,
                                    setSearchParams,
                                    key,
                                    value
                                  );
                                }
                              }
                            }
                          }}
                          // enableReinitialize="false"
                        >
                          {({
                            errors,
                            handleSubmit,
                            values,
                            setFieldValue,
                            initialValues,
                          }) => (
                            <form>
                              {filters.map((filter: AvailableFilter) => (
                                <>
                                  {/* border-b border-white/10 */}
                                  <div className="flex pb-4">
                                    <div className="w-1/2">
                                      <h3 className="text-gray-900 dark:text-white">
                                        {filter.name}
                                      </h3>
                                    </div>
                                    <div className="w-1/2">
                                      {filter.options && (
                                        <Dropdown
                                          name={toCamelCase(filter.name)}
                                          options={Array.from(filter.options)}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))}
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:w-auto mr-3"
                                onClick={() => handleSubmit()}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={() => setFilterModalOpen(false)}
                              >
                                Cancel
                              </button>
                            </form>
                          )}
                        </Formik>
                      </div>
                    ) : (
                      <div className="items-center justify-center flex w-full my-10">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-12 w-12 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      <div className="my-5 px-8 w-1/2">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative mt-1 rounded-md shadow-sm flex items-center">
          <div
            className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            aria-hidden="true"
          >
            <MagnifyingGlassIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </div>
          {isSearching && (
            <div
              className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
              aria-hidden="true"
            >
              <svg
                className="animate-spin -ml-1 h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
          <input
            onChange={handleSearchChange}
            type="text"
            name="search"
            id="search"
            // value={debouncedValue}
            className="dark:bg-dark-secondary block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 dark:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Search"
          />
        </div>
        {(debouncedValue || searchParams.get("search")) && (
          <div className="text-gray-500 dark:text-gray-400 text-xs mt-3">
            Showing results for{" "}
            <i>{debouncedValue || searchParams.get("search")}</i>
            <span
              className="ml-1 underline cursor-pointer"
              onClick={clearSearch}
            >
              clear
            </span>
          </div>
        )}
        <>
          {Object.keys(rawFilters).length > 0 && (
            <div className="text-gray-400 text-xs mt-4 flex items-center">
              <span className="mr-2">Filtering by</span>
              {Object.keys(rawFilters).map((key: any) => {
                return (
                  <>
                    {rawFilters[key] && (
                      <button
                        type="button"
                        className="rounded bg-white/10 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
                      >
                        {toSentenceCase(key)} ={" "}
                        {typeof rawFilters[key] === "object" &&
                        rawFilters[key].hasOwnProperty("value") ? (
                          <span>{rawFilters[key].value}</span>
                        ) : (
                          <span>{rawFilters[key]}</span>
                        )}
                      </button>
                    )}
                  </>
                );
              })}
              <div
                className="ml-2 underline flex items-center ml-1 cursor-pointer"
                onClick={() => clearFilters()}
              >
                <XMarkIcon className="h-4 w-4 mr-0.5" /> Clear filters
              </div>
            </div>
          )}
        </>
      </div>
      {resource.getFilters && (
        <div className="flex-1 flex items-center justify-end mr-8">
          <button
            onClick={() => setFilterModalOpen(true)}
            type="button"
            className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20 flex"
          >
            <FunnelIcon className="h-5 w-5 mr-1.5" aria-hidden="true" />
            Filter
          </button>
        </div>
      )}
    </div>
  );
};
