import { format, subDays } from "date-fns";
import { Line } from "react-chartjs-2";

interface Props {
  totalRange: Array<number>;
}

export const SalesLast7Days = ({ totalRange }: Props) => {
  const last7Days = Array.from({ length: 7 }, (_, i) =>
    subDays(new Date(), i)
  ).reverse();

  return (
    <div style={{ width: "100%", height: "90%" }}>
      <Line
        data={{
          labels: last7Days.map((date) => format(date, "d MMM")),
          datasets: [
            {
              label: "Net sales over the last 7 days",
              data: totalRange,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        }}
        options={{
          scales: {
            y: {
              ticks: {
                callback: function (tickValue: string | number) {
                  const value = Number(tickValue);
                  if (value >= 1000) {
                    return (value / 1000).toFixed(1) + "k";
                  }
                  return value.toString();
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  const index = context.dataIndex;
                  return [`Sales: €${totalRange[index].toLocaleString()}`];
                },
              },
            },
          },
        }}
      />
    </div>
  );
};
