import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { Field, Form, FormikProps, withFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { HoldCountdown } from "../components/HoldCountdown";
import { CartStep, NAME_REGEX } from "../constants";
import { AddonCartItem, CartItem, PromoCode } from "../models/Cart";
import { cartActions } from "../store/cart";
import { State } from "../store/store";
import { planExistsInCart } from "../utils/cart";
import toast from "react-hot-toast";
import { store } from "..";
import { TicketQuestions } from "../components/TicketQuestions";
import { TicketAnswer, TicketQuestion } from "../models/Tickets";

export interface BillingFormValues {
  emailAddress: string;
  confirmEmailAddress: string;
  firstName: string;
  lastName: string;
  cart: Array<CartItem>;
  promoCode?: PromoCode;
  addonCart: Array<AddonCartItem>;
  carbonFootprintContribution: number;
  questions: Array<TicketAnswer>;
}

interface OtherProps {
  cart: Array<CartItem>;
  promoCode?: PromoCode;
  carbonFootprintContribution?: number | undefined;
}

const BillingDetailsInnerForm = (
  props: OtherProps & FormikProps<BillingFormValues>
) => {
  const dispatch = useDispatch();
  const { touched, errors, isSubmitting, handleSubmit, cart } = props;
  const billingDetailsSubmitting = useSelector(
    (state: State) => state.cart.billingDetailsSubmitting
  );
  const order = useSelector((state: State) => state.cart.order);
  const containsAddons = useSelector(
    (state: State) => state.cart.containsAddons
  );
  const [questions, setQuestions] = useState<Array<TicketQuestion> | undefined>(
    undefined
  );

  // Everytime the cart changes, try extract the questions
  useEffect(() => {
    setQuestions(
      cart
        .map((cartItem) => cartItem.questions)
        .filter(
          (questions): questions is TicketQuestion[] => questions !== undefined
        ) // Type guard to ensure questions are not undefined
        .flat()
    );
  }, [cart]);

  // If the user sets the Carbon Footprint at the Billing Details Form, update the Carbon Footprint Contribution
  useEffect(() => {
    props.setFieldValue(
      "carbonFootprintContribution",
      props.carbonFootprintContribution
    );
  }, [props.carbonFootprintContribution]);

  return (
    <Form>
      <section
        aria-labelledby="billing-details-heading"
        className={`lg:-mt-4 ${order ? "hidden lg:block" : ""}`}
      >
        <div className="flex justify-between">
          <div>
            <h2
              id="billing-details-heading"
              className="text-lg font-medium text-gray-900"
            >
              Billing Details
            </h2>
          </div>
          <div>
            {order && order.heldUntil && (
              <HoldCountdown heldUntil={order.heldUntil} />
            )}
          </div>
        </div>
        {/* <Field type="hidden" name="cart" /> */}
        <Field type="hidden" name="promoCode" />
        <Field type="hidden" name="carbonFootprintContribution" />{" "}
        {/* First & Last Name */}
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <div>
            <label
              htmlFor=""
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <Field
                type="text"
                id="firstName"
                name="firstName"
                autoComplete="given-name"
                disabled={order}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 ${
                  errors.firstName
                    ? "text-red-900 ring-1 ring-inset ring-red-300"
                    : ""
                }`}
              />
              {errors.firstName && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {errors.firstName && (
              <p className="mt-2 text-sm text-red-600" id="firstNameError">
                {errors.firstName}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <Field
                type="text"
                id="lastName"
                name="lastName"
                autoComplete="family-name"
                disabled={order}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 ${
                  errors.lastName
                    ? "text-red-900 ring-1 ring-inset ring-red-300"
                    : ""
                }`}
              />
              {errors.lastName && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {errors.lastName && (
              <p className="mt-2 text-sm text-red-600" id="lastNameError">
                {errors.lastName}
              </p>
            )}
          </div>
        </div>
        {/* Email */}
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <Field
                type="text"
                id="emailAddress"
                name="emailAddress"
                autoComplete="email"
                disabled={order}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 ${
                  errors.emailAddress
                    ? "text-red-900 ring-1 ring-inset ring-red-300"
                    : ""
                }`}
              />
              {errors.emailAddress && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {errors.emailAddress && (
              <p className="mt-2 text-sm text-red-600" id="emailAddressError">
                {errors.emailAddress}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm Email
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <Field
                type="text"
                id="confirmEmailAddress"
                name="confirmEmailAddress"
                disabled={order}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 ${
                  errors.confirmEmailAddress
                    ? "text-red-900 ring-1 ring-inset ring-red-300"
                    : ""
                }`}
                onPaste={(e: any) => {
                  toast.error("Please type in your email address");
                  e.preventDefault();
                }}
              />
              {errors.confirmEmailAddress && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {errors.confirmEmailAddress && (
              <p className="mt-2 text-sm text-red-600" id="emailAddressError">
                {errors.confirmEmailAddress}
              </p>
            )}
          </div>
        </div>
        {questions && questions.length > 0 && (
          <TicketQuestions order={order} questions={questions} />
        )}
        {!order && (
          <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
            {!billingDetailsSubmitting && (
              <div className="flex justify-between w-full">
                <div className="order-last">
                  <button
                    type="submit"
                    disabled={billingDetailsSubmitting}
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="w-full rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50 order-1 sm:w-auto"
                  >
                    {billingDetailsSubmitting ? (
                      <svg
                        className="animate-spin h-5 w-5 text-blue-200"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <>Continue</>
                    )}
                  </button>
                </div>
                <div className="order-first items-center flex">
                  <div className="text-center text-sm text-gray-500 sm:order-2">
                    <p>
                      <a
                        onClick={() => {
                          dispatch(
                            cartActions.SetStep({
                              step: containsAddons
                                ? CartStep.ADDONS
                                : CartStep.TICKETS,
                            })
                          );
                        }}
                        className="font-medium text-blue-600 hover:text-blue-500 hover:cursor-pointer"
                      >
                        <span aria-hidden="true">&larr; </span>
                        {containsAddons ? "Back to Addons" : "Back to Tickets"}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </Form>
  );
};

interface BillingDetailsFormProps {
  cart: Array<CartItem>;
  addonCart: Array<AddonCartItem>;
  promoCode?: PromoCode;
  carbonFootprintContribution?: number | undefined;
}

const mapPropsToValues = (
  props: BillingDetailsFormProps
): BillingFormValues => {
  return {
    emailAddress: "",
    confirmEmailAddress: "",
    firstName: "",
    lastName: "",
    cart: props.cart || [],
    addonCart: [],
    carbonFootprintContribution: props.carbonFootprintContribution || 0,
    questions: props.cart.flatMap(
      (cartItem) =>
        cartItem.questions?.map((question) => ({
          uuid: question.uuid,
          answer: "",
        })) || []
    ),
  };
};
// Wrap our form with the withFormik HoC
export const BillingDetailsForm = withFormik<
  BillingDetailsFormProps,
  BillingFormValues
>({
  mapPropsToValues,
  validateOnChange: false,
  validationSchema: Yup.object().shape({
    emailAddress: Yup.string()
      .email("Must be a valid email")
      .required("Required"),
    confirmEmailAddress: Yup.string()
      .email("Must be a valid email")
      .required("Required")
      .oneOf([Yup.ref("emailAddress")], "Email does not match"),
    firstName: Yup.string()
      .matches(NAME_REGEX, "Please enter a valid name")
      .required("Required"),
    lastName: Yup.string()
      .matches(NAME_REGEX, "Please enter a valid name")
      .required("Required"),
    questions: Yup.array().of(
      Yup.object().shape({
        uuid: Yup.string().required("UUID is required"),
        answer: Yup.string().required("Answer is required"),
      })
    ),
  }),
  handleSubmit: (values) => {
    store.dispatch(
      cartActions.SetBillingDetailsSubmitting({
        billingDetailsSubmitting: true,
      })
    );
    const promoCode = store.getState().cart.appliedPromoCode;
    if (promoCode) {
      values.promoCode = promoCode;
    }
    const addonCart = store.getState().cart.addonCart;
    if (addonCart) {
      values.addonCart = addonCart;
    }
    if (planExistsInCart(values.cart)) {
      store.dispatch(cartActions.CreatePaymentPlan({ order: values }));
    } else {
      store.dispatch(cartActions.CreateOrder({ order: values }));
    }
  },
})(BillingDetailsInnerForm);

/*
answers: [{"uuid": "", ""}]
*/
