import { ReactNode, useEffect, useState } from "react";
import { useDarkMode } from "../hooks";
import { useSearchParams } from "react-router-dom";
import { PaginationBase } from "../models/Dashboard";
import { addSearchParam } from "../utils/url";

interface Props<T, R> {
  headers: Array<string>;
  records: Array<T>;
  pagination?: PaginationBase;
  getPageOfRecords?: (page: number) => void;
  rowRenderer: (record: T) => R;
}

export const Table = <T extends object, R extends ReactNode>({
  headers,
  records,
  pagination,
  getPageOfRecords,
  rowRenderer,
}: Props<T, R>) => {
  const isDarkMode = useDarkMode();
  const [searchParams, setSearchParams] = useSearchParams();

  const setPage = (page: number) => {
    if (getPageOfRecords) {
      addSearchParam(searchParams, setSearchParams, "page", page.toString());
      getPageOfRecords(page);
    }
  };

  return (
    <>
      {/* Records list (only on smallest breakpoint) */}

      {/* Records table (small breakpoint and up) */}
      <div className="block">
        <div className="inline-block min-w-full border-b border-gray-200 dark:border-white/5 align-middle">
          <table className="min-w-full overflow-x-scroll">
            {headers.length > 0 && (
              <thead>
                <tr className="border-gray-200 dark:border-white/5">
                  {headers.map((header, index) => {
                    return (
                      <th
                        key={index}
                        className={`${
                          index == 0 ? "pl-8" : " "
                        } border-b border-gray-200 dark:border-white/5 bg-gray-50 dark:bg-transparent dark:text-white px-6 py-3 text-left text-sm font-semibold text-gray-900`}
                        scope="col"
                      >
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody className="bg-red-500 divide-y divide-gray-100 bg-white dark:bg-transparent dark:divide-white/5 overflow-x-scroll">
              {records.map((record: any) => (
                <tr key={record.id} className="overflow-x-scroll">
                  {rowRenderer(record)}
                </tr>
              ))}
            </tbody>
          </table>
          {getPageOfRecords && pagination && (
            <nav
              className="flex items-center justify-between border-t border-gray-200 bg-white dark:dark:bg-dark-secondary dark:border-white/5 px-4 py-5 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700 dark:text-gray-400">
                  Showing{" "}
                  <span className="font-medium">
                    {pagination.page * pagination.perPage -
                      (pagination.perPage - 1)}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {pagination.page * pagination.perPage > pagination.count
                      ? pagination.count
                      : pagination.page * pagination.perPage}
                  </span>{" "}
                  of <span className="font-medium">{pagination.count}</span>{" "}
                  results
                </p>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end -my-2">
                {pagination.page > 1 && (
                  <>
                    {isDarkMode ? (
                      <button
                        type="button"
                        onClick={() => setPage(pagination.page - 1)}
                        className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                      >
                        Previous
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setPage(pagination.page - 1)}
                        className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                      >
                        Previous
                      </button>
                    )}
                  </>
                )}
                {pagination.page * pagination.perPage < pagination.count && (
                  <>
                    {isDarkMode ? (
                      <button
                        type="button"
                        onClick={() => setPage(pagination.page + 1)}
                        className="ml-3 rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setPage(pagination.page + 1)}
                        className="ml-3 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                      >
                        Next
                      </button>
                    )}
                  </>
                )}
              </div>
            </nav>
          )}
        </div>
      </div>
    </>
  );
};
