import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CartItems } from "../CartItems";
import { AddonCartItem, CartItem } from "../../models/Cart";
import { CartTotals } from "./CartTotals";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart";
import { CartStep } from "../../constants";
import { OffsetCarbonFootprint } from "../OffsetCarbonFootprint";
import { getFeatureFlag } from "../../utils/feature-flags";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  cart: Array<CartItem>;
  addonCart: Array<AddonCartItem>;
  readOnly?: boolean;
}

export const MobileCart = ({
  open,
  setOpen,
  cart,
  addonCart,
  readOnly,
}: Props) => {
  const dispatch = useDispatch();
  const offsetCarbonFootprintEnabled = getFeatureFlag(
    "OFFSET_CARBON_FOOTPRINT_ENABLED"
  );
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Order summary
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <CartItems
                        cart={cart}
                        addonCart={addonCart}
                        hideTitle={true}
                      />
                      <CartTotals />
                      {offsetCarbonFootprintEnabled && (
                        <>
                          {!readOnly && (
                            <div className="mt-5">
                              <OffsetCarbonFootprint />
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {!readOnly && (
                      <div className="border-t border-gray-200 px-4 pb-6 sm:px-6">
                        <button
                          onClick={() => {
                            // If there are addons, go to the addons step
                            setOpen(false);
                            setTimeout(() => {
                              dispatch(
                                cartActions.SetStep({
                                  step: CartStep.CHECKOUT,
                                })
                              );
                            }, 500);
                          }}
                          type="submit"
                          className="mt-6 w-full rounded-md border border-transparent bg-blue-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        >
                          Continue to payment
                        </button>
                        <div className="flex justify-center text-center text-sm text-gray-500 mt-4">
                          <p>
                            or{" "}
                            <button
                              type="button"
                              className="font-medium text-blue-600 hover:text-blue-500"
                              onClick={() => setOpen(false)}
                            >
                              Continue Shopping
                              <span aria-hidden="true"> &rarr;</span>
                            </button>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
