import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance } from "@stripe/stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { stripePromise } from "../components/PaymentDetails";
import { ChangePaymentDetailsForm } from "../forms/ChangePaymentDetailsForm";
import { OrderBase } from "../models/Order";
import { getOrder, getSetupIntent } from "../queries";
import { cartActions } from "../store/cart";
import { State } from "../store/store";
import { PortalLayout } from "../components/portal/layouts/PortalLayout";

export const PaymentPlan = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderBase>();
  const [clientSecret, setClientSecret] = useState<string>("");
  const dispatch = useDispatch();
  const paymentPlanUpdated = useSelector(
    (state: State) => state.cart.paymentPlanUpdated
  );

  useEffect(() => {
    dispatch(cartActions.SetPaymentPlanUpdated({ paymentPlanUpdated: false }));
  }, []);

  useEffect(() => {
    (async function () {
      const orderId = searchParams.get("orderId");
      if (orderId) {
        try {
          let response = await getOrder(orderId);
          if (!response.data.monthlyTotal) {
            navigate("/");
          }
          setOrder(response.data);
        } catch (exception) {
          navigate("/");
        }
      } else {
        // Redirect
        navigate("/");
      }
    })();
  }, [searchParams]);

  useEffect(() => {
    (async function () {
      if (order) {
        let response = await getSetupIntent(order.uuid);
        setClientSecret(response.data.clientSecret);
      }
    })();
  }, [order]);

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance,
  };

  return (
    <PortalLayout>
      <div className="bg-white">
        {order ? (
          <>
            {/* Background color split screen for large screens */}
            <div
              className="fixed top-0 left-0 hidden h-full w-1/2 bg-white lg:block"
              aria-hidden="true"
            />
            <div
              className="fixed top-0 right-0 hidden h-full w-1/2 bg-gray-50 lg:block"
              aria-hidden="true"
            />

            <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
              <div className="px-4 pt-16 pb-36 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
                {!paymentPlanUpdated ? (
                  <>
                    <div className="mx-auto max-w-lg lg:max-w-none">
                      <div className="border-t border-gray-200 pt-6 mt-6">
                        <div>
                          <span className="uppercase text-sm font-bold">
                            Payment Plan{" "}
                            <span className="lowercase">{order.uuid}</span>
                          </span>
                          <h1 className="text-3xl font-bold mt-2">
                            Update your Payment Plan
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8">
                      <div className="mt-7">
                        <p className="text-sm text-gray-700">
                          If you have lost your card, it has been cancelled, or
                          you just want to change what card the payment plan
                          uses, you can change its details below. Once verified,
                          these details will then be used in the next
                          instalments in the payment plan.
                        </p>
                      </div>
                    </div>
                    {clientSecret && (
                      <Elements options={options} stripe={stripePromise}>
                        <ChangePaymentDetailsForm
                          clientSecret={clientSecret}
                          orderUuid={order.uuid}
                        />
                      </Elements>
                    )}
                  </>
                ) : (
                  <>
                    <div className="mx-auto max-w-lg lg:max-w-none">
                      <div className="flex items-center">
                        <CheckCircleIcon className="text-green-400 h-12 w-12" />
                        <div className="ml-3">
                          <h2 className="text-lg font-medium text-gray-900 -mb-1.5">
                            Successfully Updated Payment Plan{" "}
                          </h2>
                          <small>#{order.uuid}</small>
                        </div>
                      </div>
                      <div className="border-t border-gray-200 pt-6 mt-6">
                        <p className="text-sm text-gray-700">
                          Thank you for updating your Payment Plan. We will use
                          the details you provided to charge any other
                          oustanding instalments in your payment and will
                          continue to charge the upcoming instalments as
                          scheduled.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="h-screen flex items-center justify-center">
            <svg
              className="animate-spin h-14 w-14 text-blue-200 -mt-20"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
      </div>
    </PortalLayout>
  );
};
