import {
  AdjustmentsHorizontalIcon,
  ArrowRightStartOnRectangleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import {
  EVENT_KEY,
  ORGANISATION_KEY,
  signOut,
} from "../../../../pages/protected-route";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../../store/store";
import { ButtonSpinner } from "../../../ButtonSpinner";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { dashboardActions } from "../../../../store/dashboard";
import { Menu } from "@headlessui/react";

export const AccountMenuContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organisation = JSON.parse(
    localStorage.getItem(ORGANISATION_KEY) || "{}"
  );
  const organisations = useSelector(
    (state: State) => state.dashboard.organisations
  );

  const switchOrganisation = () => {
    // Set local storage for event and org to null
    localStorage.removeItem(ORGANISATION_KEY);
    localStorage.removeItem(EVENT_KEY);

    // Say the interceptors aren't ready so they get re-added
    dispatch(
      dashboardActions.SetInterceptorsReady({ interceptorsReady: false })
    );

    // and set the IDs in the state to trigger a re-render
    dispatch(dashboardActions.SetOrganisationId({ organisationId: null }));
    dispatch(dashboardActions.SetEventId({ eventId: null }));
  };

  return (
    <div className="">
      <div className="border-b dark:border-white/5 flex">
        <p className="truncate px-3.5 py-3" role="none">
          <span className="block text-xs text-gray-400" role="none">
            Organisation
          </span>
          <span
            className="mt-0.5 font-semibold text-gray-900 dark:text-white "
            role="none"
          >
            {organisation ? (
              organisation.name
            ) : (
              <div className="mt-1">
                <ButtonSpinner />
              </div>
            )}
          </span>
        </p>
      </div>
      {organisations && organisations.length > 1 && (
        <Menu.Item>
          {({ close }) => (
            <div
              onClick={() => {
                switchOrganisation();
                close();
              }}
              className="flex items-center px-3.5 pt-3 text-gray-400 text-sm hover:text-gray-500 cursor-pointer"
            >
              <ArrowsRightLeftIcon className="h-4 w-4 mr-1.5" />
              Switch Organisation
            </div>
          )}
        </Menu.Item>
      )}
      <div
        onClick={() => navigate("/dashboard/settings/organisation")}
        className="flex items-center px-3.5 pt-3 pb-3 text-gray-400 text-sm hover:text-gray-500 cursor-pointer"
      >
        <AdjustmentsHorizontalIcon className="h-4 w-4 mr-1.5" />
        Organisation Settings
      </div>
      <div
        onClick={() => navigate("/dashboard/settings/user")}
        className="flex items-center px-3.5 pb-3 text-gray-400 text-sm hover:text-gray-500 cursor-pointer"
      >
        <UserIcon className="h-4 w-4 mr-1.5" />
        User Settings
      </div>
      <div
        onClick={() => signOut()}
        className="cursor-pointer flex px-3.5 py-3 border-t dark:border-white/5 text-gray-400 text-sm items-center hover:text-gray-500"
      >
        <ArrowRightStartOnRectangleIcon className="h-4 w-4 mr-1.5" />
        Sign Out
      </div>
    </div>
  );
};
