// interface Props {
//     children: any;
// }

import { Route, Routes } from "react-router-dom";
import { List } from "./List";
import { Create } from "./Create";
import { CRUDResource } from "./models";
import { convertToKebabCase } from "./utils";
import { ReactNode } from "react";
import { NotFound } from "../pages/dashboard/not-found";
import { View } from "./View";

interface Props<T, R> {
  resource: CRUDResource<T, R>;
}

export const CRUDRoute = <T extends object, R extends ReactNode>({
  resource,
}: Props<T, R>) => {
  return (
    <Routes>
      {/* Option to disable one of the routes. e.g. if you don't want to have a create or update screen you can disable it /*}
      {/* Decide if we want to do a Create and Edit Slide over. Could actually have a configuration like "createIsSlideOver" to override it and have both because the core form will be the same, just whether its displayed on a page or a slideover is what differs */}

      {/* We might not want the CRUDRoute to define every page for us. e.g. if some page has some seriously unique stuff, you can simply provide a React component to override with
       */}
      {/* Either we dynamically create Redux stuff or we pass in functions to get, create, update and delete */}
      {/* List of Objects with Delete Functionality */}
      {/* With listing, if you don't specify a Row Renderer we will show all non object columns with overflow scrolling */}
      <Route path={``} element={<List resource={resource} />} />

      {/* Create the Object. A yup validation schema is passed in and we can infer what fields it should render based on that */}
      {/* do a "if not slideOverCreate" */}
      <Route path={`create`} element={<List resource={resource} />} />

      {false && (
        <>
          not in use yet
          {/* View a Single Object. Maybe have this in a Slide over? Add params to the URL when the slideover is open? */}
          <Route
            path={`:${convertToKebabCase(resource.name)}Id`}
            element={<View resource={resource} />}
          />
        </>
      )}

      {/* Update/Edit. Maybe also a slideover or Modal */}
      <Route
        path={`:${convertToKebabCase(resource.name)}Id/update`}
        element={<p className="text-lg text-black">Update Object</p>}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
