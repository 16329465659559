import { Popover, Transition } from "@headlessui/react";
import { AxiosResponse } from "axios";
import { Fragment, useEffect, useState } from "react";
import { QueryClient, useQuery } from "react-query";
import { Ticket as TicketModel } from "../models/Tickets";
import {
  getOrder,
  getOrderByGatewayId,
  getTickets,
  validateToken,
} from "../queries";
import {
  ArrowsRightLeftIcon,
  BuildingOfficeIcon,
  CreditCardIcon,
  EyeIcon,
  PlusIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../store/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PortalLayout } from "../components/portal/layouts/PortalLayout";
import { Nav } from "../components/portal/Nav";
import { classNames } from "../utils/styles";
import { ManageOrderTab } from "../constants";
import { cartActions } from "../store/cart";
import { ViewOrder } from "../components/portal/order/ViewOrder";
import { PurchaseAddons } from "../components/portal/order/PurchaseAddons";
import { OrderResponse } from "../models/Order";
import { Loading } from "../components/portal/Loading";
import { toSnakeCase } from "js-convert-case";
import { decodeToken } from "react-jwt";
import Lottie from "lottie-react";
import successAnimation from "../assets/successAnimation.json";

const tabs = [
  {
    name: "Purchase Addons",
    tab: ManageOrderTab.PURCHASE_ADDONS,
    icon: PlusIcon,
  },
  {
    name: "View Order",
    tab: ManageOrderTab.VIEW_ORDER,
    icon: CreditCardIcon,
  },
];

export const Order = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, data } = useQuery<
    AxiosResponse<Array<TicketModel>>,
    Error
  >("tickets", getTickets);
  const manageOrderTab = useSelector(
    (state: State) => state.cart.manageOrderTab
  );
  const order = useSelector((state: State) => state.cart.order);
  const [hasCheckedForPaymentComplete, setHasCheckedForPaymentComplete] =
    useState(false);
  const [showAddonPaymentComplete, setShowAddonPaymentComplete] =
    useState(false);
  const event = useSelector((state: State) => state.cart.event);

  // Check token and then get order
  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      validateToken(token)
        .then(() => {
          const decodedToken: any = decodeToken(token);
          // call to get order
          getOrder(decodedToken["order_uuid"] as string)
            .then((response) => {
              dispatch(
                cartActions.SetOrder({
                  orderResponse: response.data as OrderResponse,
                })
              );
            })
            .catch((error) => navigate("/"));
        })
        .catch(() => {
          navigate("/");
        });
    } else {
      navigate("/");
    }
  }, []);

  // If the URL has a payment intent ID in it, get it and then use that to display the page
  useEffect(() => {
    (async function () {
      const paymentIntentClientSecret = searchParams.get(
        "payment_intent_client_secret"
      );
      if (paymentIntentClientSecret) {
        await getOrderByGatewayId(paymentIntentClientSecret);
        setShowAddonPaymentComplete(true);
      } else {
        // Check and see if it's a Setup Intent, if it is we need to charge the Payment Plan now
        const setupIntent = searchParams.get("setup_intent");
        if (setupIntent) {
          let response = await getOrderByGatewayId(setupIntent);
          setShowAddonPaymentComplete(true);
        }
      }
      setHasCheckedForPaymentComplete(true);
    })();
  }, []);

  return (
    <PortalLayout>
      <div className="bg-white relative md:mt-0 -mt-6 mx-6 lg-mx-0">
        <Nav />
        {order && hasCheckedForPaymentComplete ? (
          <div className="max-w-3xl mx-auto lg:pt-24 pt-28">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Manage Order
            </h1>

            {showAddonPaymentComplete ? (
              <div className="relative mx-auto">
                <div className="border-t border-gray-200 pt-6 mt-6"></div>
                <div className="px-4 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0">
                  <div className="mx-auto max-w-lg lg:max-w-none">
                    <div className="flex items-center">
                      <div className="h-12 w-12">
                        <Lottie animationData={successAnimation} loop={false} />
                      </div>
                      <div className="ml-3">
                        <h2 className="text-lg font-medium text-gray-900 -mb-1.5">
                          Thanks for your order!{" "}
                        </h2>
                        <small>#{order.uuid}</small>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="">
                      <p className="text-sm text-gray-700">
                        Thank you for your order. We have sent a confirmation of
                        the add-ons you purchased to{" "}
                        <span className="underline">{order.emailAddress}</span>.
                        Please allow a couple of minutes for this to come
                        through. If you can't see this email, make sure you
                        check your junk.
                      </p>
                    </div>

                    {event && (
                      <button
                        onClick={() => {
                          navigate(
                            `/${event.slug}/order?token=${searchParams.get(
                              "token"
                            )}`
                          );
                          setHasCheckedForPaymentComplete(true);
                          setShowAddonPaymentComplete(false);
                        }}
                        type="submit"
                        className="mt-6 w-full rounded-md border border-transparent bg-blue-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                      >
                        <div className="flex items-center justify-center">
                          Go back to Manage my Order
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="mt-2 sm:border-b border-gray-200 pb-5 text-sm sm:flex sm:justify-between">
                  <dl className="lg:flex block">
                    <dt className="text-gray-500">Order number&nbsp;</dt>
                    <dd className="font-medium text-gray-900">#{order.uuid}</dd>
                    <dt>
                      <span className="sr-only">Date</span>
                      <span
                        className="mx-2 text-gray-400 hidden lg:block"
                        aria-hidden="true"
                      >
                        &middot;
                      </span>
                    </dt>
                    <dd className="font-medium text-gray-900 lg:mt-0 hidden lg:block">
                      <time dateTime="2021-03-22">March 22, 2021</time>
                    </dd>
                  </dl>
                </div>

                <div>
                  <div className="sm:hidden -mt-1">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      id="tabs"
                      name="tabs"
                      onChange={(event) => {
                        dispatch(
                          cartActions.SetManageOrderTab({
                            manageOrderTab: toSnakeCase(
                              event.target.value
                            ).toUpperCase() as ManageOrderTab,
                          })
                        );
                      }}
                      className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                      // defaultValue={tabs.find((tab) => tab.current).name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.tab}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <div className="">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <button
                            key={tab.name}
                            onClick={() =>
                              dispatch(
                                cartActions.SetManageOrderTab({
                                  manageOrderTab: tab.tab,
                                })
                              )
                            }
                            className={classNames(
                              tab.tab === manageOrderTab
                                ? "border-blue-500 text-blue-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 p-4 px-1 text-sm font-medium"
                            )}
                            aria-current={
                              tab.tab === manageOrderTab ? "page" : undefined
                            }
                          >
                            <tab.icon
                              className={classNames(
                                tab.tab === manageOrderTab
                                  ? "text-blue-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "-ml-0.5 mr-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                            <span>{tab.name}</span>
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                {manageOrderTab === ManageOrderTab.VIEW_ORDER && (
                  <ViewOrder order={order} />
                )}
                {manageOrderTab === ManageOrderTab.PURCHASE_ADDONS && (
                  <PurchaseAddons addons={order.availableAddons} />
                )}
              </>
            )}
          </div>
        ) : (
          <div className="flex w-full h-screen items-center justify-center -mt-96">
            <Loading />
          </div>
        )}
      </div>
    </PortalLayout>
  );
};
