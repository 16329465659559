import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CartItem, PromoCodeType } from "../models/Cart";
import { State } from "../store/store";
import { calculateDiscount } from "../utils/cart";

export const Discounts = () => {
  const cart = useSelector((state: State) => state.cart.cart);
  const appliedPromoCode = useSelector(
    (state: State) => state.cart.appliedPromoCode
  );
  const totalDiscounts = useSelector(
    (state: State) => state.cart.totalDiscount
  );

  return (
    <>
      {!!totalDiscounts && (
        <>
          <div className="flex items-center justify-between">
            <dt className="text-green-600">Discounts</dt>
            <dd className="text-green-600">
              &euro;{totalDiscounts.toFixed(2)}
            </dd>
          </div>
        </>
      )}
    </>
  );
};
