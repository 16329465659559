import { DashboardLayout } from "@/components/dashboard/layouts/DashboardLayout";

export const DashboardErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => {
  return (
    <DashboardLayout pageTitle="Error" requiredPermissions={[]}>
      <div className="flex items-center justify-center h-screen w-full">
        <div className="text-center -mt-52">
          <h1 className="text-5xl">🤦‍♂️</h1>
          <h3 className="mt-4 font-bold text-gray-900 text-4xl dark:text-white">
            An error occured, sorry about that
          </h3>
          <p className="mt-4 text-gray-400 mx-[20rem]">
            The page you were trying to view encountered an error. Our team has
            been notified and will work to resolve the issue as soon as
            possible. Try again later
          </p>
          <div className="mt-4">
            <pre className="dark:text-white">{error.message}</pre>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
