import { DashboardLayout } from "../../components/dashboard/layouts/DashboardLayout";
import { PermissionDenied } from "../../components/PermissionDenied";

export const PermissionDeniedPage = () => {
  return (
    <DashboardLayout pageTitle="Permission Denied" requiredPermissions={[]}>
      <PermissionDenied />
    </DashboardLayout>
  );
};
