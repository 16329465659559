import createSagaMiddleware from "redux-saga";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { cartSaga, cartSlice, CartState } from "./cart";
import { DashboardState, dashboardSaga, dashboardSlice } from "./dashboard";
import { createReducers, crudSaga } from "../crud/store";
import { CRUD_RESOURCES } from "../globals";

export const STORE_TOKEN = "store";

export interface State {
  cart: CartState;
  dashboard: DashboardState;
}

export const initStore = () => {
  const rootReducer = combineReducers<State>({
    cart: cartSlice.reducer,
    dashboard: dashboardSlice.reducer,
    // Add the CRUD Reducers
    ...createReducers(CRUD_RESOURCES),
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: true,
  });

  sagaMiddleware.run(cartSaga);
  sagaMiddleware.run(dashboardSaga);

  // Add the CRUD Saga Middleware
  sagaMiddleware.run(crudSaga);

  return store;
};
