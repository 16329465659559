import "./index.css"; // Ensure this is at the top
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { CRUDResource } from "./crud/models";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { initStore } from "./store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const history = createBrowserHistory({ window });

export const store = initStore();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      {/* Removing StrictMode because of https://stackoverflow.com/a/71982736/5434276. useEffects were running twice */}
      {/* <React.StrictMode> */}
      <Toaster />
      {/* @ts-expect-error */}
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
      {/* </React.StrictMode> */}
    </Provider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
