export const NAME_REGEX = /^[A-Za-z ]*$/;

export enum CartStep {
  TICKETS = "TICKETS",
  ADDONS = "ADDONS",
  CHECKOUT = "CHECKOUT",
}

export enum AuthModalStep {
  SIGN_IN = "SIGN_IN",
  SIGN_UP = "SIGN_UP",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}

export enum ManageOrderTab {
  VIEW_ORDER = "VIEW_ORDER",
  PURCHASE_ADDONS = "PURCHASE_ADDONS",
}
