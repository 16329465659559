import { SetURLSearchParams } from "react-router-dom";

export const addSearchParam = (
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
  key: string,
  value: string
) => {
  const newSearchParams = new URLSearchParams(searchParams.toString());
  newSearchParams.set(key, value);
  setSearchParams(newSearchParams, { replace: true });
};

export const constructQueryString = (obj: Record<string, any>): string => {
  const queryStringParts: string[] = [];

  const buildQueryString = (key: string, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (value.hasOwnProperty("id")) {
        queryStringParts.push(`${key}Id=${value.id}`);
      }
    } else {
      queryStringParts.push(`${key}=${value}`);
    }
  };

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      buildQueryString(key, obj[key]);
    }
  }

  return queryStringParts.join("&");
};
