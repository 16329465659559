import { useDispatch, useSelector } from "react-redux";
import { State } from "../../store/store";
import { Addon } from "../../models/Tickets";
import { useEffect, useState } from "react";
import { AddonItem } from "./AddonItem";
import { cartActions } from "../../store/cart";
import { CartStep } from "../../constants";

export const Addons = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: State) => state.cart.cart);
  const [addons, setAddons] = useState<Array<Addon>>([]);

  // everytime the cart changes, calculate addons
  useEffect(() => {
    setAddons(
      cart
        .reduce((acc, obj) => acc.concat(obj.addons || []), [] as Addon[])
        .filter(
          (addon, index, self) =>
            self.findIndex((a) => a.uuid === addon.uuid) === index
        )
    );
  }, [cart]);

  return (
    <section className="lg:-mt-10 px-4 lg:pt-8 sm:px-6 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16 pt-4 pb-10">
      <div className="mx-auto max-w-lg lg:max-w-none">
        <div className="block mt-10">
          <h2
            id="summary-heading"
            className="text-lg font-medium text-gray-900"
          >
            Add-ons
          </h2>
          <p>Enhance your festival experience</p>
        </div>

        <div className="mt-4 border-t border-gray-200 pt-4 lg:pt-0">
          {addons.map((addon: Addon) => (
            <AddonItem key={addon.uuid} addon={addon} />
          ))}
        </div>
      </div>
      <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
        <div className="flex justify-between w-full">
          <div className="order-last hidden lg:block">
            <button
              type="submit"
              onClick={() => {
                dispatch(cartActions.SetStep({ step: CartStep.CHECKOUT }));
              }}
              className="w-full rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50 order-1 sm:w-auto"
            >
              <>Continue</>
            </button>
          </div>
          <div className="order-first items-center flex">
            <div className="text-center text-sm text-gray-500 sm:order-2">
              <p>
                <a
                  onClick={() => {
                    dispatch(cartActions.SetStep({ step: CartStep.TICKETS }));
                  }}
                  className="font-medium text-blue-600 hover:text-blue-500 hover:cursor-pointer"
                >
                  <span aria-hidden="true">&larr; </span>
                  Back to Tickets
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
