export const Skeleton = () => {
  return (
    <>
      <div
        role="status"
        className="space-y-4 divide-y divide-gray-100 animate-pulse dark:divide-gray-700 dark:border-gray-700"
      >
        <div className="flex items-center justify-between">
          <div className="w-32 h-6 bg-gray-200 rounded-md dark:bg-gray-700"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    </>
  );
};
