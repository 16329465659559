import { Route, Routes } from "react-router-dom";
import { CRUDResource } from "./models";
import { CRUDRoute } from "./CRUDRoute";
import { List } from "./List";
import { convertToKebabCase } from "./utils";
import { ReactNode } from "react";
import { NotFound } from "../pages/dashboard/not-found";

interface Props<T, R> {
  resources: Array<CRUDResource<T, R>>;
}

export const CRUDRenderer = <T extends object, R extends ReactNode>({
  resources,
}: Props<T, R>) => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {resources.map((resource: CRUDResource<T, R>) => (
        <Route
          key={convertToKebabCase(resource.name).replaceAll(" ", "-")}
          path={`/${convertToKebabCase(resource.name).replaceAll(" ", "-")}/*`}
          element={<CRUDRoute resource={resource} />}
        />
      ))}
    </Routes>
  );
};
