import { useEffect } from "react";
import { Cart } from "../components/Cart";
import { Tickets } from "../components/Tickets";
import { Checkout } from "../components/Checkout";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../store/store";
import { PortalLayout } from "../components/portal/layouts/PortalLayout";
import { CartStep } from "../constants";
import { Addons } from "../components/addons/Addons";
import { cartActions } from "../store/cart";
import { Nav } from "../components/portal/Nav";
import { getFeatureFlag } from "../utils/feature-flags";
import { useParams } from "react-router-dom";
import { EventDetails } from "../components/portal/EventDetails";

export const Event = () => {
  const dispatch = useDispatch();
  const portalNavEnabled = getFeatureFlag("PORTAL_NAV_ENABLED");
  const step = useSelector((state: State) => state.cart.step);
  const event = useSelector((state: State) => state.cart.event);

  // todo: store an order ID in the local storage with an expiry and retrieve it at checkout time

  // When we load the page, remove the loaded order
  useEffect(() => {
    dispatch(cartActions.ClearOrder());
  }, []);

  return (
    <PortalLayout>
      <div className="bg-white relative lg:mt-0 -mt-6">
        {portalNavEnabled && <Nav />}
        {/* {!localStorage.getItem("presale") && <PresaleLock />} */}
        {/* Background color split screen for large screens */}
        <div className={portalNavEnabled ? "pt-4" : ""}>
          <div
            className="fixed top-0 left-0 hidden h-full w-1/2 bg-white lg:block"
            aria-hidden="true"
          />
          <div
            className="fixed top-0 right-0 hidden h-full w-1/2 bg-gray-50 lg:block"
            aria-hidden="true"
          />

          <div
            className={`lg:mt-0 relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48 ${
              portalNavEnabled ? "mt-10 " : ""
            }`}
          >
            <div className="lg:order-1 order-2">
              {event && event.showEventDetails && (
                <EventDetails event={event} />
              )}
              {step === CartStep.TICKETS && <Tickets />}
              {step === CartStep.ADDONS && <Addons />}
              {step === CartStep.CHECKOUT && <Checkout />}
            </div>
            <div className="lg:order-2 order-1">
              <Cart />
            </div>
            {/* <div className={event?.showEventDetails ? "-mt-6" : ""}>
              <Cart />
            </div> */}
          </div>
        </div>
      </div>
    </PortalLayout>
  );
};
