import {
  CheckCircleIcon,
  PencilSquareIcon,
  TicketIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { DashboardAttendee } from "../../../models/Dashboard";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dashboardActions } from "../../../store/dashboard";
import { checkIn } from "../../../dashboardQueries";
import toast from "react-hot-toast";
import { ButtonSpinner } from "../../ButtonSpinner";
import {
  ArrowLeftStartOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
} from "@heroicons/react/20/solid";

interface Props {
  attendee: DashboardAttendee;
  setViewingAttendee: (attendee: DashboardAttendee | {}) => void;
  setViewQRCodeModalOpen: (open: boolean) => void;
  orderId: string;
}
export const OrderAttendeeRow = ({
  attendee,
  setViewingAttendee,
  setViewQRCodeModalOpen,
  orderId,
}: Props) => {
  const dispatch = useDispatch();
  const [showCheckInOutButton, setShowCheckInOutButton] = useState(false);
  const [checkInOutLoading, setCheckInOutLoading] = useState(false);

  const handleCheckInOut = async (attendee: DashboardAttendee) => {
    setCheckInOutLoading(true);
    if (orderId) {
      await checkIn("attendees", attendee.uuid, attendee.checkedIn);
      dispatch(dashboardActions.GetOrder({ orderId }));
      toast.success(
        `Successfully checked ${attendee.checkedIn ? "out" : "in"} attendee.`
      );
    }
    setCheckInOutLoading(false);
    setShowCheckInOutButton(false);
  };

  return (
    <tr
      key={attendee.id}
      className="border-b border-gray-100 dark:border-white/5 "
    >
      <td className="max-w-0 px-0 py-5 align-top">
        <div className="truncate font-medium text-gray-900 dark:text-white flex items-center">
          <TicketIcon className="h-5 w-5 mr-2" /> {attendee.ticket.name}
        </div>
        <div
          className="truncate text-gray-500 text-xs underline cursor-pointer dark:text-gray-400"
          onClick={() => {
            setViewingAttendee({});
            setViewQRCodeModalOpen(true);
            setViewingAttendee(attendee);
          }}
        >
          View QR Code
        </div>
      </td>
      <td
        className="hidden py-5 pl-8 pr-0 tabular-nums text-gray-700 sm:table-cell items-center flex justify-center"
        colSpan={2}
      >
        {showCheckInOutButton ? (
          <button
            onClick={() => handleCheckInOut(attendee)}
            type="button"
            className="hidden lg:block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {checkInOutLoading ? (
              <div className="flex items-center justify-center">
                <ButtonSpinner /> Processing...
              </div>
            ) : (
              <>
                {attendee.checkedIn ? (
                  <div className="flex items-center justify-center">
                    <ArrowLeftStartOnRectangleIcon className="h-4 w-4 mr-1" />
                    Check out
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <ArrowRightStartOnRectangleIcon className="h-4 w-4 mr-1" />
                    Check in
                  </div>
                )}
              </>
            )}
          </button>
        ) : (
          <div className="flex items-center justify-center">
            <div>
              {attendee.checkedIn ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              ) : (
                <XCircleIcon className="h-5 w-5 text-red-500" />
              )}
            </div>
            <PencilSquareIcon
              className="h-5 w-5 text-gray-500 dark:text-gray-400 cursor-pointer ml-2"
              onClick={() => setShowCheckInOutButton(true)}
            />
          </div>
        )}
      </td>
      <td className="py-5 pl-8 pr-0 text-right tabular-nums text-gray-700 dark:text-gray-400">
        &euro;
        {parseFloat(attendee.ticket.price).toFixed(2)}
      </td>
    </tr>
  );
};
